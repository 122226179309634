import {createSlice} from '@reduxjs/toolkit';
import {createScheduledMaintenanceAsync, getScheduledMaintenanceAsync} from './thunk';
import {ScheduleMaintenanceForm} from 'models/maintenance-interface';

interface State {
  isLoading: boolean;
  scheduleMaintenance: ScheduleMaintenanceForm[];
}

const initialState: State = {
  isLoading: false,
  scheduleMaintenance: [] as ScheduleMaintenanceForm[],
};

const scheduleMaintenanceSlice = createSlice({
  name: 'scheduleMaintenanceSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getScheduledMaintenanceAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(getScheduledMaintenanceAsync.fulfilled, (state, {payload}) => {
        state.scheduleMaintenance = payload.data;
        state.isLoading = false;
      })
      .addCase(getScheduledMaintenanceAsync.rejected, state => {
        state.isLoading = false;
      })
      .addCase(createScheduledMaintenanceAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(createScheduledMaintenanceAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(createScheduledMaintenanceAsync.rejected, state => {
        state.isLoading = false;
      });
  },
});

export default scheduleMaintenanceSlice.reducer;
