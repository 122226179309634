import {FC, useState, ChangeEvent} from 'react';
import Close from '@mui/icons-material/Close';
import {useAppDispatch} from 'redux/redux';
import {updateBaitCheckerSearchText, updateLogsSearchText} from 'redux/inventory/slice';
import {updatePestBaitBoxSearchText, updatePestCompanySearchText} from 'redux/pest-company/slice';
import TextInput from '../text-input/TextInput';
import Button from '../Button';

interface SearchBarProps {
  onInputChange: (searchTerm: string) => void;
  textChange?: (text: string) => void;
}

const SearchBar: FC<SearchBarProps> = ({onInputChange, textChange}) => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const trimmedValue = JSON.parse(JSON.stringify(value)).trim();
    onInputChange(trimmedValue);
    setSearchTerm(value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    if (location.pathname.includes('bait-checker')) {
      dispatch(updateBaitCheckerSearchText({text: null}));
    }
    if (location.pathname.includes('view-pest-company')) {
      dispatch(updatePestBaitBoxSearchText({text: null}));
    }
    if (location.pathname.includes('logs')) {
      dispatch(updateLogsSearchText({text: null}));
    } else {
      dispatch(updatePestCompanySearchText({text: null}));
    }
    textChange(null);
  };

  return (
    <div className="search-term-container clear-search-btn">
      <TextInput value={searchTerm} placeholder="SEARCH" onChange={handleInputChange} />
      {searchTerm && (
        <Button
          type="button"
          label=""
          startIcon={<Close fontSize="large" />}
          variant="text"
          onClick={handleClearSearch}
        />
      )}
    </div>
  );
};

export default SearchBar;
