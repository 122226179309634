import axiosInstance from 'api/api-service';
import {BAIT_BOX, BAIT_BOX_ACTION, LANGUAGES, PEST_COMPANY, PEST_COMPANY_ACTION} from 'constants/api-endpoints';
import {HttpResponse} from 'models/http-interface';
import {
  ClientBaitBoxForDeactivation,
  CreatePestCompanyData,
  DeactiveBaitBoxBody,
  LanguagesList,
  ListParams,
  PestCompanyBaitboxList,
  PestCompanyData,
  PestCompanyListData,
  UpdatePestCompanyData,
  UpdatePestCompanyStatusResponse,
} from 'models/pest-company-interface';

const {MARK_DEACTIVATE, MARK_RETURN_TO_ZEPTA, MARK_RETIRE} = BAIT_BOX_ACTION;

const {
  DEACTIVATE_MULTIPLE_PEST_COMPANIES,
  DEACTIVATE_MULTIPLE_CLIENT_BAITBOX,
  RETURN_TO_ZEPTA_MULTIPLE,
  RETIRE_MULTIPLE,
} = PEST_COMPANY_ACTION;

export const createPestCompany = (body: CreatePestCompanyData) => {
  return axiosInstance.post<HttpResponse<null>>(PEST_COMPANY.PEST_COMPANY, body);
};

export const getPestCompanyList = (params: ListParams) => {
  return axiosInstance.get<HttpResponse<PestCompanyListData>>(PEST_COMPANY.PEST_COMPANY, {
    params,
  });
};

export const updatePestCompany = (id: string, updatedBody: UpdatePestCompanyData) => {
  return axiosInstance.put<HttpResponse<null>>(`${PEST_COMPANY.PEST_COMPANY}/${id}`, updatedBody);
};

export const updatePestCompanyStatus = (pestCompanyId: string, params: {isDeactivate: boolean}) => {
  return axiosInstance.put<HttpResponse<UpdatePestCompanyStatusResponse>>(
    `${PEST_COMPANY.PEST_COMPANY_STATUS}/${pestCompanyId}`,
    {},
    {
      params,
    },
  );
};

export const getPestCompanyBaitBoxes = (pestCompanyId: string, params?: ListParams) => {
  return axiosInstance.get<HttpResponse<PestCompanyBaitboxList>>(`${BAIT_BOX.LIST}/${pestCompanyId}`, {
    params,
  });
};

export const deactivateBaitBox = (deactivateBaitBoxBody: DeactiveBaitBoxBody) => {
  return axiosInstance.put<HttpResponse<null>>(`${MARK_DEACTIVATE}`, {
    ...deactivateBaitBoxBody,
  });
};

export const returnBaitBoxToZepta = (iccHash: string) => {
  return axiosInstance.patch<HttpResponse<null>>(`${MARK_RETURN_TO_ZEPTA}/${iccHash}`);
};

export const markBaitBoxRetire = (iccHash: string) => {
  return axiosInstance.patch<HttpResponse<null>>(`${MARK_RETIRE}/${iccHash}`);
};

export const deactivateSelectedPestCompanies = (selectedPestCompanies: string[]) => {
  return axiosInstance.patch<HttpResponse<null>>(`${DEACTIVATE_MULTIPLE_PEST_COMPANIES}`, {
    pestCompanyIds: selectedPestCompanies,
  });
};

export const deactivateSelectedClientBaitBoxes = (selectedBaitBoxes: ClientBaitBoxForDeactivation[]) => {
  return axiosInstance.put<HttpResponse<null>>(`${DEACTIVATE_MULTIPLE_CLIENT_BAITBOX}`, {
    baitBoxes: selectedBaitBoxes,
  });
};

export const retireSelectedClientBaitBoxes = (selectedBaitBoxes: string[]) => {
  return axiosInstance.patch<HttpResponse<null>>(`${RETIRE_MULTIPLE}`, {
    iccHashs: selectedBaitBoxes,
  });
};

export const retunrToZeptaSelectedClientBaitBoxes = (selectedBaitBoxes: string[]) => {
  return axiosInstance.patch<HttpResponse<null>>(`${RETURN_TO_ZEPTA_MULTIPLE}`, {iccHashs: selectedBaitBoxes});
};

export const getPestDetailsWithBaitboxes = (pestCompanyId: string) => {
  return axiosInstance.get<HttpResponse<PestCompanyData>>(
    `${PEST_COMPANY.PEST_COMPANY_WITH_BAITBOX_COUNT}/${pestCompanyId}`,
  );
};

/**
 * @description: Get languages list
 * @returns
 */
export const getLanguagesList = () => {
  return axiosInstance.get<HttpResponse<LanguagesList[]>>(`${LANGUAGES.LANGUAGES}`);
};
