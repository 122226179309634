import DataNotFound from 'components/shared/DataNotFound';
import Loader from 'components/shared/loader/Loader';
import TablePagination from 'components/shared/table-pagination/TablePagination';
import {FC} from 'react';
import {getBaitCheckerCount, inventoryLoading} from 'redux/inventory/selector';
import {useAppSelector} from 'redux/redux';
import {countPages} from 'utils/page-count';
import List from './table';

type BaitCheckerListProp = {
  pagination: {
    limit: number;
    page: number;
    onPageChange: (newPage: number) => void;
    onLimitChange: (newLimit: number) => void;
  };
};

const BaitcheckerList: FC<BaitCheckerListProp> = ({pagination}) => {
  const count = useAppSelector(getBaitCheckerCount);
  const loading = useAppSelector(inventoryLoading);
  const {page, limit, onPageChange, onLimitChange} = pagination;

  return (
    <>
      <Loader show={loading} />
      {count > 0 ? (
        <>
          <List />
          <TablePagination
            pageChange={onPageChange}
            page={page}
            count={countPages(count, limit)}
            limit={limit}
            limitChange={onLimitChange}
          />
        </>
      ) : (
        <DataNotFound />
      )}
    </>
  );
};

export default BaitcheckerList;
