import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {HttpError} from 'models/http-interface';
import {Tag} from 'models/tags-interface';
import {formatError} from 'utils/http-error-formatter';
import {showError} from 'utils/toast-alerts';
import baitBoxTagsInitialState from './initial-state';
import {
  createBaitBoxTagAsync,
  deactivateBaitBoxTagAsync,
  getBaitBoxTagsListAsync,
  updateBaitBoxTagAsync,
} from './thunk';

export const baitBoxTagsSlice = createSlice({
  name: 'tags',
  initialState: baitBoxTagsInitialState,
  reducers: {
    setTagDetails: (state, action: PayloadAction<Tag>) => {
      state.TagDetail = action.payload;
    },
    clearTagDetails: state => {
      state.TagDetail = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createBaitBoxTagAsync.pending, state => {
        state.loading = true;
      })
      .addCase(createBaitBoxTagAsync.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createBaitBoxTagAsync.rejected, state => {
        state.loading = false;
      })

      .addCase(getBaitBoxTagsListAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getBaitBoxTagsListAsync.fulfilled, (state, {payload}) => {
        state.tagsList = payload.data.list;
        state.count = payload.data.count;
        state.loading = false;
      })
      .addCase(getBaitBoxTagsListAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.loading = false;
      })

      .addCase(updateBaitBoxTagAsync.pending, state => {
        state.loading = true;
      })
      .addCase(updateBaitBoxTagAsync.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateBaitBoxTagAsync.rejected, state => {
        state.loading = false;
      })

      .addCase(deactivateBaitBoxTagAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deactivateBaitBoxTagAsync.fulfilled, state => {
        state.loading = false;
      })
      .addCase(deactivateBaitBoxTagAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.loading = false;
      });
  },
});

export const {setTagDetails, clearTagDetails} = baitBoxTagsSlice.actions;

export default baitBoxTagsSlice.reducer;
