import DataNotFound from 'components/shared/DataNotFound';
import Loader from 'components/shared/loader/Loader';
import {useEffect} from 'react';
import {inventoryLoading} from 'redux/inventory/selector';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import List from './table';
import {getScheduledMaintenanceAsync} from 'redux/scheduled-maintenance/thunk';
import {scheduleMaintenanceData} from 'redux/scheduled-maintenance/selector';

const ScheduleMaintenanceList = () => {
  const count = useAppSelector(scheduleMaintenanceData).length;
  const loading = useAppSelector(inventoryLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchScheduleMaintenance();
  }, []);

  const fetchScheduleMaintenance = async () => {
    dispatch(getScheduledMaintenanceAsync()).unwrap();
  };

  return (
    <>
      <Loader show={loading} />
      {count > 0 ? (
        <div className="body-wrapper">
          <List />
        </div>
      ) : (
        <DataNotFound />
      )}
    </>
  );
};

export default ScheduleMaintenanceList;
