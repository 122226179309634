import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import useDialog from 'hooks/useDialog';
import useTranslate from 'hooks/useTranslate';
import {FC, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {getSelectedCheckBoxIds, getSelectedCheckBoxPestIds} from 'redux/checkBox/selector';
import {reset} from 'redux/checkBox/slice';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {clearSelectedBaitCheckers} from 'redux/inventory/slice';
import {
  deactivateSelectedBaitBoxes,
  getBaitCheckerAsync,
  updateMultiPlierOfSelectedBaitBoxes,
  updateTareOffSetOfSelectedBaitBoxes,
} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {showError, showSuccess} from 'utils/toast-alerts';
import {CustomInputPopup} from './CustomInputPopup';
import {InputSettingPopup} from './InputSettingPopup';

const baitCheckerMultiSelectOptions = [
  {label: 'DEACTIVATE_SELECTED', value: 'Deactive'},
  {label: 'UPDATE_TARE_OFFSET', value: 'UpdateTareOffset'},
  {label: 'UPDATE_MULTIPLIER', value: 'UpdateMultiplier'},
  {label: 'UPDATE_DEVICE_SETTINGS', value: 'UpdateDeviceSettings'},
];

type SelectOptionType = {
  textChange?: (text: string) => void;
};

const BtckrSelectOptions: FC<SelectOptionType> = ({textChange}) => {
  const [parameter] = useSearchParams();
  const {parseUI} = useTranslate();
  const dispatch = useAppDispatch();

  const {multiSelectOptionActionLoading} = useAppSelector(getBaitCheckerState);

  const checkBoxPestIds = useAppSelector(getSelectedCheckBoxPestIds);

  const checkBoxIds = useAppSelector(getSelectedCheckBoxIds);

  const [btckrOption, setBtckrOption] = useState('');
  const {isOpen, showDialog, hideDialog} = useDialog(false);
  const [showSettingInput, setSettingInput] = useState(false);
  const [confirmDialogBody, setConfirmDialogBody] = useState('');

  // for custom input dialog
  const [isCustomInputOpen, setIsCustomInputOpen] = useState(false);
  const [customInputValue, setCustomInputValue] = useState('');
  const [customInputTitle, setCustomInputTitle] = useState('');

  const queryPage = parameter.get('page') ?? '1';
  const queryLimit = parameter.get('limit') ?? '50';

  const handleInputOpen = () => {
    setIsCustomInputOpen(true);
  };

  const handleCloseInputPopup = () => {
    setBtckrOption('');
    setIsCustomInputOpen(false);
  };

  const handleInputSubmit = (value: string) => {
    setCustomInputValue(value);
    showDialog<string>(btckrOption);
  };

  const onSuccess = async () => {
    let response;

    try {
      if (btckrOption === 'UpdateTareOffset') {
        const tareOffset = parseFloat(customInputValue);
        response = await dispatch(
          updateTareOffSetOfSelectedBaitBoxes({
            baitBoxIds: checkBoxIds,
            tareOffset,
          }),
        ).unwrap();
      } else if (btckrOption === 'UpdateMultiplier') {
        const multiplier = parseFloat(customInputValue);
        response = await dispatch(
          updateMultiPlierOfSelectedBaitBoxes({
            baitBoxIds: checkBoxIds,
            multiplier,
          }),
        ).unwrap();
      } else if (btckrOption === 'Deactive') {
        // call the api to for deactive slected Batiboxes
        response = await dispatch(
          deactivateSelectedBaitBoxes({
            baitBoxes: checkBoxPestIds,
          }),
        ).unwrap();
      }

      dispatch(reset());
      setBtckrOption('');
      showSuccess(response.message);
      hideDialog();

      if (textChange) {
        textChange('');
      } else {
        reFetch();
      }
    } catch (error) {
      showError(error.message);
      dispatch(clearSelectedBaitCheckers());
      hideDialog();
    }
  };

  const reFetch = () => {
    dispatch(
      getBaitCheckerAsync({
        pageNumber: parseInt(queryPage),
        limit: parseInt(queryLimit),
      }),
    );
  };

  const handleChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value === 'UpdateMultiplier') {
      setCustomInputTitle('Input Multiplier Value');
      setConfirmDialogBody(`${parseUI('WANT_TO_UPDATE_MULTIPLIER_OF_SELECTED_BAITCHECKERS')} ?`);
      setBtckrOption(e.target.value);
      handleInputOpen();
    } else if (e.target.value === 'UpdateTareOffset') {
      setCustomInputTitle('Input TareOffset Value');
      setConfirmDialogBody(`${parseUI('WANT_TO_UPDATE_TARE_OFFSET_OF_SELECTED_BAITCHECKERS')} ?`);
      setBtckrOption(e.target.value);
      handleInputOpen();
    } else if (e.target.value === 'Deactive') {
      setConfirmDialogBody(`${parseUI('WANT_TO_DEACTIVATE_SELECTED_BAITCHECKERS')}`);
      showDialog<string>(e.target.value);
      setBtckrOption(e.target.value);
    }

    if (e.target.value === 'UpdateDeviceSettings') {
      setSettingInput(true);
    }
  };

  const hideDialogAndSetOptionToNull = () => {
    setBtckrOption('');
    hideDialog();
  };

  const closeSettingInputPopup = () => {
    setSettingInput(false);
    setBtckrOption('');
    reFetch();
  };

  const renderSelectedOptionConfirmDialog = () => {
    return (
      <ConfirmDialog
        isLoading={multiSelectOptionActionLoading}
        initialValue={isOpen}
        onClose={hideDialogAndSetOptionToNull}
        successMessage={parseUI('YES')}
        cancelMessage={parseUI('CANCEL')}
        onSuccess={onSuccess}
        heading={parseUI('ARE_YOU_SURE')}
        body={confirmDialogBody}
      />
    );
  };

  const renderInputPopup = () => {
    return (
      <CustomInputPopup
        title={customInputTitle}
        open={isCustomInputOpen}
        onClose={handleCloseInputPopup}
        onSubmit={handleInputSubmit}
      />
    );
  };

  const renderSettingInputPopup = () => {
    return <InputSettingPopup open={showSettingInput} onClose={closeSettingInputPopup} />;
  };

  return (
    <>
      <FormControl sx={{m: 0, minWidth: 180}} size="small" className="checkbox-action">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={btckrOption}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return <span>{parseUI('MULTI_SELECT_OPTIONS')}</span>;
            }
            const selectedItem = baitCheckerMultiSelectOptions.find(item => item.value === selected);
            return selectedItem ? parseUI(selectedItem.label) : '';
          }}
          onChange={handleChange}>
          <MenuItem value="">
            <em>{parseUI('NONE')}</em>
          </MenuItem>
          {baitCheckerMultiSelectOptions.map(({label, value}) => (
            <MenuItem key={value} value={value}>
              {parseUI(label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {renderSelectedOptionConfirmDialog()}
      {renderInputPopup()}
      {renderSettingInputPopup()}
    </>
  );
};

export default BtckrSelectOptions;
