import ListHeader from 'components/UI/list/ListHeader';
import BaitBoxTagsList from 'components/tags/list/BaitBoxTagsList';
import {ROUTES} from 'constants/routes';

const BaitBoxTags = () => {
  return (
    <>
      <ListHeader label="ADD_TAG" url={ROUTES.ADD_TAG} title="TAGS" />
      <div className="body-wrapper">
        <BaitBoxTagsList />
      </div>
    </>
  );
};

export default BaitBoxTags;
