import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router';
import {TableCell, TableRow} from '@mui/material';
import LoadingButton from 'components/UI/LoadingButton';
import {ROUTES} from 'constants/routes';

import {getBaitCheckerState} from 'redux/inventory/selector';
import {AssignTagsToBaitBox} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {showError, showSuccess} from 'utils/toast-alerts';
import {formatError} from 'utils/http-error-formatter';
import {HttpError} from 'models/http-interface';
import useCheckboxList from 'hooks/useTagCheckboxList';
import InfoTooltip from 'components/UI/InfoTooltip';

const {DASHBOARD, LIST_BAIT_CHECKER} = ROUTES;

const TagTableBody = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const {baitCheckerTags, isLoading} = useAppSelector(getBaitCheckerState);

  const {data, syncCode, toggleCheckBox, getChangedCheckBoxList} = useCheckboxList();

  const dispatch = useAppDispatch();

  const updateList = async () => {
    try {
      const updatedData = getChangedCheckBoxList();

      const response = await dispatch(
        AssignTagsToBaitBox({
          baitBoxId: id,
          data: updatedData,
        }),
      ).unwrap();
      showSuccess(response.message);
      navigate(`/${DASHBOARD}/${LIST_BAIT_CHECKER}`);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
  };

  const renderRows = () =>
    data.length > 0 &&
    data.map(({isCheck, name, uuid}) => (
      <TableRow key={uuid} className="tooltip-table">
        <TableCell width={300}>
          {name.length > 254 ? (
            <InfoTooltip name={name}>
              <span className="assign-tag-name">{name}</span>
            </InfoTooltip>
          ) : (
            name
          )}
        </TableCell>
        <TableCell>
          <input type="checkbox" checked={isCheck} onChange={() => toggleCheckBox(uuid)} className="pest-checkbox" />
        </TableCell>
      </TableRow>
    ));

  useEffect(() => {
    syncCode(baitCheckerTags);
  }, [baitCheckerTags]);

  return (
    <>
      {renderRows()}
      <TableRow>
        <TableCell width={300}>&nbsp;</TableCell>
        <TableCell>
          <LoadingButton
            type="button"
            label="SUBMIT"
            loader={isLoading}
            disabled={isLoading || getChangedCheckBoxList().length == 0}
            className="loading-button"
            onClick={updateList}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TagTableBody;
