import {createSlice} from '@reduxjs/toolkit';
import {PURGE} from 'redux-persist';

import {TOKEN} from 'constants/local-storage-keys';
import {HttpError} from 'models/http-interface';
import {formatError} from 'utils/http-error-formatter';
import {setKey} from 'utils/local-storage';
import {showError, showSuccess} from 'utils/toast-alerts';

import initialState from './initial-state';
import {changePasswordAsync, loginUserAsync, updateAccessTokenAsync} from './thunk';

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loginUserAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, {payload}) => {
        state.isLoading = false;
        showSuccess(payload.message);
        state.user = payload.data.user;
        state.accessToken = payload.data.accessToken;
        state.refreshToken = payload.data.refreshToken;
        state.isLoggedIn = true;
        setKey(TOKEN, payload.data.accessToken);
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.isLoading = false;
        const payload = action.payload as HttpError;
        const errorMsg = formatError(payload.error);
        showError(errorMsg);
      })
      .addCase(changePasswordAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(changePasswordAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(changePasswordAsync.rejected, state => {
        state.isLoading = false;
      })
      .addCase(updateAccessTokenAsync.fulfilled, (state, {payload}) => {
        state.accessToken = payload.data.accessToken;
        state.refreshToken = payload.data.refreshToken;
      })
      .addCase(PURGE, () => {
        return initialState;
      });
  },
});

export default authSlice.reducer;
