import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  AssignUnassignTagBody,
  BaitBoxCommentListParams,
  BaitboxDeviceSettings,
  CreateCommentBody,
  CreateSimulateDataV2,
  DownloadActivityLogsCSV,
  GetAllAndAssignedTags,
  GetBaitBoxActivityLogsBody,
  SelectedBaitCheckers,
  SimulateDataWithoutHash,
  UpdateBaitBoxMeta,
  UpdateBaitCheckersMultiplier,
  UpdateBaitCheckersTareOffset,
  UpdateCommentBody,
} from 'models/inventory.interface';
import {ListParams} from 'models/pest-company-interface';
import {
  createBaitBoxComment,
  createBaitBoxInventory,
  deactivateMultipleBaitBox,
  downloadActivityLogsCSV,
  editBaitBoxMeta,
  getActivityLogsFilters,
  getBaitBoxActivityLogs,
  getBaitBoxStatus,
  getBaitCheckerAllAndAssignedTags,
  getBaitCheckerCommentsList,
  getSimulatorConfiguration,
  listBaitBoxInventory,
  removeBaitBoxComment,
  simulateBaitBoxLogs,
  simulateBaitBoxLogsV2,
  updateBaitBoxComment,
  updateBaitboxDeviceSettings,
  updateBaitBoxTags,
  updateMultiplierOfMultipleBaitboxes,
  updateTareOffsetOfMultipleBaitboxes,
} from './api';

export const createBaitBoxInventoryAsync = createAsyncThunk(
  'baitBox/create',
  async (baitBoxData: {iccNumbers: string}, {rejectWithValue}) => {
    try {
      return await createBaitBoxInventory(baitBoxData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBaitCheckerAsync = createAsyncThunk('baitbox/list', async (params: ListParams, {rejectWithValue}) => {
  try {
    const {data} = await listBaitBoxInventory(params);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateBaitBoxMetaAsync = createAsyncThunk(
  'baitbox/edit',
  async (body: UpdateBaitBoxMeta, {rejectWithValue}) => {
    try {
      const {data} = await editBaitBoxMeta(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deactivateSelectedBaitBoxes = createAsyncThunk(
  'baitbox/deactivate-selected',
  async (body: {baitBoxes: SelectedBaitCheckers[]}, {rejectWithValue}) => {
    try {
      const {data} = await deactivateMultipleBaitBox(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateTareOffSetOfSelectedBaitBoxes = createAsyncThunk(
  'baitbox/update-bulk-tareoffset',
  async (body: UpdateBaitCheckersTareOffset, {rejectWithValue}) => {
    try {
      const {data} = await updateTareOffsetOfMultipleBaitboxes(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateMultiPlierOfSelectedBaitBoxes = createAsyncThunk(
  'baitbox/update-bulk-multipliers',
  async (body: UpdateBaitCheckersMultiplier, {rejectWithValue}) => {
    try {
      const {data} = await updateMultiplierOfMultipleBaitboxes(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getAllAndAssignedTags = createAsyncThunk(
  'baitbox/get-all-assign-tags',
  async (body: GetAllAndAssignedTags, {rejectWithValue}) => {
    try {
      const {data} = await getBaitCheckerAllAndAssignedTags(body);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const AssignTagsToBaitBox = createAsyncThunk(
  'baitbox/assign-tags',
  async (body: AssignUnassignTagBody, {rejectWithValue}) => {
    try {
      const {data} = await updateBaitBoxTags(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBaitBoxCommentsAsync = createAsyncThunk(
  'baitbox/get-comments',
  async (params: BaitBoxCommentListParams, {rejectWithValue}) => {
    try {
      const {data} = await getBaitCheckerCommentsList(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateBaitBoxCommentAsync = createAsyncThunk(
  'baitbox/update-comment',
  async (body: UpdateCommentBody, {rejectWithValue}) => {
    try {
      const {data} = await updateBaitBoxComment(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const removeBaitBoxCommentAsync = createAsyncThunk(
  'baitbox/remove-comment',
  async (commentId: string, {rejectWithValue}) => {
    try {
      const {data} = await removeBaitBoxComment(commentId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addBaitBoxCommentAsync = createAsyncThunk(
  'baitbox/add-comment',
  async (body: CreateCommentBody, {rejectWithValue}) => {
    try {
      const {data} = await createBaitBoxComment(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBaitBoxStatusAsync = createAsyncThunk(
  'baitbox/get-baitbox-status',
  async (iccNumber: string, {rejectWithValue}) => {
    try {
      const {data} = await getBaitBoxStatus(iccNumber);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const simulateBaitBoxLogsAsync = createAsyncThunk(
  'baitbox/simulate-logs',
  async (simulatorBody: SimulateDataWithoutHash, {rejectWithValue}) => {
    try {
      const response = await simulateBaitBoxLogs(simulatorBody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const simulateBaitBoxLogsAsyncV2 = createAsyncThunk(
  'baitbox/simulate-logs-v2',
  async (simulatorBody: CreateSimulateDataV2, {rejectWithValue}) => {
    try {
      return await simulateBaitBoxLogsV2(simulatorBody);
    } catch (error) {
      const errorText = new TextDecoder().decode(error);

      return rejectWithValue(JSON.parse(errorText));
    }
  },
);

export const getBaiBoxActivityLogsAsync = createAsyncThunk(
  'baitbox/activity-logs',
  async (baitBoxActivityLogsBody: GetBaitBoxActivityLogsBody, {rejectWithValue}) => {
    try {
      const response = await getBaitBoxActivityLogs(baitBoxActivityLogsBody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getActivityLogsFiltersAsync = createAsyncThunk(
  'baitbox/activity-logs-filters',
  async (baitboxId: string, {rejectWithValue}) => {
    try {
      const response = await getActivityLogsFilters(baitboxId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadActivityLogsCSVAsync = createAsyncThunk(
  'baitbox/activity-logs-csv',
  async (downloadCSVBody: DownloadActivityLogsCSV, {rejectWithValue}) => {
    try {
      const response = await downloadActivityLogsCSV(downloadCSVBody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSimulatorConfigurationAsync = createAsyncThunk(
  'baitbox/simulator-config',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getSimulatorConfiguration();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateBaitboxDeviceSettingsAsync = createAsyncThunk(
  'baitbox/device-settings',
  async (body: BaitboxDeviceSettings, {rejectWithValue}) => {
    try {
      const response = await updateBaitboxDeviceSettings(body);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
