import {BaitBoxLogsData} from 'models/inventory.interface';

export const createArrayBetween = () => {
  const array = [];
  for (let i = 1; i <= 14; i++) {
    array.push(i);
  }

  return array;
};

export const findMinMaxInArray = (array: BaitBoxLogsData[]) => {
  let min = array[0].weight;
  let max = array[0].weight;
  for (let i = 1; i < array.length; i++) {
    if (array[i].weight < min) {
      min = array[i].weight;
    }
    if (array[i].weight > max) {
      max = array[i].weight;
    }
  }

  return {min, max};
};

export const createYAxis = () => {
  return createArrayBetween();
};
