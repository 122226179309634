import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

/**
 * The `usePagination` function is a custom hook in TypeScript that manages pagination state and
 * provides functions to update the page and limit values.
 * @param {number} initialPage - The initial page number to start with when using pagination.
 * @param {number} initialLimit - The `initialLimit` parameter is the initial number of items to be
 * displayed per page. It determines the number of items that will be shown on each page when the
 * pagination component is first rendered.
 * @returns The usePagination hook returns an object with the following properties and methods:
 */
export const usePagination = (initialPage: number, initialLimit: number) => {
  const [page, setPage] = useState<number>();
  const [limit, setLimit] = useState<number>();
  const [parameter, setParam] = useSearchParams();

  const onPageChange = (newPage: number) => {
    setParam({
      page: newPage.toString(),
      limit: limit.toString(),
    });
    setPage(newPage);
  };

  const onLimitChange = (newLimit: number) => {
    setParam({
      page: '1',
      limit: newLimit.toString(),
    });
    setLimit(newLimit);
    setPage(() => 1);
  };

  useEffect(() => {
    const queryPage = parameter.get('page');
    const queryLimit = parameter.get('limit');

    if (queryPage && queryLimit) {
      setParam({
        page: queryPage,
        limit: queryLimit ?? limit.toString(),
      });
      setPage(parseInt(queryPage));
      setLimit(parseInt(queryLimit));
    } else {
      setPage(initialPage);
      setLimit(initialLimit);
    }
  }, []);

  return {limit, page, onPageChange, onLimitChange};
};
