import {useField} from 'formik';
import {FC} from 'react';
import PhoneInput, {Country} from 'react-phone-number-input';
import useTranslate from '../../hooks/useTranslate';
import de from 'react-phone-number-input/locale/de.json';
import en from 'react-phone-number-input/locale/en.json';
import i18next from 'i18next';

interface PhoneInputProps {
  name: string;
  label: string;
  type: string;
  country?: string;
  maxLength: number;
  onCountryChange?: (value: ((prevState: string) => string) | string) => void;
}

const CustomPhoneInput: FC<PhoneInputProps> = ({label, ...props}) => {
  const [field, _, {setValue}] = useField(props);
  const currentLocale = i18next.language;
  const {parseUI} = useTranslate();

  return (
    <div className={'p-3 bg-white rounded-3 mt-3'} style={{border: '1px solid rgba(196,196,196,1)'}}>
      <p style={{fontSize: '12px', marginLeft: '-5px', marginTop: '-20px', lineHeight: '6px'}}>
        <span className={'bg-white'}>{parseUI('COUNTRY_AND_PHONE')}</span>
      </p>
      <PhoneInput
        value={field.value}
        defaultCountry={'DE'}
        international
        countrySelectProps={{style: {width: '100%', marginBottom: '15px', padding: '10px'}}}
        numberInputProps={{
          style: {
            width: 'calc(100% - 40px)',
            padding: '10px',
            borderRadius: '10px',
            border: 'none',
            backgroundColor: 'rgb(243,245,249)',
            marginBottom: '15px',
          },
        }}
        limitMaxLength
        addInternationalOption={false}
        countryOptionsOrder={['DE', 'AT', 'GB']}
        label={label}
        labels={currentLocale === 'de' ? de : en}
        placeHolder={parseUI(label)}
        country={props.country as Country}
        maxLength={props.maxLength}
        onChange={setValue}
        onCountryChange={c => props.onCountryChange(c)}
      />
    </div>
  );
};

export default CustomPhoneInput;
