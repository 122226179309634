import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import useDialog from 'hooks/useDialog';
import useTranslate from 'hooks/useTranslate';
import {ClientBaitBoxForDeactivation} from 'models/pest-company-interface';
import {FC, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {selectPestCompanyData} from 'redux/pest-company/selector';
import {
  deactivateSelectedClientBaitBoxAsync,
  getPestCompanyBaitBoxesAsync,
  getPestDetailsWithBaitBoxesAsync,
  retireMultipleBaitBoxesAsync,
  returnToZeptaMultipleBaitBoxesAsync,
} from 'redux/pest-company/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {showError, showSuccess} from 'utils/toast-alerts';

const ClientBaitBoxMultiSelectOptions = [
  {label: 'DEACTIVATE_SELECTED', value: 'Deactivate'},
  {label: 'RETURN_TO_ZEPTA', value: 'ReturnToZepta'},
  {label: 'RETIRE', value: 'Retire'},
];

type ClientBaitBoxOptionsProp = {
  getSelectedBaitBoxesByStatus: (isActive: number) => any[];
  textChange?: (text: string) => void;
};

const ClientBaitBoxSelectOptions: FC<ClientBaitBoxOptionsProp> = ({getSelectedBaitBoxesByStatus, textChange}) => {
  const {id} = useParams();

  const [parameter] = useSearchParams();
  const {parseUI} = useTranslate();

  const dispatch = useAppDispatch();

  const {baitboxSearchText, baitboxSortingKey, baitboxSortingOrder} = useAppSelector(selectPestCompanyData);

  const [selectedOption, setSelectedOption] = useState('');
  const [confirmDialogBody, setConfirmDialogBody] = useState('');
  const [filteredBaitBoxes, setFilteredBaitBoxes] = useState<any[]>(null);

  const {isOpen, showDialog, hideDialog} = useDialog(false);

  const queryPage = parameter.get('page') ?? '1';
  const queryLimit = parameter.get('limit') ?? '10';

  const handleChange = (e: SelectChangeEvent<string>) => {
    setSelectedOption(e.target.value);
    if (e.target.value === 'Deactivate') {
      const deActivatedBaitBoxes = getSelectedBaitBoxesByStatus(1);
      setFilteredBaitBoxes(deActivatedBaitBoxes as ClientBaitBoxForDeactivation[]);
      setConfirmDialogBody(`${parseUI('WANT_TO_DEACTIVATE_SELECTED_BAITCHECKERS')}`);
    } else {
      const activeBaitBoxes = getSelectedBaitBoxesByStatus(0);
      setFilteredBaitBoxes(activeBaitBoxes as string[]);
      if (e.target.value === 'Retire') {
        setConfirmDialogBody(`${parseUI('WANT_TO_RETIRE_SELECTED_BAITCHECKERS')} ?`);
      }
      if (e.target.value === 'ReturnToZepta') {
        setConfirmDialogBody(`${parseUI('WANT_TO_RETURN_SELECTED_BAITCHECKERS_TO_ZEPTA')} ?`);
      }
    }

    showDialog<string>(e.target.value);
  };

  const hideDialogAndSetOptionToNull = () => {
    setSelectedOption('');
    hideDialog();
  };

  const onSuccess = async () => {
    let response;

    try {
      if (filteredBaitBoxes.length === 0) {
        showError(`${parseUI('PLEASE_SELECT_APPROPRIATE_BAITCHECKER_FOR_ACTION')}`);
      }
      if (selectedOption === 'Deactivate') {
        response = await dispatch(deactivateSelectedClientBaitBoxAsync(filteredBaitBoxes)).unwrap();
      }

      if (selectedOption === 'ReturnToZepta') {
        response = await dispatch(returnToZeptaMultipleBaitBoxesAsync(filteredBaitBoxes)).unwrap();
      }

      if (selectedOption === 'Retire') {
        response = await dispatch(retireMultipleBaitBoxesAsync(filteredBaitBoxes)).unwrap();
      }

      if (selectedOption === 'Deactivate') {
        // refresh the current page with the pagination value of current page .
        dispatch(
          getPestCompanyBaitBoxesAsync({
            pestCompanyId: id,
            pageNumber: parseInt(queryPage),
            limit: parseInt(queryLimit),
            searchField: baitboxSearchText,
            sortingField: baitboxSortingKey,
            sortingOrder: baitboxSortingOrder,
          }),
        );
      } else {
        // to change the current page 1 in query param and call the api for page 1 as bait boxes removed from the list
        // when we return to zepta or retire
        textChange(null);
      }

      dispatch(getPestDetailsWithBaitBoxesAsync(id));

      setSelectedOption('');
      showSuccess(response.message);
      hideDialog();
    } catch (error) {
      setSelectedOption('');
      showError(error.message);
      hideDialog();
    }
  };

  const renderSelectedOptionConfirmDialog = () => {
    return (
      <ConfirmDialog
        isLoading={false}
        initialValue={isOpen}
        onClose={hideDialogAndSetOptionToNull}
        successMessage={parseUI('YES')}
        cancelMessage={parseUI('CANCEL')}
        onSuccess={onSuccess}
        heading={parseUI('ARE_YOU_SURE')}
        body={confirmDialogBody}
      />
    );
  };

  return (
    <>
      <FormControl sx={{m: 0, minWidth: 180}} size="small" className="checkbox-action">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          // disabled={selectedBaitCheckersForAction.length === 0}
          // condition to check if there are selected items in redux state
          value={selectedOption}
          displayEmpty
          renderValue={selected => {
            if (selected === '') {
              return <span>{parseUI('MULTI_SELECT_OPTIONS')}</span>;
            }
            const selectedItem = ClientBaitBoxMultiSelectOptions.find(item => item.value === selected);
            return selectedItem ? parseUI(selectedItem.label) : '';
          }}
          onChange={handleChange}>
          <MenuItem value="">
            <em>{parseUI('NONE')}</em>
          </MenuItem>
          {ClientBaitBoxMultiSelectOptions.map(({label, value}) => (
            <MenuItem key={value} value={value}>
              {parseUI(label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {renderSelectedOptionConfirmDialog()}
    </>
  );
};

export default ClientBaitBoxSelectOptions;
