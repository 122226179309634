import {FC, useState} from 'react';
import Button from 'components/UI/Button';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {clearDateRangeFilter, updateDateRangeFilter} from 'redux/inventory/slice';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {getToday, getYesterday} from 'utils/format-date';
import {ActivityLogsDateFilterAction, DateFilterStatus} from 'models/inventory.interface';

interface DateRangePickerComponentProps {
  onDateFiltersChange: (dateFilterAction: ActivityLogsDateFilterAction) => void;
}

const DateRangePickerComponent: FC<DateRangePickerComponentProps> = ({onDateFiltersChange}) => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const {activityLogsDateRangeFilter} = useAppSelector(getBaitCheckerState);

  const dispatch = useAppDispatch();

  const today = getToday();
  const yesterday = getYesterday();

  const applyDateRangeFilter = () => {
    dispatch(updateDateRangeFilter({startDate, endDate}));
    onDateFiltersChange({
      status: DateFilterStatus.APPLY,
      startDate,
      endDate,
    });
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value;
    if (endDate && newStartDate >= endDate) {
      return setStartDate('');
    }
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value;
    if (startDate && newEndDate <= startDate) {
      return setEndDate('');
    }
    setEndDate(newEndDate);
  };

  const showDateSubmitButton = () => {
    return startDate && endDate;
  };

  const handleClearDateRange = () => {
    setStartDate('');
    setEndDate('');
    dispatch(clearDateRangeFilter());
    onDateFiltersChange({
      status: DateFilterStatus.REMOVE,
      startDate: null,
      endDate: null,
    });
  };

  return (
    <div className="date-filter-logs">
      <div className="from-sec">
        <label>From</label>
        <input type="date" value={startDate} onChange={handleStartDateChange} max={yesterday} />
      </div>
      <div className="to-sec">
        <label>To</label>
        <input type="date" value={endDate} onChange={handleEndDateChange} max={today} />
      </div>
      {showDateSubmitButton() && (
        <div className="apply-btn">
          <Button
            type="button"
            label="APPLY_DATE_FILTER"
            isDisabled={startDate === '' || endDate === ''}
            onClick={applyDateRangeFilter}
          />
        </div>
      )}

      {activityLogsDateRangeFilter && <Button type="button" label="CLEAR_DATE_FILTER" onClick={handleClearDateRange} />}
    </div>
  );
};

export default DateRangePickerComponent;
