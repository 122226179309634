import useTranslate from 'hooks/useTranslate';

const columnDefinitions = [
  {label: 'NAME', key: 'name'},
  {label: 'ACTIONS', key: 'actions'},
];

const TagsTableHeader = () => {
  const {parseUI} = useTranslate();

  const renderHeadingFields = () => {
    return columnDefinitions.map(column => (
      <div className="editable-header-cell" key={column.key}>
        <span>{parseUI(column.label)}</span>
      </div>
    ));
  };

  return <div className="editable-table-header">{renderHeadingFields()}</div>;
};

export default TagsTableHeader;
