import {FC} from 'react';

import {TableCell, TableRow} from '@mui/material';

import InfoTooltip from 'components/UI/InfoTooltip';
import {ScheduleMaintenanceForm} from 'models/maintenance-interface';

interface ScheduleMaintenanceTableProps {
  data: ScheduleMaintenanceForm;
}

const ScheduleMaintenanceTable: FC<ScheduleMaintenanceTableProps> = ({data}) => {
  return (
    <>
      <TableRow key={data.id}>
        <TableCell className="tooltip-col">
          {data.description.length > 30 ? (
            <InfoTooltip name={data.description}>
              <div className="table-text-ellipsis">{data.description}</div>
            </InfoTooltip>
          ) : (
            data.description
          )}
        </TableCell>
        <TableCell className="tooltip-col">
          {data.emailSubject.length > 30 ? (
            <InfoTooltip name={data.emailSubject}>
              <div className="table-text-ellipsis">{data.emailSubject}</div>
            </InfoTooltip>
          ) : (
            data.emailSubject
          )}
        </TableCell>
        <TableCell>{new Date(data.startTime).toUTCString()}</TableCell>
        <TableCell>{new Date(data.endTime).toUTCString()}</TableCell>
        <TableCell>{new Date(data.emailScheduledTime).toUTCString()}</TableCell>
      </TableRow>
    </>
  );
};

export default ScheduleMaintenanceTable;
