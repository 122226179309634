import {INDICATOR} from 'models/signal-color';

export const getSignalColor = (networkStrength: number) => {
  const signalColor = {
    one: null,
    two: null,
    three: null,
    four: null,
    five: null,
  };

  if (networkStrength > 5) {
    signalColor.one = INDICATOR.DARK;
    signalColor.two = INDICATOR.DARK;
    signalColor.three = INDICATOR.DARK;
    signalColor.four = INDICATOR.DARK;
    signalColor.five = INDICATOR.DARK;
  } else if (networkStrength >= 4) {
    signalColor.one = INDICATOR.DARK;
    signalColor.two = INDICATOR.DARK;
    signalColor.three = INDICATOR.DARK;
    signalColor.four = INDICATOR.DARK;
    signalColor.five = INDICATOR.LIGHT;
  } else if (networkStrength >= 3) {
    signalColor.one = INDICATOR.DARK;
    signalColor.two = INDICATOR.DARK;
    signalColor.three = INDICATOR.DARK;
    signalColor.four = INDICATOR.LIGHT;
    signalColor.five = INDICATOR.BLUR;
  } else if (networkStrength >= 1) {
    signalColor.one = INDICATOR.DARK;
    signalColor.two = INDICATOR.LIGHT;
    signalColor.three = INDICATOR.BLUR;
    signalColor.four = INDICATOR.BLUR;
    signalColor.five = INDICATOR.BLUR;
  } else {
    signalColor.one = INDICATOR.BLUR;
    signalColor.two = INDICATOR.BLUR;
    signalColor.three = INDICATOR.BLUR;
    signalColor.four = INDICATOR.BLUR;
    signalColor.five = INDICATOR.BLUR;
  }

  return signalColor;
};

export const getIndicatorPercentage = (signalLevel: number) => {
  return `${+signalLevel} % `;
};
