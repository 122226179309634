import * as Yup from 'yup';
import {DECIMALS_NUMBER_REGEX, NUMBER_REGEX, PASSWORD_REGEX, TAG_NAME_REGEX} from './regex';
import {uniqueArray} from './unique-array';

const email = {
  email: Yup.string().trim().lowercase().email('INVALID_EMAIL').required('EMAIL_IS_REQUIRED'),
};

const password = {
  password: Yup.string().trim().required('PASSWORD_IS_REQUIRED'),
};

const firstName = {
  firstName: Yup.string().trim().required('FIRST_NAME_REQUIRED').max(120, 'MAX_LENGTH_60'),
};

const lastName = {
  lastName: Yup.string().trim().required('LAST_NAME_REQUIRED').max(120, 'MAX_LENGTH_60'),
};

const phoneNumber = {
  phoneNumber: Yup.string()
    .trim()
    .notRequired()
    //.matches(PHONE_NUMBER, 'SPECIFIC_NUMBERS_FORMAT_ALLOWED')
    .min(11, 'PHONE_LENGTH')
    .max(20, 'PHONE_LENGTH'),
};

const name = {
  name: Yup.string().trim().required('PEST_COMPANY_NAME_REQUIRED').max(120, 'MAX_LENGTH_120'),
};

const personName = {
  personName: Yup.string().trim().required('PERSON_NAME_REQUIRED').max(120, 'MAX_LENGTH_120'),
};

const street = {
  street: Yup.string().trim().required('STREET_HOUSE_NUMBER_REQUIRED').max(100, 'STREET_MAX_LENGTH_100'),
};

const city = {
  city: Yup.string().trim().required('CITY_REQUIRED').max(80, 'MAX_LENGTH_80'),
};

const postCode = {
  postCode: Yup.string()
    .test('is-valid-german-postal-code', 'POST_CODE_ALLOWED_NUMBERS', value => {
      //const numericPostalCode = value.replace(/\D/g, '');
      //const numericValue = parseInt(numericPostalCode, 10);
      //return numericValue >= 1067 && numericValue <= 99998;
      return !(value.length < 5 || value.length > 20);
    })
    .required('POST_CODE_REQUIRED'),
};

const taxId = {
  taxId: Yup.string().trim().optional().min(4, 'EXACT_LENGTH_11').max(15, 'EXACT_LENGTH_11'),
};

const language = {
  languageId: Yup.string().trim().required('LANGUAGE_REQUIRED'),
};

const baitboxTag = {
  name: Yup.string()
    .trim()
    .matches(TAG_NAME_REGEX, 'TAG_NAME_ALPHANUMERIC')
    .min(5, 'TAG_NAME_MIN_5')
    .max(255, 'TAG_NAME_MAX_255')
    .required('TAG_NAME_REQUIRED'),
};

const baitBoxComment = {
  text: Yup.string()
    .trim()
    .required('COMMENT_IS_REQUIRED')
    .min(5, 'COMMENT_MIN_LENGTH_5')
    .max(1000, 'COMMENT_MAX_LENGTH_1000'),
};

/* The code is defining a schema called `LoginSchema` using the Yup library. */
export const LoginSchema = Yup.object().shape({
  ...email,
  ...password,
});

/* The code is defining a schema called `ChangePasswordSchema` using the Yup library. This schema is
used to validate the input fields when changing a password. */
export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().trim().required('CURRENT_PASSWORD_REQUIRED'),
  password: Yup.string()
    .trim()
    .required('NEW_PASSWORD_REQUIRED')
    .min(8, 'PASSWORD_LENGTH')
    .matches(PASSWORD_REGEX, 'CHANGE_PASSWORD_STRENGTH'),
  passwordConfirm: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], 'PASSWORD_MUST_MATCH')
    .required('CONFIRM_PASSWORD_REQUIRED'),
});

/* The code is defining a schema called `AddPestCompanySchema` using the Yup library. This schema is
used to validate the input fields when adding a pest company. */
export const AddPestCompanySchema = Yup.object().shape({
  ...firstName,
  ...lastName,
  ...email,
  ...phoneNumber,
  ...name,
  ...street,
  ...city,
  ...postCode,
  ...taxId,
  ...language,
});

/* The code is defining a schema called `UpdatePestCompanySchema` using the Yup library. This schema is
used to validate the input fields when updating a pest company. */
export const UpdatePestCompanySchema = Yup.object().shape({
  ...name,
  ...personName,
  ...email,
  ...phoneNumber,
  ...street,
  ...city,
  ...postCode,
  ...taxId,
  ...language,
});

export const AddBaitCheckerInventorySchema = Yup.object().shape({
  iccNumbers: Yup.string()
    .test('validLength', 'INVALID_ICC_NUMBER', function (value) {
      if (!value) return true;
      const iccNumbers = value.split(',');

      if (iccNumbers.length !== uniqueArray(iccNumbers).length) {
        return this.createError({
          message: 'UNIQUE_ICC_NUMBER',
        });
      }

      const inValidValues = [];
      for (const iccNumber of iccNumbers) {
        if (!NUMBER_REGEX.test(iccNumber)) {
          inValidValues.push(iccNumber);
        }
      }

      if (inValidValues.length > 0) {
        return this.createError({
          message: `${'INVALID_ICC_NUMBER'}|${inValidValues.join(',')}`,
        });
      }
      return true;
    })
    .required('ICC_NUMBER_REQUIRED'),
});

export const EditBaitCheckerSchema = Yup.object().shape({
  tareOffset: Yup.string()
    .matches(DECIMALS_NUMBER_REGEX, {message: 'INVALID_NUMBER'})
    .test('max-length-1000', 'MAXIMUM_TARE_OFFSET_VALUE', value => parseFloat(value) <= 1000)
    .required('TAREOFFSET_REQUIRED'),
  multiplier: Yup.string()
    .test('Is positive?', 'POSITIVE_NUMBER', value => parseInt(value) > 0)
    .test('max-length-1000', 'MAXIMUM_MULTIPLIER_VALUE', value => parseFloat(value) <= 1000)
    .matches(DECIMALS_NUMBER_REGEX, {message: 'INVALID_NUMBER'})
    .required('MULTIPLIER_REQUIRED'),
});

export const AddBaitBoxTagSchema = Yup.object().shape({
  ...baitboxTag,
});

export const EditBaitBoxTagSchema = Yup.object().shape({
  ...baitboxTag,
});

export const AddBaitBoxCommentSchema = Yup.object().shape({
  ...baitBoxComment,
});

export const EditBaitBoxCommentSchema = Yup.object().shape({
  ...baitBoxComment,
});

export const BaitCheckerSimulatorSchema = Yup.object().shape({
  iccNumber: Yup.string()
    .matches(NUMBER_REGEX, 'INVALID_ICC')
    .required('ICC_NUMBER_REQUIRED')
    .test('is-valid-hash', 'ICC_NUMBER_IS_NOT_VALID', function () {
      return this.parent.isValidHash;
    }),

  batteryLevel: Yup.string()
    .matches(NUMBER_REGEX, 'BATTERY_LEVEL_SHOULD_BE_NUMBER')
    .test('is-valid-batteryLevel', 'BATTERY_LEVEL_SHOULD_BE_NUMBER', function (value) {
      const {path, createError} = this;
      const numValue = Number(value);

      if (numValue < 0) {
        return createError({
          path,
          message: 'MIN_BATTERY_LEVEL',
        });
      }
      if (numValue > 100) {
        return createError({
          path,
          message: 'MAX_BATTERY_LEVEL',
        });
      }

      return true;
    })
    .required('BATTERY_LEVEL_REQUIRED'),

  weight: Yup.string()
    .matches(NUMBER_REGEX, 'WEIGHT_SHOULD_BE_NUMBER')
    .test('is-valid-weight', 'WEIGHT_SHOULD_BE_NUMBER', function (value) {
      const {path, createError} = this;
      const numValue = Number(value);

      if (numValue < 0) {
        return createError({
          path,
          message: 'MIN_WEIGHT',
        });
      }
      return true;
    })
    .required('WEIGHT_REQUIRED'),

  networkStrength: Yup.string()
    .matches(NUMBER_REGEX, 'NETWORK_STRENGTH_SHOULD_BE_NUMBER')
    .test('is-valid-networkStrength', 'NETWORK_STRENGTH_SHOULD_BE_NUMBER', function (value) {
      const {path, createError} = this;
      const numValue = Number(value);
      if (numValue > 100) {
        return createError({
          path,
          message: 'MAX_NETWORK_STRENGTH',
        });
      }

      return true;
    })
    .max(100, 'MAX_NETWORK_STRENGTH')
    .required('NETWORK_STRENGTH_REQUIRED'),
});

export const ScheduleMaintenanceSchema = Yup.object().shape({
  description: Yup.string().trim().max(120, 'MAX_LENGTH_120').required('DESCRIPTION'),
  emailSubject: Yup.string().trim().max(60, 'MAX_LENGTH_60').required('EMAIL_SUBJECT'),
  startTime: Yup.date()
    .required('START_TIME_REQUIRED')
    .min(new Date(), 'START_TIME_MIN_CURRENT_DATE')
    .typeError('MUST_BE_VALID_DATE'),
  endTime: Yup.date()
    .required('END_TIME_REQUIRED')
    .min(Yup.ref('startTime'), 'END_TIME_MIN_START_TIME')
    .typeError('MUST_BE_VALID_DATE'),
  emailScheduledTime: Yup.date()
    .required('EMAIL_SCHEDULE_TIMESTAMP_REQUIRED')
    .min(new Date(), 'EMAIL_SCHEDULE_TIMESTAMP_MIN_CURRENT_TIME')
    .max(Yup.ref('startTime'), 'EMAIL_SCHEDULE_TIMESTAMP_MAX_START_TIME')
    .typeError('MUST_BE_VALID_DATE'),
});

const validateObjectKeys = (schema: any, validKeys: any) => {
  return Yup.mixed().test({
    name: 'keys-check',
    exclusive: true,
    message: `Invalid keys detected. Only these keys are allowed: ${validKeys.join(', ')}`,
    test: function (value) {
      if (value == null) {
        return true;
      }

      /** If the value is an object, use the checkKeys function to validate the keys */
      if (typeof value === 'object') {
        /** Validate the values of the keys */
        try {
          schema.validateSync(value);
        } catch (error) {
          return this.createError({message: error.errors[0]});
        }
        const isValid = checkKeys(value, schema);
        if (!isValid) {
          throw new Error(
            `Invalid keys detected in ${JSON.stringify(value)}. Only these keys are allowed: ${validKeys.join(', ')}`,
          );
        }
        return isValid;
      }

      /** If the value is not an object, just check if the key is valid */
      return validKeys.includes(value);
    },
  });
};

/**
 * @desc: to validate nested keys in device settings object
 * @param jsonObject
 * @param schema
 * @returns
 */
const checkKeys = (jsonObject: any, schema: any): boolean => {
  const validKeys = Object.keys(schema.describe().fields);

  for (const key in jsonObject) {
    if (!validKeys.includes(key)) {
      return false;
    }

    if (
      typeof jsonObject[key] === 'object' &&
      jsonObject[key] !== null &&
      schema.fields[key] instanceof Yup.object &&
      !checkKeys(jsonObject[key], schema.fields[key])
    ) {
      return false;
    }
  }

  return true;
};

const deviceSettingsSchema = Yup.object({
  scale: validateObjectKeys(
    Yup.object({
      calm_weight: validateObjectKeys(
        Yup.object({
          repeat_measure_per_sample: Yup.number()
            .positive()
            .integer()
            .min(1, 'REPEAT_MEASURE_PER_SAMPLE_MIN')
            .typeError('REPEAT_MEASURE_PER_SAMPLE_NUMBER'),
          max_difference: Yup.number().positive().integer().min(10, 'MAX_DIFFERENCE_MIN'),
          continuous_values: Yup.number()
            .positive()
            .integer()
            .min(1, 'CONTINUOUS_VALUES_MIN')
            .typeError('CONTINUOUS_VALUES_NUMBER'),
          time_between_measures_ms: Yup.number()
            .positive()
            .integer()
            .min(1, 'TIME_BETWEEN_MEASURES_MIN')
            .typeError('TIME_BETWEEN_MEASURES_NUMBER'),
          weigh_timeout_ms: Yup.number()
            .positive()
            .integer()
            .min(1, 'WEIGHT_TIMEOUT_MIN')
            .typeError('WEIGHT_TIMEOUT_NUMBER'),
          tare_timeout_ms: Yup.number()
            .positive()
            .integer()
            .min(1, 'TARE_TIMEOUT_MIN')
            .typeError('TARE_TIMEOUT_NUMBER'),
          calibration_timeout_ms: Yup.number()
            .positive()
            .integer()
            .min(1, 'CALIBRATION_TIMEOUT_MIN')
            .typeError('CALIBRATION_TIMEOUT_NUMBER'),
        }).noUnknown(true),
        [
          'repeat_measure_per_sample',
          'max_difference',
          'continuous_values',
          'time_between_measures_ms',
          'weigh_timeout_ms',
          'tare_timeout_ms',
          'calibration_timeout_ms',
        ],
      ),
      calibration_weight: Yup.number()
        .positive()
        .integer()
        .min(1, 'CALIBRATION_WEIGHT_MIN')
        .typeError('CALIBRATION_WEIGHT_NUMBER'),
      save_scale_calibration_to_flash: Yup.boolean().typeError('SAVE_SCALE_CALIBRATION_BOOLEAN'),
      transmit_raw_scale_data: Yup.boolean().typeError('TRANSMIT_RAW_SCALE_DATA'),
    }).noUnknown(true),
    ['calm_weight', 'calibration_weight', 'save_scale_calibration_to_flash', 'transmit_raw_scale_data'],
  ),
  sleep: validateObjectKeys(
    Yup.object({
      after_success_s: Yup.number()
        .positive()
        .integer()
        .min(1, 'AFTER_SUCCESS_MIN')
        .max(172800, 'AFTER_SUCCESS_MAX')
        .typeError('AFTER_SUCCESS_NUMBER'),
      after_no_network_available_s: Yup.number()
        .positive()
        .integer()
        .min(1, 'AFTER_NO_NETWORK_AVAILABLE_MIN')
        .max(1800, 'AFTER_NO_NETWORK_AVAILABLE_MAX')
        .typeError('AFTER_NO_NETWORK_AVAILABLE_NUMBER'),
      after_transmitter_error_s: Yup.number()
        .positive()
        .integer()
        .min(1, 'AFTER_TRANSMITTER_ERROR_MIN')
        .max(1800, 'AFTER_TRANSMITTER_ERROR_MAX')
        .typeError('AFTER_TRANSMITTER_ERROR_NUMBER'),
      after_backend_error_s: Yup.number()
        .positive()
        .integer()
        .min(1, 'AFTER_BACKEND_ERROR_MIN')
        .max(1800, 'AFTER_BACKEND_ERROR_MAX')
        .typeError('AFTER_BACKEND_ERROR_NUMBER'),
      after_not_calibrated_s: Yup.number()
        .positive()
        .integer()
        .min(1, 'AFTER_NOT_CALIBRATED_MIN')
        .max(60, 'AFTER_NOT_CALIBRATED_MAX')
        .typeError('AFTER_NOT_CALIBRATED_NUMBER'),
      after_calm_weight_timeout_s: Yup.number()
        .positive()
        .integer()
        .min(1, 'AFTER_CALM_WEIGHT_TIMEOUT_MIN')
        .max(1800, 'AFTER_CALM_WEIGHT_TIMEOUT_MAX')
        .typeError('AFTER_CALM_WEIGHT_TIMEOUT_NUMBER'),
      keep_powered_on: Yup.boolean().typeError('KEEP_POWERED_ON_BOOLEAN'),
    }).noUnknown(true),
    [
      'after_success_s',
      'after_no_network_available_s',
      'after_transmitter_error_s',
      'after_backend_error_s',
      'after_not_calibrated_s',
      'after_calm_weight_timeout_s',
      'keep_powered_on',
    ],
  ),
  wifi: validateObjectKeys(
    Yup.object({
      allow_sleep_while_client_is_connected: Yup.boolean().typeError('ALLOW_SLEEP_WHILE_CLIENT_IS_CONNECTED_BOOLEAN'),
      enable_wifi_after_deep_sleep: Yup.boolean().typeError('ENABLE_WIFI_AFTER_DEEP_SLEEP_BOOLEAN'),
      login_timeout_on_power_on_s: Yup.number()
        .positive()
        .integer()
        .min(1, 'LOGIN_TIMEOUT_ON_POWER_ON_MIN')
        .max(600, 'LOGIN_TIMEOUT_ON_POWER_ON_MAX')
        .typeError('LOGIN_TIMEOUT_ON_POWER_ON_NUMBER'),
      login_timeout_after_sleep_s: Yup.number()
        .positive()
        .integer()
        .min(1, 'LOGIN_TIMEOUT_AFTER_SLEEP_MIN')
        .max(600, 'LOGIN_TIMEOUT_AFTER_SLEEP_MAX')
        .typeError('LOGIN_TIMEOUT_AFTER_SLEEP_NUMBER'),
      ssid_prefix: Yup.string().typeError('SSID_PREFIX_NUMBER'),
    }).noUnknown(true),
    [
      'allow_sleep_while_client_is_connected',
      'enable_wifi_after_deep_sleep',
      'login_timeout_on_power_on_s',
      'login_timeout_after_sleep_s',
      'ssid_prefix',
    ],
  ),
}).noUnknown(true);

export const AddDeviceSettingsSchema = Yup.object({
  deviceSettings: Yup.string()
    .required('DEVICE_SETTINGS')
    .test('is-json', 'INVALID_JSON_FORMAT_FOR_DEVICE_SETTINGS', function (value) {
      try {
        const jsonObject = JSON.parse(value);
        try {
          deviceSettingsSchema.validateSync(jsonObject);
        } catch (error) {
          return this.createError({message: error.errors[0]});
        }
        /** Check that the parsed JSON object only contains the keys defined in deviceSettingsSchema */
        return !!checkKeys(jsonObject, deviceSettingsSchema);
      } catch (error) {
        return this.createError({
          message: 'INVALID_JSON_FORMAT_FOR_DEVICE_SETTINGS',
        });
      }
    }),
});
