import {FC} from 'react';
import {Grid, Typography} from '@mui/material';
import {debounce} from 'lodash';
import InfoTooltip from 'components/UI/InfoTooltip';
import useTranslate from 'hooks/useTranslate';
import {useAppSelector} from 'redux/redux';

import {pestCompanyDetails, selectPestCompanyData} from 'redux/pest-company/selector';
import {DEBOUNCE_TIME} from 'constants/debouce-time';
import SearchBar from 'components/UI/searchbar/SearchBar';
import ClientBaitBoxSelectOptions from './multi-select-options/ClientBaitBoxSelectedOptions';

const {BAITCHECKER_SEARCH_DELAY} = DEBOUNCE_TIME;

type PestBaitBoxHeaderType = {
  textChange?: (text: string) => void;

  getSelectedBaitBoxesByStatus: (isActive: number) => any[];
};

const PageHeader: FC<PestBaitBoxHeaderType> = ({textChange, getSelectedBaitBoxesByStatus}) => {
  const {name, email, personName, city, street, totalBaitBox, activeBaitBox, inActiveBaitBox} =
    useAppSelector(pestCompanyDetails);

  const {parseUI} = useTranslate();

  const {pestCompanyBaitBoxes, baitboxSearchText} = useAppSelector(selectPestCompanyData);

  const textSearchHandler = (text: string) => {
    textChange(text);
  };

  const handleSearch = debounce(textSearchHandler, BAITCHECKER_SEARCH_DELAY);

  return (
    <Grid container justifyContent="space-between" alignItems="center" className="detail-header">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {name}
        </Typography>
        <div className="box-detail">
          <Typography variant="h5" component="h5" gutterBottom>
            <span className="baitVal">
              {totalBaitBox}
              <span>{parseUI('TOTAL_BAIT_BOXES')}</span>
            </span>
          </Typography>
          <Typography variant="h5" component="h5" gutterBottom>
            <InfoTooltip name={parseUI('ACTIVE_BAIT_BOXES')}>
              <div className="info-outer activeInfo">
                <div className="baitactive">{activeBaitBox}</div>
              </div>
            </InfoTooltip>
            <span>{parseUI('ACTIVE_BAIT_BOXES')}</span>
          </Typography>
          <Typography variant="h5" component="h5" gutterBottom>
            <InfoTooltip name={parseUI('INACTIVE_BAIT_BOXES')}>
              <div className="info-outer activeInfo">
                <div className="baitinactive">{inActiveBaitBox}</div>
              </div>
            </InfoTooltip>
            <span>{parseUI('INACTIVE_BAIT_BOXES')}</span>
          </Typography>
        </div>
        <div className="search-dropdown">
          {(pestCompanyBaitBoxes.length > 0 || baitboxSearchText) && (
            <SearchBar onInputChange={handleSearch} textChange={textChange} />
          )}
          <ClientBaitBoxSelectOptions
            getSelectedBaitBoxesByStatus={getSelectedBaitBoxesByStatus}
            textChange={textChange}
          />
        </div>
      </Grid>

      <Grid className="pest-address">
        <Grid item className="bait-header cus-info">
          <h4> {parseUI('ADDRESS')}</h4>
          <div>
            <h5>{parseUI('CITY')}</h5>
            <span>{city}</span>
          </div>
          <div>
            <h5>{parseUI('STREET')}</h5>
            <span className="street-address">{street}</span>
          </div>
        </Grid>
        <Grid item className="bait-header cus-info">
          <h4> {parseUI('CONTACT')}</h4>
          <div>
            <h5>{parseUI('PERSON_NAME')}</h5>
            <span>{personName}</span>
          </div>
          <div>
            <h5>{parseUI('CONTACT_EMAIL')}</h5>
            <span>{email}</span>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
