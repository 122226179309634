import axiosInstance from 'api/api-service';
import {
  ACTIVITY_LOGS,
  BAIT_BOX,
  BAIT_BOX_ACTION,
  BAIT_BOX_LOGS,
  BAIT_BOX_TAG,
  CONFIGURATIONS,
} from 'constants/api-endpoints';
import {HttpResponse} from 'models/http-interface';
import {
  AssignUnassignTagBody,
  BaiBoxActivityFilter,
  BaitBoxActivityLogsResponse,
  BaitBoxCommentListParams,
  BaitBoxCommentsResponse,
  BaitboxDeviceSettings,
  CreateCommentBody,
  CreateSimulateDataV2,
  CSVDownloadResponse,
  DownloadActivityLogsCSV,
  GetAllAndAssignedTagResponse,
  GetAllAndAssignedTags,
  GetBaitBoxActivityLogsBody,
  GetBaitBoxResponse,
  GetBaitBoxStatusResponse,
  NewlyCreatedBaitBox,
  SelectedBaitCheckers,
  SimulateDataWithoutHash,
  UpdateBaitBoxMeta,
  UpdateBaitCheckersMultiplier,
  UpdateBaitCheckersTareOffset,
  UpdateCommentBody,
} from 'models/inventory.interface';
import {ListParams} from 'models/pest-company-interface';
import {getCreateBaitCheckerTimeout} from 'utils/create-baitchecker-timeout';

const {UPDATE_TARE_OFFSET, UPDATE_MULTIPLIER, ASSIGN_TAGS, UPDATE_DEVICE_SETTINGS} = BAIT_BOX_ACTION;
const {COMMENTS} = BAIT_BOX;
const {GET_BAIT_BOX_TAGS} = BAIT_BOX_TAG;
const {SIMULATE, SIMULATE_V2} = BAIT_BOX_LOGS;
const {GET_BAIT_BOX_LOGS, GET_BAIT_BOX_LOGS_FILTERS, DOWNLOAD_CSV} = ACTIVITY_LOGS;
const {SIMULATOR_API_LIMIT} = CONFIGURATIONS;

export const createBaitBoxInventory = (body: {iccNumbers: string}) => {
  return axiosInstance.post<
    HttpResponse<{
      alreadyExists: NewlyCreatedBaitBox[];
      newSimIds: NewlyCreatedBaitBox[];
    }>
  >(BAIT_BOX.CREATE_INVENTORY, body, {
    timeout: getCreateBaitCheckerTimeout(body.iccNumbers),
  });
};

export const listBaitBoxInventory = (params: ListParams) => {
  return axiosInstance.get<HttpResponse<GetBaitBoxResponse>>(BAIT_BOX.LIST_INVENTORY, {
    params,
  });
};

export const editBaitBoxMeta = (body: UpdateBaitBoxMeta) => {
  const {baitBoxId, ...data} = body;
  return axiosInstance.put<HttpResponse<null>>(`${BAIT_BOX.EDIT_INVENTORY}/${baitBoxId}`, data);
};

export const deactivateMultipleBaitBox = (body: {baitBoxes: SelectedBaitCheckers[]}) => {
  return axiosInstance.put<HttpResponse<null>>(`${BAIT_BOX.DEACTIVATE_MULTIPLE}`, body);
};

export const updateTareOffsetOfMultipleBaitboxes = (body: UpdateBaitCheckersTareOffset) => {
  return axiosInstance.patch<HttpResponse<null>>(`${UPDATE_TARE_OFFSET}`, body);
};

export const updateMultiplierOfMultipleBaitboxes = (body: UpdateBaitCheckersMultiplier) => {
  return axiosInstance.patch<HttpResponse<null>>(`${UPDATE_MULTIPLIER}`, body);
};

export const getBaitCheckerAllAndAssignedTags = (body: GetAllAndAssignedTags) => {
  const {baitBoxId, ...params} = body;
  return axiosInstance.get<HttpResponse<GetAllAndAssignedTagResponse>>(`${GET_BAIT_BOX_TAGS}/${baitBoxId}`, {params});
};

export const updateBaitBoxTags = (body: AssignUnassignTagBody) => {
  const {baitBoxId, data} = body;
  return axiosInstance.put<HttpResponse<null>>(`${ASSIGN_TAGS}/${baitBoxId}`, {
    data,
  });
};

export const getBaitCheckerCommentsList = (params: BaitBoxCommentListParams) => {
  return axiosInstance.get<HttpResponse<BaitBoxCommentsResponse>>(COMMENTS, {
    params,
  });
};

export const updateBaitBoxComment = (body: UpdateCommentBody) => {
  const {commentId, ...data} = body;
  return axiosInstance.patch<HttpResponse<null>>(`${BAIT_BOX.EDIT_COMMENT}/${commentId}`, data);
};

export const removeBaitBoxComment = (commentId: string) => {
  return axiosInstance.patch<HttpResponse<null>>(`${BAIT_BOX.REMOVE_COMMENT}/${commentId}`);
};

export const createBaitBoxComment = (body: CreateCommentBody) => {
  return axiosInstance.post<HttpResponse<null>>(`${BAIT_BOX.ADD_COMMENT}`, body);
};

export const getBaitBoxStatus = (iccNumber: string) => {
  return axiosInstance.get<HttpResponse<GetBaitBoxStatusResponse>>(`${BAIT_BOX.LIST_INVENTORY}/${iccNumber}`);
};

export const simulateBaitBoxLogs = (simulatorBody: SimulateDataWithoutHash) => {
  return axiosInstance.post<HttpResponse<null>>(`${SIMULATE}`, simulatorBody);
};
export const simulateBaitBoxLogsV2 = (simulatorBody: CreateSimulateDataV2) => {
  return axiosInstance.post<HttpResponse<null>>(`${SIMULATE_V2}`, simulatorBody.blob, {
    headers: {'Content-Type': 'application/octet-stream'},
    responseType: 'arraybuffer',
  });
};

export const getBaitBoxActivityLogs = (baitBoxActivityLogsBody: GetBaitBoxActivityLogsBody) => {
  const {baitBoxId, ...params} = baitBoxActivityLogsBody;
  return axiosInstance.get<HttpResponse<BaitBoxActivityLogsResponse>>(`${GET_BAIT_BOX_LOGS}/${baitBoxId}`, {
    params,
  });
};

export const getActivityLogsFilters = (baitBoxId: string) => {
  return axiosInstance.get<HttpResponse<BaiBoxActivityFilter>>(`${GET_BAIT_BOX_LOGS_FILTERS}/${baitBoxId}`);
};

export const downloadActivityLogsCSV = (downloadCSVBody: DownloadActivityLogsCSV) => {
  const {baitBoxId, ...params} = downloadCSVBody;
  return axiosInstance.get<HttpResponse<CSVDownloadResponse>>(`${DOWNLOAD_CSV}/${baitBoxId}`, {
    params,
  });
};

export const getSimulatorConfiguration = () => {
  return axiosInstance.get<HttpResponse<any>>(`${SIMULATOR_API_LIMIT}`);
};

/**
 * @desc: to update device settings of baitbox in DB
 * @param body
 * @returns
 */
export const updateBaitboxDeviceSettings = (body: BaitboxDeviceSettings) => {
  const {baitBoxIds, deviceSettings} = body;

  return axiosInstance.put<HttpResponse<null>>(UPDATE_DEVICE_SETTINGS, {
    deviceSettings,
    baitBoxIds,
  });
};
