import {getBaitCheckerState} from 'redux/inventory/selector';
import {useAppSelector} from 'redux/redux';
import CommentRow from './CommentRow';

const CommentsTableBody = () => {
  const {baitCheckerComments} = useAppSelector(getBaitCheckerState);

  const renderRows = () =>
    baitCheckerComments.length > 0 &&
    baitCheckerComments.map(comment => {
      return <CommentRow key={comment.uuid} comment={comment} />;
    });
  return <div className="editable-table-content">{renderRows()}</div>;
};

export default CommentsTableBody;
