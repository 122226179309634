export enum ROUTES {
  LOGIN = 'login',
  CHANGE_PASSWORD = 'change-password',
  DASHBOARD = 'dashboard',
  PEST_COMPANY = 'pest-company',
  ADD_PEST_COMPANY = 'add-pest-company',
  ADD_BAIT_CHECKER = 'add-bait-checker',
  VIEW_PEST_COMPANY = 'view-pest-company',
  EDIT_PEST_COMPANY = 'edit-pest-company',
  INTERNAL_SERVER_ERROR = 'internal-server-error',
  BAIT_CHECKER = 'bait-checker',
  LIST_BAIT_CHECKER = 'bait-checker-list',
  LIST_TAGS = 'tags',
  ADD_TAG = 'add-tag',
  EDIT_TAG = 'edit-tag',
  ASSIGN_TAG = 'assign-tag',
  BAIT_CHECKER_SIMULATOR = 'bait-checker-simulator',
  BAIT_BOX_COMMENTS = 'comments',
  ADD_COMMENT = 'add-comment',
  EDIT_COMMENT = 'edit-comment',
  BAIT_BOX_LOGS = 'logs',
  NOT_FOUND = '*',
  SCHEDULE_MAINTENANCE = 'schedule-maintenance',
}
