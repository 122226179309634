import {createSlice} from '@reduxjs/toolkit';

interface Item {
  uuid: string;
  isCheck: boolean;
  isActive: number | boolean;
}

interface State<T> {
  items: Record<string, T>;
  selectedData: Record<string, T>;
  isCheckAll: boolean;
  isAllDisabled: boolean;
}

const initialState: State<any> = {
  items: {},
  selectedData: {},
  isCheckAll: false,
  isAllDisabled: false,
};

const checkBoxSlice = createSlice({
  name: 'checkBox',
  initialState,
  reducers: {
    syncCode(state, action) {
      const data: Item[] = action.payload;
      const hashMap = {};
      let checkAll = true;
      let allDisabled = 0;
      for (const {uuid, isActive, ...restData} of data) {
        const isCheck = !!state.selectedData[uuid] && isActive;
        if (isActive && !isCheck) {
          checkAll = false;
        }
        allDisabled = isActive ? allDisabled - 1 : allDisabled + 1;
        hashMap[uuid] = {
          uuid,
          isActive,
          isCheck,
          ...restData,
        };
      }
      state.isCheckAll = checkAll;
      state.isAllDisabled = allDisabled === data.length;
      state.items = hashMap;
    },

    syncSelected(state, action) {
      const data: Item[] = action.payload;
      const hashMap = {};

      for (const {uuid, ...restData} of data) {
        hashMap[uuid] = {
          uuid,
          ...restData,
        };
      }
      state.selectedData = hashMap;
    },

    toggleData(state, action) {
      const id: string = action.payload;
      const currentItem = state.items[id];

      const updatedItem = {
        ...currentItem,
        isCheck: !currentItem.isCheck,
      };
      state.items[id] = updatedItem;
      if (updatedItem.isCheck) {
        state.selectedData[id] = updatedItem;
      } else {
        delete state.selectedData[id];
      }

      state.isCheckAll = checkAllCheckBoxChecked(state.items, state.selectedData);

      return state;
    },

    checkUnCheckAll(state) {
      for (const key in state.items) {
        const data = state.items[key];
        if (state.isCheckAll && data.isActive) {
          data.isCheck = false;
          delete state.selectedData[key];
        } else if (!state.isCheckAll && data.isActive) {
          data.isCheck = true;
          state.selectedData[key] = data;
        }
      }

      state.isCheckAll = !state.isCheckAll;
    },

    reset() {
      return initialState;
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
const checkAllCheckBoxChecked = (data: Object, selected: Object) => {
  let allCheck = true;
  for (const key in data) {
    const item = data[key];
    if (item.isActive && !selected[key]) {
      allCheck = false;
      break;
    }
  }
  return allCheck;
};

export const {syncCode, syncSelected, toggleData, checkUnCheckAll, reset} = checkBoxSlice.actions;

export const checkBoxReducer = checkBoxSlice.reducer;
