import {CheckboxInventoryData} from 'models/inventory.interface';
import {useCallback} from 'react';
import {getCheckBoxData} from 'redux/checkBox/selector';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import TableRow from './TableRow';
import {toggleData} from 'redux/checkBox/slice';

const InventoryTableBody = () => {
  const dispatch = useAppDispatch();
  const handleRowSelect = useCallback((row: CheckboxInventoryData) => {
    dispatch(toggleData(row.uuid));
  }, []);

  const item = useAppSelector(getCheckBoxData);

  return (
    <div className="editable-table-content">
      {item.map(row => (
        <TableRow key={row.uuid} data={row} onSelect={() => handleRowSelect(row)} />
      ))}
    </div>
  );
};

export default InventoryTableBody;
