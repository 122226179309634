import {
  AssignTags,
  BaitBoxActivityLog,
  BaitCheckerComment,
  BaitCheckerInventory,
  DateRange,
  SelectedBaitCheckers,
  SimulatorBaitBoxState,
} from 'models/inventory.interface';

export interface InitialPestCompanyState {
  isLoading: boolean;
  inventoryList: BaitCheckerInventory[];
  inventoryDetails: BaitCheckerInventory;
  count: number | null;
  searchText: string | null;
  sortingKey: string | null;
  sortingOrder: number | null;
  multiSelectOptionActionLoading: boolean;
  selectedBaitCheckersForAction: SelectedBaitCheckers[];
  baitCheckerTags: AssignTags[];
  getAssignedTagsLoading: boolean;
  baitCheckerCommentsLoading: boolean;
  commentsCount: number | null;
  baitCheckerComments: BaitCheckerComment[];
  selectAllActivated: boolean;
  isAllActiveSelected: boolean;
  logsSearchText: string | null;
  logsSortingKey: string | null;
  logsSortingOrder: number | null;
  logsPestCompaniesFilterValues: string[];
  logsClientCompaniesFilterValues: string[];
  logsEventsFilterValues: string[];
  baitBoxActivityLogsCount: number | null;
  activityLogs: BaitBoxActivityLog[];
  activityLogsDateRangeFilter: DateRange;
  baitBoxTagsSearchText: string | null;
  baitBoxTagCount: number | null;
  loadingSimulator: boolean;
  simulatorBaitBoxState: SimulatorBaitBoxState;
  csvDownloadLoading: boolean;
  isUpdateBaitboxDeviceSettings: boolean;
}

const initialInventoryState: InitialPestCompanyState = {
  isLoading: false,
  inventoryList: [],
  inventoryDetails: null,
  count: null,
  searchText: null,
  sortingKey: null,
  sortingOrder: null,
  multiSelectOptionActionLoading: false,
  selectedBaitCheckersForAction: [],
  getAssignedTagsLoading: false,
  baitCheckerCommentsLoading: false,
  commentsCount: null,
  baitCheckerComments: [],
  selectAllActivated: false,
  isAllActiveSelected: false,
  logsSearchText: null,
  logsSortingKey: null,
  logsSortingOrder: null,
  logsPestCompaniesFilterValues: [],
  logsClientCompaniesFilterValues: [],
  logsEventsFilterValues: [],
  baitBoxActivityLogsCount: null,
  activityLogs: [],
  activityLogsDateRangeFilter: null,
  baitCheckerTags: [],
  baitBoxTagsSearchText: null,
  baitBoxTagCount: null,
  loadingSimulator: false,
  simulatorBaitBoxState: null,
  csvDownloadLoading: false,
  isUpdateBaitboxDeviceSettings: false,
};

export default initialInventoryState;
