import {ChangeEvent} from 'react';
import useTranslate from 'hooks/useTranslate';

const TextInput = ({value, placeholder, onChange}) => {
  const {parseUI} = useTranslate();
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <input
      type="text"
      className="form-control"
      placeholder={parseUI(placeholder)}
      value={value}
      onChange={handleInputChange}
    />
  );
};

export default TextInput;
