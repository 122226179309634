import TagsTableHeader from './Header';
import TagsTableBody from './Body';

const BaitBoxTagList = () => {
  return (
    <section className="common-table editable">
      <div className="editable-table full-width-table">
        <TagsTableHeader />
        <TagsTableBody />
      </div>
    </section>
  );
};

export default BaitBoxTagList;
