import {useAppSelector} from 'redux/redux';
import Row from './TableRow';
import {scheduleMaintenanceData} from 'redux/scheduled-maintenance/selector';

const ScheduleMaintenanceTableBody = () => {
  const item = useAppSelector(scheduleMaintenanceData);

  return (
    <div className="editable-table-content">
      {item.map(row => (
        <Row key={row.id} data={row} />
      ))}
    </div>
  );
};

export default ScheduleMaintenanceTableBody;
