import {FC} from 'react';
import {Paper, Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {useAppSelector} from 'redux/redux';
import TagTableHeader from './table/TableHeader';
import TagTableBody from './table/TableBody';
import Loader from 'components/shared/loader/Loader';
import DataNotFound from 'components/shared/DataNotFound';
import PageTitleWrapper from 'components/PageTitleWrapper';
import PageHeader from './page-header/PageHeader';
import TablePagination from 'components/shared/table-pagination/TablePagination';
import {countPages} from 'utils/page-count';

type AssignTagListProp = {
  pagination: {
    limit: number;
    page: number;
    onPageChange: (newPage: number) => void;
    onLimitChange: (newLimit: number) => void;
  };
  textChange?: (text: string) => void;
};

const AssignTagsListing: FC<AssignTagListProp> = ({pagination, textChange}) => {
  const {getAssignedTagsLoading, baitCheckerTags, baitBoxTagCount} = useAppSelector(getBaitCheckerState);

  const {page, limit, onPageChange, onLimitChange} = pagination;

  return (
    <>
      <PageTitleWrapper>
        <PageHeader textChange={textChange} />
      </PageTitleWrapper>
      {baitCheckerTags && baitCheckerTags.length > 0 ? (
        <div className="body-wrapper">
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="">
                <TableRow>
                  <TagTableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                <TagTableBody />
              </TableBody>
            </Table>
            <TablePagination
              pageChange={onPageChange}
              page={page}
              count={countPages(baitBoxTagCount, limit)}
              limit={limit}
              limitChange={onLimitChange}
            />
          </TableContainer>
          <Loader show={getAssignedTagsLoading} />
        </div>
      ) : (
        <DataNotFound />
      )}
    </>
  );
};

export default AssignTagsListing;
