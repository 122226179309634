import {usePagination} from 'hooks/usePagination';
import PageTitleWrapper from 'components/PageTitleWrapper';

import BiatboxList from './baitbox-listing-components/BiatboxList';
import PageHeader from './baitbox-listing-components/PageHeader';
import {getPestCompanyBaitBoxesAsync, getPestDetailsWithBaitBoxesAsync} from 'redux/pest-company/thunk';
import {
  cleanPestCompanyBaitBoxes,
  updatePestBaitBoxSearchText,
  updatePestBaitBoxSortingKeyAndOrder,
} from 'redux/pest-company/slice';
import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {useParams} from 'react-router';
import {selectPestCompanyData} from 'redux/pest-company/selector';
import useTableCheckBoxList from 'hooks/useTableCheckbox';

const PestCompanyDetails = () => {
  const {id} = useParams();
  const dispatch = useAppDispatch();

  const {baitboxSearchText, baitboxSortingKey, baitboxSortingOrder, pestCompanyBaitBoxes} =
    useAppSelector(selectPestCompanyData);

  const {page, limit, onPageChange, onLimitChange} = usePagination(1, 50);

  const {data, syncCode, toggleCheckBox, getSelectedBaitBoxesByStatus, toggleCheckAll, checkAll, markCheckUnCheck} =
    useTableCheckBoxList();

  useEffect(() => {
    syncCode(pestCompanyBaitBoxes, id);
  }, [pestCompanyBaitBoxes]);

  const onTextChange = (text: string) => {
    dispatch(updatePestBaitBoxSearchText({text}));
    if (page === 1) {
      dispatch(
        getPestCompanyBaitBoxesAsync({
          pestCompanyId: id,
          pageNumber: 1,
          limit,
          searchField: text,
          sortingField: baitboxSortingKey,
          sortingOrder: baitboxSortingOrder,
        }),
      );
    } else {
      onLimitChange(10);
    }
  };

  const getPestCompanyBaitBoxes = () => {
    dispatch(
      getPestCompanyBaitBoxesAsync({
        pestCompanyId: id,
        pageNumber: page,
        limit,
        searchField: baitboxSearchText,
        sortingField: baitboxSortingKey,
        sortingOrder: baitboxSortingOrder,
      }),
    );
  };

  useEffect(() => {
    if (page && limit) {
      getPestCompanyBaitBoxes();
    }
  }, [id, page, limit, baitboxSortingKey, baitboxSortingOrder]);

  useEffect(() => {
    // to get latest active/inactive bait boxes count
    dispatch(getPestDetailsWithBaitBoxesAsync(id));
  }, []);

  //cleanup
  useEffect(() => {
    return () => {
      dispatch(cleanPestCompanyBaitBoxes());
      dispatch(updatePestBaitBoxSearchText({text: null}));
      dispatch(
        updatePestBaitBoxSortingKeyAndOrder({
          sortingKeyOrder: {sortingKey: null, sortingOrder: null},
        }),
      );
    };
  }, []);
  return (
    <>
      {
        <PageTitleWrapper>
          <PageHeader textChange={onTextChange} getSelectedBaitBoxesByStatus={getSelectedBaitBoxesByStatus} />
        </PageTitleWrapper>
      }

      <BiatboxList
        data={data}
        toggleCheckBox={toggleCheckBox}
        pagination={{page, limit, onPageChange, onLimitChange}}
        toggleCheckAll={toggleCheckAll}
        textChange={onTextChange}
        markCheckUnCheck={markCheckUnCheck}
        isCheckAll={checkAll}
      />
    </>
  );
};

export default PestCompanyDetails;
