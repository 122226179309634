import {useAppSelector} from 'redux/redux';
import {getBaitBoxTagsState} from 'redux/tags/selector';
import TagRow from './TagRow';

const TagsTableBody = () => {
  const {tagsList} = useAppSelector(getBaitBoxTagsState);

  const renderRows = () =>
    tagsList.length > 0 &&
    tagsList.map(tag => {
      return <TagRow key={tag.uuid} tag={tag} />;
    });

  return <div className="editable-table-content">{renderRows()}</div>;
};

export default TagsTableBody;
