import {createAsyncThunk} from '@reduxjs/toolkit';
import {ScheduleMaintenanceForm} from 'models/maintenance-interface';
import {getUpcomingScheduleMaintenance, scheduleMaintenance} from './api';

/**
 * @desc: create scheduled maintenance
 */
export const createScheduledMaintenanceAsync = createAsyncThunk(
  'scheduleMaintenance/create',
  async (data: ScheduleMaintenanceForm, {rejectWithValue}) => {
    try {
      return await scheduleMaintenance(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * @desc: to get scheduled maintenance list
 */
export const getScheduledMaintenanceAsync = createAsyncThunk(
  'scheduleMaintenance/list',
  async (_, {rejectWithValue}) => {
    try {
      const {data} = await getUpcomingScheduleMaintenance();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
