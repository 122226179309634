import {useTranslation} from 'react-i18next';

/**
 * The `useTranslate` function is a custom hook that provides translation functions for parsing UI
 * elements, form errors, validation errors, and response messages.
 * @returns The `useTranslate` function returns an object with four functions: `parseError`,
 * `parseValidation`, `parseResponse`, and `parseUI`.
 */
const useTranslate = () => {
  const {t} = useTranslation();
  const parseUI = (i18nKey: string) => t(`ui:${i18nKey}`);
  const parseError = (i18nKey: string) => t(`error:${i18nKey}`);
  return {parseError, parseUI};
};

export default useTranslate;
