const sidebarLinks = [
  {
    id: 2,
    title: 'PEST_COMPANIES',
    path: '/dashboard/pest-company',
  },
  {
    id: 3,
    title: 'BAITCHECKER_INVENTORY',
    path: '/dashboard/bait-checker-list',
  },
  {
    id: 4,
    title: 'TAGS',
    path: '/dashboard/tags',
  },
  {
    id: 5,
    title: 'BAIT_CHECKER_SIMULATOR',
    path: '/dashboard/bait-checker-simulator',
  },
  {
    id: 6,
    title: 'SCHEDULE_MAINTENANCE',
    path: '/dashboard/schedule-maintenance',
  },
];

export default sidebarLinks;
