import {Box, Grid} from '@mui/material';
import Button from 'components/UI/Button';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import LoadingButton from 'components/UI/LoadingButton';
import DebounceInputField from 'components/UI/form/DebounceInputField';
import InputField from 'components/UI/form/InputField';
import {ROUTES} from 'constants/routes';
import {Form, Formik} from 'formik';
import useDialog from 'hooks/useDialog';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {CreateSimulateData, SimulateDataWithoutHash} from 'models/inventory.interface';
import protobuf from 'protobufjs';
import {useState} from 'react';
import {useNavigate} from 'react-router';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {simulateBaitBoxLogsAsyncV2} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import {BaitCheckerSimulatorSchema} from 'utils/validation-schema';

const baitCheckerSimulatorFields = [
  {name: 'iccNumber', label: 'ICC_NUMBER_LABEL', type: 'text'},
  {name: 'batteryLevel', label: 'BATTERY_LEVEL', type: 'text'},
  {name: 'weight', label: 'WEIGHT', type: 'text'},
  {name: 'networkStrength', label: 'NETWORK_STRENGTH', type: 'text'},
];

const SimulateBaitChecker = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {parseUI} = useTranslate();

  const {isLoading, simulatorBaitBoxState} = useAppSelector(getBaitCheckerState);

  const {isOpen, dialogData, showDialog, hideDialog} = useDialog(false);

  const [formValues, setFormValues] = useState<SimulateDataWithoutHash>();

  const initialSimulatorValues: CreateSimulateData = {
    iccNumber: '',
    batteryLevel: '',
    weight: '',
    networkStrength: '',
    isValidHash: false,
  };

  const onCancelClick = () => {
    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PEST_COMPANY}`);
  };

  const createLog = async data => {
    try {
      protobuf.load('/baitcheckerV2.proto', async function (err, root) {
        if (err) {
          throw err;
        }

        /** Get the message type */
        const BaitCheckerRequest = root.lookupType('protobuf.BackendApiBaitCheckerRequestV2');

        /** Create a message */
        const message = BaitCheckerRequest.create({
          deviceId: {simIcc: data.iccNumber},
          results: [
            {
              weightUnits: data.weight,
              batteryLevel: data.batteryLevel,
              signalStrength: {
                nbIot: {
                  rssi: data.networkStrength,
                },
              },
            },
          ],
        });

        /** Encode the message to a buffer */
        const buffer = BaitCheckerRequest.encode(message).finish();

        /** to create the binary blob */
        const blob = new Blob([buffer], {
          type: 'application/octet-stream',
        });

        try {
          const response = await dispatch(simulateBaitBoxLogsAsyncV2({blob})).unwrap();
          const reply = root.lookupType('protobuf.BackendApiBaitCheckerReplyV2');

          /** to check the API response */
          const bufferResponse = new Uint8Array(response.data as unknown as ArrayBufferLike);

          /** to decode the API response */
          const messageResponse = reply.decode(bufferResponse);

          if (messageResponse) {
            showSuccess(`${parseUI('LOG_CREATED_SUCCESSFULLY')}`);
            hideDialog();
          }
        } catch (error) {
          const errorMsg = formatError((error as HttpError).error);
          showError(errorMsg);
        }
      });
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
  };

  const handleSubmit = async (formData: CreateSimulateData) => {
    const data = {
      iccNumber: formData.iccNumber,
      batteryLevel: formData.batteryLevel,
      weight: formData.weight,
      networkStrength: formData.networkStrength,
    };

    setFormValues(data);
    if (simulatorBaitBoxState.isActive) {
      showDialog(`${parseUI('WANT_TO_CREATE_LOG_FOR_ACTIVE_BAITBOX')}`);
    } else {
      createLog(data);
    }
  };

  const hideDialogPopup = () => {
    hideDialog();
  };

  const onSuccess = () => {
    createLog(formValues);
  };

  const renderInActiveBaitBoxConfirmDialog = () => {
    return (
      <ConfirmDialog
        isLoading={isLoading}
        initialValue={isOpen}
        onClose={hideDialogPopup}
        successMessage={parseUI('PROCEED')}
        cancelMessage={parseUI('CANCEL')}
        onSuccess={onSuccess}
        heading={parseUI('ARE_YOU_SURE')}
        body={dialogData}
      />
    );
  };

  const renderSimulatorForm = () => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="plr36">
        <h1 className="page-title">{parseUI('SIMULATE_BAITCHECKER')} </h1>
        <Formik
          initialValues={initialSimulatorValues}
          validationSchema={BaitCheckerSimulatorSchema}
          onSubmit={handleSubmit}
          validateOnBlur={true}
          validateOnChange={true}>
          {({isValid, dirty, setFieldValue, resetForm}) => (
            <Form>
              <Grid container spacing={2}>
                {baitCheckerSimulatorFields?.map(field => (
                  <Grid item xs={12} sm={6} className="pt-0" key={field.name}>
                    {field.name === 'iccNumber' ? (
                      <DebounceInputField
                        name={field.name}
                        type={field.type}
                        label={field.label}
                        setFieldValue={setFieldValue}
                      />
                    ) : (
                      <InputField name={field.name} type={field.type} label={field.label} />
                    )}
                  </Grid>
                ))}
              </Grid>
              <Box mt={3} className="simulate-buttons">
                <div>
                  <Button type="button" onClick={onCancelClick} label="Back" />
                </div>
                <div className="simulate-buttons-submit">
                  <LoadingButton
                    type="submit"
                    label="SUBMIT"
                    loader={isLoading}
                    className="loading-button"
                    disabled={!isValid || !dirty || isLoading}
                  />

                  <Button type="button" onClick={() => resetForm()} label="RESET" />
                </div>
              </Box>
            </Form>
          )}
        </Formik>

        {renderInActiveBaitBoxConfirmDialog()}
      </Box>
    );
  };
  return <> {renderSimulatorForm()}</>;
};

export default SimulateBaitChecker;
