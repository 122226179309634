import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {HttpError} from 'models/http-interface';
import {BaitCheckerInventory, DateRange} from 'models/inventory.interface';
import {formatError} from 'utils/http-error-formatter';
import {showError} from 'utils/toast-alerts';
import initialInventoryState from './initial-state';
import {
  addBaitBoxCommentAsync,
  AssignTagsToBaitBox,
  createBaitBoxInventoryAsync,
  deactivateSelectedBaitBoxes,
  downloadActivityLogsCSVAsync,
  getAllAndAssignedTags,
  getBaiBoxActivityLogsAsync,
  getBaitBoxCommentsAsync,
  getBaitBoxStatusAsync,
  getBaitCheckerAsync,
  removeBaitBoxCommentAsync,
  simulateBaitBoxLogsAsync,
  simulateBaitBoxLogsAsyncV2,
  updateBaitboxDeviceSettingsAsync,
  updateBaitBoxMetaAsync,
  updateMultiPlierOfSelectedBaitBoxes,
  updateTareOffSetOfSelectedBaitBoxes,
} from './thunk';

export const pestCompanySlice = createSlice({
  name: 'inventory',
  initialState: initialInventoryState,
  reducers: {
    updateBaitCheckerSearchText: (state, action: PayloadAction<{text: string | null}>) => {
      state.searchText = action.payload.text;
    },
    updateBaitCheckerSortingKeyOrder: (
      state,
      action: PayloadAction<{
        sortingKeyOrder: {
          sortingKey: string | null;
          sortingOrder: number | null;
        };
      }>,
    ) => {
      state.sortingKey = action.payload.sortingKeyOrder.sortingKey;
      state.sortingOrder = action.payload.sortingKeyOrder.sortingOrder;
    },
    selectDisSelectRow(state, action: PayloadAction<BaitCheckerInventory>) {
      const index = state.selectedBaitCheckersForAction.findIndex(item => item.baitBoxId === action.payload.uuid);
      if (index === -1) {
        // If not found, add action.payload to the array
        state.selectedBaitCheckersForAction.push({
          baitBoxId: action.payload.uuid,
          pestCompanyId: action.payload.pestCompanyId,
        });
      } else {
        // If found, remove action.payload from the array
        state.selectedBaitCheckersForAction.splice(index, 1);
      }

      // to change isAllSelected value
      if (
        state.inventoryList.filter(({isActive}) => isActive === 1).length === state.selectedBaitCheckersForAction.length
      ) {
        state.isAllActiveSelected = true;
      } else {
        state.isAllActiveSelected = false;
      }
    },
    selectDisSelectMultipleRows(state, action: PayloadAction<{selectAll: boolean}>) {
      if (action.payload.selectAll) {
        //set selectedBaitCheckersForAction = active baitboxes
        const activeUUIDs = () => {
          return state.inventoryList.reduce((acc, item) => {
            if (item.isActive === 1) {
              acc.push({
                baitBoxId: item.uuid,
                pestCompanyId: item.pestCompanyId,
              });
            }
            return acc;
          }, []);
        };
        state.selectedBaitCheckersForAction = activeUUIDs();
        state.isAllActiveSelected = true;
      } else {
        state.selectedBaitCheckersForAction = [];
        state.isAllActiveSelected = false;
      }
    },
    clearSelectedBaitCheckers(state) {
      state.selectedBaitCheckersForAction = [];
      state.isAllActiveSelected = false;
    },
    updateInventoryDetails(state, action: PayloadAction<BaitCheckerInventory | null>) {
      state.inventoryDetails = action.payload;
    },
    updateTagsList(state) {
      state.baitCheckerTags = [];
    },
    clearBaitCheckerComments(state) {
      state.baitCheckerComments = [];
      state.commentsCount = 0;
    },
    updateLogsSearchText: (state, action: PayloadAction<{text: string | null}>) => {
      state.logsSearchText = action.payload.text;
    },
    updateActivityLogSortingKeyOrder: (
      state,
      action: PayloadAction<{
        sortingKeyOrder: {
          sortingKey: string | null;
          sortingOrder: number | null;
        };
      }>,
    ) => {
      state.logsSortingKey = action.payload.sortingKeyOrder.sortingKey;
      state.logsSortingOrder = action.payload.sortingKeyOrder.sortingOrder;
    },
    clearLogsPestCompaniesFilterValues: state => {
      state.logsPestCompaniesFilterValues = [];
    },
    clearLogsClientCompaniesFilterValues: state => {
      state.logsClientCompaniesFilterValues = [];
    },
    clearLogsEventsFilterValues: state => {
      state.logsEventsFilterValues = [];
    },
    clearActivityLogsList: state => {
      state.activityLogs = [];
      state.baitBoxActivityLogsCount = 0;
    },
    selectDisSelectPestCompaniesFilter: (state, action: PayloadAction<{pestCompanyId: string}>) => {
      const index = state.logsPestCompaniesFilterValues.findIndex(id => id === action.payload.pestCompanyId);

      if (index === -1) {
        // If not found, add action.payload to the array
        state.logsPestCompaniesFilterValues.push(action.payload.pestCompanyId);
      } else {
        // If found, remove action.payload from the array
        state.logsPestCompaniesFilterValues.splice(index, 1);
      }
    },

    selectDisSelectClientCompaniesFilter: (state, action: PayloadAction<{clientCompanyId: string}>) => {
      const index = state.logsClientCompaniesFilterValues.findIndex(id => id === action.payload.clientCompanyId);

      if (index === -1) {
        // If not found, add action.payload to the array
        state.logsClientCompaniesFilterValues.push(action.payload.clientCompanyId);
      } else {
        // If found, remove action.payload from the array
        state.logsClientCompaniesFilterValues.splice(index, 1);
      }
    },

    selectDisSelectEventFilter: (state, action: PayloadAction<{eventId: string}>) => {
      const index = state.logsEventsFilterValues.findIndex(id => id === action.payload.eventId);

      if (index === -1) {
        // If not found, add action.payload to the array
        state.logsEventsFilterValues.push(action.payload.eventId);
      } else {
        // If found, remove action.payload from the array
        state.logsEventsFilterValues.splice(index, 1);
      }
    },

    clearLogsFilters: state => {
      state.logsPestCompaniesFilterValues = [];
      state.logsClientCompaniesFilterValues = [];
      state.logsEventsFilterValues = [];
    },

    updateDateRangeFilter: (state, action: PayloadAction<DateRange>) => {
      state.activityLogsDateRangeFilter = action.payload;
    },

    clearDateRangeFilter: state => {
      state.activityLogsDateRangeFilter = null;
    },

    updateBaitboxTagSearchText: (state, action: PayloadAction<{text: string | null}>) => {
      state.baitBoxTagsSearchText = action.payload.text;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createBaitBoxInventoryAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(createBaitBoxInventoryAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(createBaitBoxInventoryAsync.rejected, state => {
        state.isLoading = false;
      })

      .addCase(getBaitCheckerAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(getBaitCheckerAsync.fulfilled, (state, {payload}) => {
        state.inventoryList = payload.data.list;
        state.count = payload.data.count;
        state.isLoading = false;
      })
      .addCase(getBaitCheckerAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.isLoading = false;
      })

      .addCase(updateBaitBoxMetaAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateBaitBoxMetaAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(updateBaitBoxMetaAsync.rejected, state => {
        state.isLoading = false;
      })

      .addCase(deactivateSelectedBaitBoxes.pending, state => {
        state.multiSelectOptionActionLoading = true;
      })
      .addCase(deactivateSelectedBaitBoxes.fulfilled, state => {
        state.multiSelectOptionActionLoading = false;
      })
      .addCase(deactivateSelectedBaitBoxes.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.multiSelectOptionActionLoading = false;
      })

      .addCase(updateTareOffSetOfSelectedBaitBoxes.pending, state => {
        state.multiSelectOptionActionLoading = true;
      })
      .addCase(updateTareOffSetOfSelectedBaitBoxes.fulfilled, state => {
        state.multiSelectOptionActionLoading = false;
      })
      .addCase(updateTareOffSetOfSelectedBaitBoxes.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.multiSelectOptionActionLoading = false;
      })

      .addCase(updateMultiPlierOfSelectedBaitBoxes.pending, state => {
        state.multiSelectOptionActionLoading = true;
      })
      .addCase(updateMultiPlierOfSelectedBaitBoxes.fulfilled, state => {
        state.multiSelectOptionActionLoading = false;
      })
      .addCase(updateMultiPlierOfSelectedBaitBoxes.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.multiSelectOptionActionLoading = false;
      })

      .addCase(getAllAndAssignedTags.pending, state => {
        state.getAssignedTagsLoading = true;
      })
      .addCase(getAllAndAssignedTags.fulfilled, (state, {payload}) => {
        state.baitCheckerTags = payload.data;
        state.baitBoxTagCount = payload.count;
        state.getAssignedTagsLoading = false;
      })
      .addCase(getAllAndAssignedTags.rejected, state => {
        state.getAssignedTagsLoading = false;
      })

      .addCase(AssignTagsToBaitBox.pending, state => {
        state.isLoading = true;
      })
      .addCase(AssignTagsToBaitBox.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(AssignTagsToBaitBox.rejected, state => {
        state.isLoading = false;
      })

      .addCase(getBaitBoxCommentsAsync.pending, state => {
        state.baitCheckerCommentsLoading = true;
      })
      .addCase(getBaitBoxCommentsAsync.fulfilled, (state, {payload}) => {
        state.commentsCount = payload.data.count;
        state.baitCheckerComments = payload.data.list;
        state.baitCheckerCommentsLoading = false;
      })
      .addCase(getBaitBoxCommentsAsync.rejected, state => {
        state.baitCheckerCommentsLoading = false;
      })

      .addCase(removeBaitBoxCommentAsync.pending, state => {
        state.baitCheckerCommentsLoading = true;
      })
      .addCase(removeBaitBoxCommentAsync.fulfilled, state => {
        state.baitCheckerCommentsLoading = false;
      })
      .addCase(removeBaitBoxCommentAsync.rejected, state => {
        state.baitCheckerCommentsLoading = false;
      })

      .addCase(addBaitBoxCommentAsync.pending, state => {
        state.baitCheckerCommentsLoading = true;
      })
      .addCase(addBaitBoxCommentAsync.fulfilled, state => {
        state.baitCheckerCommentsLoading = false;
      })
      .addCase(addBaitBoxCommentAsync.rejected, state => {
        state.baitCheckerCommentsLoading = false;
      })

      .addCase(simulateBaitBoxLogsAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(simulateBaitBoxLogsAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(simulateBaitBoxLogsAsync.rejected, state => {
        state.isLoading = false;
      })
      .addCase(simulateBaitBoxLogsAsyncV2.pending, state => {
        state.isLoading = true;
      })
      .addCase(simulateBaitBoxLogsAsyncV2.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(simulateBaitBoxLogsAsyncV2.rejected, state => {
        state.isLoading = false;
      })
      .addCase(downloadActivityLogsCSVAsync.pending, state => {
        state.csvDownloadLoading = true;
      })
      .addCase(downloadActivityLogsCSVAsync.fulfilled, state => {
        state.csvDownloadLoading = false;
      })
      .addCase(downloadActivityLogsCSVAsync.rejected, state => {
        state.csvDownloadLoading = false;
      })

      .addCase(getBaiBoxActivityLogsAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(getBaiBoxActivityLogsAsync.fulfilled, (state, {payload}) => {
        state.baitBoxActivityLogsCount = payload.data.count;
        state.activityLogs = payload.data.list;
        state.isLoading = false;
      })
      .addCase(getBaiBoxActivityLogsAsync.rejected, state => {
        state.isLoading = false;
      })

      .addCase(getBaitBoxStatusAsync.pending, state => {
        state.loadingSimulator = true;
      })
      .addCase(getBaitBoxStatusAsync.fulfilled, (state, {payload}) => {
        state.loadingSimulator = false;
        state.simulatorBaitBoxState = payload.data;
      })
      .addCase(getBaitBoxStatusAsync.rejected, state => {
        state.loadingSimulator = false;
      })
      .addCase(updateBaitboxDeviceSettingsAsync.pending, state => {
        state.isUpdateBaitboxDeviceSettings = true;
      })
      .addCase(updateBaitboxDeviceSettingsAsync.fulfilled, state => {
        state.isUpdateBaitboxDeviceSettings = false;
      })
      .addCase(updateBaitboxDeviceSettingsAsync.rejected, state => {
        state.isUpdateBaitboxDeviceSettings = false;
      });
  },
});

export const {
  updateBaitCheckerSearchText,
  updateBaitCheckerSortingKeyOrder,
  selectDisSelectRow,
  selectDisSelectMultipleRows,
  clearSelectedBaitCheckers,
  updateInventoryDetails,
  updateTagsList,
  clearBaitCheckerComments,
  updateLogsSearchText,
  updateActivityLogSortingKeyOrder,
  clearLogsPestCompaniesFilterValues,
  clearLogsClientCompaniesFilterValues,
  clearLogsEventsFilterValues,
  clearActivityLogsList,
  selectDisSelectPestCompaniesFilter,
  selectDisSelectClientCompaniesFilter,
  selectDisSelectEventFilter,
  clearLogsFilters,
  updateDateRangeFilter,
  clearDateRangeFilter,
  updateBaitboxTagSearchText,
} = pestCompanySlice.actions;

export default pestCompanySlice.reducer;
