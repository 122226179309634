import {Tag} from 'models/tags-interface';

export interface BaitBoxTagsInitialState {
  loading: boolean;
  tagsList: Tag[];
  count: number | null;
  TagDetail: Tag;
}

const baitBoxTagsInitialState: BaitBoxTagsInitialState = {
  loading: false,
  tagsList: [],
  count: null,
  TagDetail: null,
};

export default baitBoxTagsInitialState;
