import {lazy} from 'react';
import BaseLayout from 'layouts/BaseLayout';
import {ROUTES} from 'constants/routes';

const Login = lazy(() => import('pages/Login'));
const InternalServerError = lazy(() => import('components/shared/InternalServerError'));
const NotFound = lazy(() => import('components/shared/NotFound'));

const authRoutes = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Login />,
      },
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
    ],
  },
  {
    path: ROUTES.INTERNAL_SERVER_ERROR,
    element: <InternalServerError />,
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <NotFound />,
  },
];

export default authRoutes;
