export enum BaitBoxAction {
  DEACTIVATE = 1,
  RETURN_TO_ZEPTA = 2,
  RETIRE = 3,
}

export enum ClientBaitBoxDeactivationStatus {
  COMPLETE = 1,
  PENDING = 2,
  DECLINE = 3,
}

export enum BaitBoxStatus {
  ACTIVE = 1,
  DEACTIVE = 0,
}
