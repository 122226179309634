import {FC} from 'react';

import {selectPestCompanyData} from 'redux/pest-company/selector';
import DataNotFound from 'components/shared/DataNotFound';
import Loader from 'components/shared/loader/Loader';
import TablePagination from 'components/shared/table-pagination/TablePagination';
import {countPages} from 'utils/page-count';
import PestCompanyBaitBoxes from './PestCompanyBaitBoxes';
import {useAppSelector} from 'redux/redux';
import {UseTableCheckBoxListData} from 'models/pest-company-interface';

type BiatboxListProp = {
  pagination: {
    limit: number;
    page: number;
    onPageChange: (newPage: number) => void;
    onLimitChange: (newLimit: number) => void;
  };
  data: UseTableCheckBoxListData[];
  toggleCheckBox: (iccHash: string) => void;
  toggleCheckAll: () => void;
  textChange?: (text: string) => void;
  markCheckUnCheck: (check: boolean) => void;
  isCheckAll: boolean;
};

const BiatboxList: FC<BiatboxListProp> = ({
  data,
  toggleCheckBox,
  pagination,
  toggleCheckAll,
  textChange,
  markCheckUnCheck,
  isCheckAll,
}) => {
  const {baitBoxCount, isLoading} = useAppSelector(selectPestCompanyData);

  const {page, limit, onPageChange, onLimitChange} = pagination;

  return (
    <div className="body-wrapper">
      <Loader show={isLoading} />
      {data.length > 0 ? (
        <>
          <PestCompanyBaitBoxes
            data={data}
            toggleCheckBox={toggleCheckBox}
            toggleCheckAll={toggleCheckAll}
            textChange={textChange}
            markCheckUnCheck={markCheckUnCheck}
            isCheckAll={isCheckAll}
          />
          <TablePagination
            pageChange={onPageChange}
            page={page}
            count={countPages(baitBoxCount, limit)}
            limit={limit}
            limitChange={onLimitChange}
          />
        </>
      ) : (
        <DataNotFound />
      )}
    </div>
  );
};

export default BiatboxList;
