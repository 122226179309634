import {Dialog, DialogActions, DialogContent} from '@mui/material';
import Button from 'components/UI/Button';
import InputCheckBox from 'components/UI/form/InputCheckBox';
import useTranslate from 'hooks/useTranslate';
import {ActivityLogsFilterAction, BaiBoxActivityFilter, DateFilterStatus} from 'models/inventory.interface';
import {FC} from 'react';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {
  clearLogsFilters,
  selectDisSelectClientCompaniesFilter,
  selectDisSelectEventFilter,
  selectDisSelectPestCompaniesFilter,
} from 'redux/inventory/slice';
import {useAppDispatch, useAppSelector} from 'redux/redux';

interface LogsFilterPopupProps {
  open: boolean;
  onClose: () => void;
  onFiltersChange: (filterAction: ActivityLogsFilterAction) => void;
  data: BaiBoxActivityFilter | null;
}

const LogsFilterPopup: FC<LogsFilterPopupProps> = ({open, onClose, onFiltersChange, data}) => {
  const {parseUI} = useTranslate();
  const dispatch = useAppDispatch();

  const {logsPestCompaniesFilterValues, logsClientCompaniesFilterValues, logsEventsFilterValues} =
    useAppSelector(getBaitCheckerState);

  const {pestCompanies, clientCompanies, events} = data;

  const onApplyFilter = () => {
    onFiltersChange({
      status: DateFilterStatus.APPLY,
      pestCompaniesFilters: logsPestCompaniesFilterValues,
      clientCompaniesFilters: logsClientCompaniesFilterValues,
      eventFilters: logsEventsFilterValues,
    });
    onClose();
  };

  const clearFilter = () => {
    dispatch(clearLogsFilters());
    onClose();
    onFiltersChange({
      status: DateFilterStatus.REMOVE,
      pestCompaniesFilters: [],
      clientCompaniesFilters: [],
      eventFilters: [],
    });
  };

  const onCloseFilter = () => {
    onClose();
    onFiltersChange({
      status: 'close',
      pestCompaniesFilters: [],
      clientCompaniesFilters: [],
      eventFilters: [],
    });
  };

  const removeAllFilters = () => {
    clearFilter();
  };

  const isPestCompanyChecked = (pestCompanyId: string) => {
    return logsPestCompaniesFilterValues.some(id => {
      return id === pestCompanyId;
    });
  };

  const onPestCompanyCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      selectDisSelectPestCompaniesFilter({
        pestCompanyId: e.target.value,
      }),
    );
  };

  const isClientCompanyChecked = (clientCompanyId: string) => {
    return logsClientCompaniesFilterValues.some(id => {
      return id === clientCompanyId;
    });
  };

  const onClientCompanyCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      selectDisSelectClientCompaniesFilter({
        clientCompanyId: e.target.value,
      }),
    );
  };

  const isEventChecked = (eventId: string) => {
    return logsEventsFilterValues.some(id => {
      return id === eventId;
    });
  };

  const onEventCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      selectDisSelectEventFilter({
        eventId: e.target.value,
      }),
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent className="filter-modal">
        {
          // render Pest Companies
          pestCompanies && pestCompanies.length > 0 && (
            <div className="filter-modal-head">
              <h1>{parseUI('COMPANIES')}</h1>
              {pestCompanies.map(({pestCompanyId, pestCompanyDetails: {name}}) => (
                <div key={pestCompanyId} className="filter-item">
                  <label className="filter-label-item">
                    <InputCheckBox
                      checked={isPestCompanyChecked(pestCompanyId)}
                      onChange={e => onPestCompanyCheckBoxChange(e)}
                      value={pestCompanyId}
                    />
                    <div className="filter-item-name">{name}</div>
                  </label>
                </div>
              ))}
            </div>
          )
        }

        {
          // render Client Companies
          clientCompanies && clientCompanies.length > 0 && (
            <div className="filter-modal-head">
              <h1>{parseUI('CLIENTS')}</h1>
              {clientCompanies.map(({clientCompanyId, clientCompanyDetails: {name}}) => (
                <div key={clientCompanyId} className="filter-item">
                  <label className="filter-label-item">
                    <InputCheckBox
                      checked={isClientCompanyChecked(clientCompanyId)}
                      onChange={e => onClientCompanyCheckBoxChange(e)}
                      value={clientCompanyId}
                    />
                    <div className="filter-item-name">{name}</div>
                  </label>
                </div>
              ))}
            </div>
          )
        }

        {
          // render events
          events && events.length > 0 && (
            <div className="filter-modal-head">
              <h1>{parseUI('EVENTS')}</h1>
              {events.map(({eventId, eventDetails: {name}}) => (
                <div key={eventId} className="filter-item">
                  <label className="filter-label-item">
                    <InputCheckBox
                      checked={isEventChecked(eventId)}
                      onChange={e => onEventCheckBoxChange(e)}
                      value={eventId}
                    />
                    <div className="filter-item-name">{name}</div>
                  </label>
                </div>
              ))}
            </div>
          )
        }
      </DialogContent>
      <DialogActions className="filter-modal-btn">
        <Button type="button" label="APPLY_FILTERS" onClick={onApplyFilter} color="primary" />

        <Button type="button" label="REMOVE_FILTERS" onClick={removeAllFilters} color="primary" />
        <Button type="button" label="CLOSE" onClick={onCloseFilter} color="primary" />
      </DialogActions>
    </Dialog>
  );
};

export default LogsFilterPopup;
