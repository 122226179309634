import {BaitBox, PestCompanyData} from 'models/pest-company-interface';

export interface InitialPestCompanyState {
  isLoading: boolean;
  currentPage: number;
  pestCompanies: PestCompanyData[];
  pestCompanyDetail: PestCompanyData | null;
  pestCompanyBaitBoxes: BaitBox[] | [];
  baitBoxCount: number | null;
  count: number | null;
  isUpdate: boolean;
  searchText: string | null;
  sortingKey: string | null;
  sortingOrder: number | null;
  baitboxSearchText: string | null;
  baitboxSortingKey: string | null;
  baitboxSortingOrder: number | null;
  multiSelectOptionLoading: boolean;
  selectedPestCompaniesForAction: string[];
  isAllActiveSelected: boolean;
  loadingActionOnSelected: boolean;
  loadingClientDetailsWithCount: boolean;
}

const initialPestCompanyState: InitialPestCompanyState = {
  isLoading: false,
  currentPage: 1,
  pestCompanies: [],
  pestCompanyDetail: null,
  pestCompanyBaitBoxes: [],
  baitBoxCount: null,
  count: null,
  isUpdate: false,
  searchText: null,
  sortingKey: null,
  sortingOrder: null,
  baitboxSearchText: null,
  baitboxSortingKey: null,
  baitboxSortingOrder: null,
  multiSelectOptionLoading: false,
  selectedPestCompaniesForAction: [],
  isAllActiveSelected: false,
  loadingActionOnSelected: false,
  loadingClientDetailsWithCount: false,
};

export default initialPestCompanyState;
