import {RootState} from '../store';

export const getCheckBoxData = (state: RootState) => Object.values(state.checkBox.items);

export const getCheckBoxStatus = (state: RootState) => ({
  isCheckAll: state.checkBox.isCheckAll,
  isAllDisabled: state.checkBox.isAllDisabled,
});

export const getSelectedCheckBox = (state: RootState) => Object.values(state.checkBox.selectedData);

export const getSelectedCheckBoxIds = (state: RootState) =>
  Object.values(state.checkBox.selectedData).map(item => item.uuid);

export const getSelectedCheckBoxPestIds = (state: RootState) =>
  Object.values(state.checkBox.selectedData).map(item => ({
    baitBoxId: item.uuid,
    pestCompanyId: item.pestCompanyId,
  }));
