import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

import LoadingButton from './LoadingButton';

type dialogType = {
  isLoading: boolean;
  initialValue: boolean;
  onClose: () => void;
  successMessage: string;
  cancelMessage: string;
  onSuccess: () => void;
  heading: string;
  body: unknown;
};

const ConfirmDialog: React.FC<dialogType> = ({
  isLoading,
  initialValue,
  onClose,
  successMessage,
  cancelMessage,
  onSuccess,
  heading,
  body,
}) => {
  const onConfirm = () => {
    onSuccess();
  };

  return (
    <Dialog
      open={initialValue}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className="modal-heading">
        {heading}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loader={isLoading}
          disabled={isLoading === true}
          type="submit"
          label={successMessage}
          onClick={onConfirm}
          className="loading-button"
          color="green"
        />
        <LoadingButton
          type="button"
          label={cancelMessage}
          onClick={onClose}
          disabled={isLoading === true}
          className="loading-button"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
