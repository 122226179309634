import {createAsyncThunk} from '@reduxjs/toolkit';
import {BaitBoxTagData, DeactivateBaitBoxTagBody, TagListParams, UpdateBaitBoxTagData} from 'models/tags-interface';
import {createBaitBoxTag, deactivateBaitBoxTag, getBaitBoxTagList, updateBaitBoxTag} from './api';

export const createBaitBoxTagAsync = createAsyncThunk(
  'tags/create',
  async (baitBoxTagData: BaitBoxTagData, {rejectWithValue}) => {
    try {
      const response = await createBaitBoxTag(baitBoxTagData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBaitBoxTagsListAsync = createAsyncThunk(
  'tags/list',
  async (params: TagListParams, {rejectWithValue}) => {
    try {
      const response = await getBaitBoxTagList(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateBaitBoxTagAsync = createAsyncThunk(
  'tags/edit',
  async (body: UpdateBaitBoxTagData, {rejectWithValue}) => {
    try {
      const response = await updateBaitBoxTag(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deactivateBaitBoxTagAsync = createAsyncThunk(
  'tags/deactivate',
  async (body: DeactivateBaitBoxTagBody, {rejectWithValue}) => {
    try {
      const response = await deactivateBaitBoxTag(body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
