import ListHeader from 'components/UI/list/ListHeader';
import BaitcheckerList from 'components/baitchecker-inventory/list/BaitcheckerList';
import {ROUTES} from 'constants/routes';
import {usePagination} from 'hooks/usePagination';
import {useCallback, useEffect, useState} from 'react';
import {syncCode} from 'redux/checkBox/slice';
import {getBaitCheckerList, getBaitCheckerState} from 'redux/inventory/selector';
import {
  clearSelectedBaitCheckers,
  updateBaitCheckerSearchText,
  updateBaitCheckerSortingKeyOrder,
} from 'redux/inventory/slice';
import {getBaitCheckerAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import Icon from '@mdi/react';
import {mdiAlert} from '@mdi/js';

const BaitCheckerInventory = () => {
  const {page, limit, onPageChange, onLimitChange} = usePagination(1, 50);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState<string | null>(null);
  const {sortingKey, sortingOrder} = useAppSelector(getBaitCheckerState);
  const inventoryList = useAppSelector(getBaitCheckerList);

  const onTextChange = useCallback(
    (text: string) => {
      setSearchText(text);
      if (text && text.length < 3 && text.length > 0) {
        return;
      }
      dispatch(updateBaitCheckerSearchText({text}));

      if (page === 1) {
        dispatch(
          getBaitCheckerAsync({
            pageNumber: 1,
            limit,
            searchField: text,
            sortingField: sortingKey,
            sortingOrder,
          }),
        );
      } else {
        onLimitChange(10);
      }
    },
    [searchText, inventoryList],
  );

  const getBaitChecker = () => {
    dispatch(
      getBaitCheckerAsync({
        pageNumber: page,
        limit,
        searchField: searchText,
        sortingField: sortingKey,
        sortingOrder,
      }),
    );
  };

  useEffect(() => {
    if (page && limit) {
      getBaitChecker();
    }
  }, [page, limit, sortingKey, sortingOrder]);

  useEffect(() => {
    //cleanup the state when component unmount
    return () => {
      dispatch(updateBaitCheckerSearchText({text: null}));
      dispatch(
        updateBaitCheckerSortingKeyOrder({
          sortingKeyOrder: {sortingKey: null, sortingOrder: null},
        }),
      );
      dispatch(clearSelectedBaitCheckers());
    };
  }, []);

  useEffect(() => {
    dispatch(syncCode(inventoryList));
  }, [inventoryList]);

  return (
    <>
      <ListHeader
        label="ADD_BAIT_CHECKER"
        url={ROUTES.ADD_BAIT_CHECKER}
        title="BAITCHECKER"
        textChange={onTextChange}
      />
      <div className={'container alert alert-warning my-4'}>
        <Icon path={mdiAlert} size={1} className={'pe-1'} />
        This section is subject to change drastically. The following ideas exist:
        <ul className={'mt-3'}>
          <li>Show name of [z]Base</li>
          <li>"Click to copy" function</li>
        </ul>
      </div>
      <div className="body-wrapper">
        <BaitcheckerList pagination={{page, limit, onPageChange, onLimitChange}} />
      </div>
    </>
  );
};

export default BaitCheckerInventory;
