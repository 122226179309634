import {Edit as EditIcon, Save as SaveIcon} from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {IconButton} from '@mui/material';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import InputTableCell from 'components/UI/form/InputTableCell';
import InfoTooltip from 'components/UI/InfoTooltip';
import {Form, Formik} from 'formik';
import useDialog from 'hooks/useDialog';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {Tag} from 'models/tags-interface';
import {FC, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {getBaitBoxTagsState} from 'redux/tags/selector';
import {deactivateBaitBoxTagAsync, getBaitBoxTagsListAsync, updateBaitBoxTagAsync} from 'redux/tags/thunk';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import {EditBaitBoxTagSchema} from 'utils/validation-schema';

type TagRowProp = {
  tag: Tag;
};

const TagRow: FC<TagRowProp> = ({tag}) => {
  const dispatch = useAppDispatch();
  const {parseUI} = useTranslate();
  const [parameter] = useSearchParams();
  const {isOpen, showDialog, dialogData, hideDialog} = useDialog(false);

  const {loading} = useAppSelector(getBaitBoxTagsState);

  const [showForm, setShowForm] = useState<boolean>(false);

  const queryPage = parameter.get('page') ?? '1';
  const queryLimit = parameter.get('limit') ?? '10';

  const {name, uuid} = tag;

  const onConfirmDialogSuccess = async () => {
    try {
      const response = await dispatch(
        deactivateBaitBoxTagAsync({
          uuid: dialogData.uuid,
        }),
      ).unwrap();
      dispatch(
        getBaitBoxTagsListAsync({
          pageNumber: parseInt(queryPage),
          limit: parseInt(queryLimit),
        }),
      );
      showSuccess(response.message);
    } catch (error) {
      showError(error.message);
    }

    hideDialog();
  };

  const onSubmit = async (formData: any) => {
    const data = {
      id: formData.uuid,
      body: {name: formData.name},
    };
    try {
      const response = await dispatch(updateBaitBoxTagAsync(data)).unwrap();
      dispatch(
        getBaitBoxTagsListAsync({
          pageNumber: parseInt(queryPage),
          limit: parseInt(queryLimit),
        }),
      );
      showSuccess(response.message);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
    setShowForm(false);
  };

  const renderRow = () => {
    return (
      <Formik
        key={uuid}
        initialValues={tag}
        enableReinitialize={true}
        validationSchema={EditBaitBoxTagSchema}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        className="editable-table-row">
        {({isValid}) => {
          return (
            <Form className="editable-table-row">
              <div className="editable-table-cell">
                <InputTableCell name="name" type="text" readOnly={!showForm} />
              </div>
              <div className="editable-table-cell">
                {showForm ? (
                  <IconButton disabled={!isValid} aria-label="Save" type="submit">
                    <SaveIcon />
                  </IconButton>
                ) : (
                  <InfoTooltip name={parseUI('EDIT')}>
                    <IconButton onClick={() => setShowForm(prev => !prev)} aria-label="Edit">
                      <EditIcon />
                    </IconButton>
                  </InfoTooltip>
                )}
                <InfoTooltip name={parseUI('DELETE')}>
                  <IconButton
                    onClick={() =>
                      showDialog<{
                        tagName: string;
                        uuid: string;
                      }>({
                        tagName: name,
                        uuid,
                      })
                    }
                    aria-label="Edit">
                    <DeleteOutlineIcon />
                  </IconButton>
                </InfoTooltip>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderActiveDeActiveDialog = () => {
    const deleteText = parseUI('WANT_TO_DELETE');
    const tagName = dialogData?.tagName;
    const baitBoxText = parseUI('BAIT_BOX_TAG');
    return (
      <ConfirmDialog
        isLoading={loading}
        initialValue={isOpen}
        onClose={hideDialog}
        successMessage={parseUI('YES')}
        cancelMessage={parseUI('CANCEL')}
        onSuccess={onConfirmDialogSuccess}
        heading={parseUI('ARE_YOU_SURE')}
        body={
          <>
            {deleteText} <strong>{tagName}</strong> {baitBoxText} ?
          </>
        }
      />
    );
  };

  return (
    <>
      {renderRow()}
      {renderActiveDeActiveDialog()}
    </>
  );
};

export default TagRow;
