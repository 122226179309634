/* eslint-disable no-useless-escape */
/* The code is defining a regular expression pattern called `RESET_PASSWORD_REGEX`. This pattern is
used to validate a password reset input. */
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const REMOVE_WHITESPACE = /\s/g;

export const PHONE_NUMBER = /^(?:(?:\+|00)49[\s-]?)?(?:(?:0[\s-]?)?[1-9]\d{1,4}[\s-]?)?(?:\d{2,5}[\s-]?\d{2,5})$/;

export const DECIMALS_NUMBER_REGEX = /^[+-]?(\d*\.)?\d+$/;

export const NUMBER_REGEX = /^\d+$/;

export const TAG_NAME_REGEX = /^[\sA-Za-z0-9]+$/;

export const ONLY_NUMBER = /^[0-9\b]+$/;
