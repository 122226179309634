import {FC, memo, useEffect} from 'react';
import {Chip, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import {Tag} from 'models/pest-company-interface';

import Button from 'components/UI/Button';
import useTranslate from 'hooks/useTranslate';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {updateInventoryDetails} from 'redux/inventory/slice';
import {getBaitCheckerState} from 'redux/inventory/selector';

interface TagPopupProps {
  open: boolean;
  onClose: () => void;
  tags: Tag[];
}

const TagPopup: FC<TagPopupProps> = ({open, onClose, tags}) => {
  const {inventoryDetails} = useAppSelector(getBaitCheckerState);
  const {parseUI} = useTranslate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateInventoryDetails(null));
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{paddingBottom: '10px'}}>
        <strong>{parseUI('BAITCHECKER_UNIQUE_ID')} : </strong>
        {inventoryDetails?.slug}
        <div>
          <strong>{parseUI('BAIT_BOX_TAGS')}</strong>
        </div>
      </DialogTitle>
      <DialogContent style={{paddingBottom: '0'}}>
        {tags &&
          tags.length > 0 &&
          tags.map(({uuid, name}) => (
            <Chip key={uuid} label={name} variant="outlined" style={{marginRight: '8px', marginBottom: '8px'}} />
          ))}
      </DialogContent>
      <DialogActions>
        <Button type="button" label="CLOSE" onClick={onClose} color="primary" />
      </DialogActions>
    </Dialog>
  );
};

export default memo(TagPopup, (prev, next) => {
  return prev && JSON.stringify(prev.tags) === JSON.stringify(next.tags);
});
