import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import {Grid, Typography} from '@mui/material';
import useTranslate from 'hooks/useTranslate';
import {debounce} from 'lodash';
import {FC, memo, useCallback, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import BtckrSelectOptions from 'components/baitchecker-inventory/multiselect-options/BtckrSelectOptions';
import PageTitleWrapper from 'components/PageTitleWrapper';
import PestCompanyMultiSelectOptions from 'components/pest-company/multiselect-options/PestCompanyMultiSelectOptions';
import Button from 'components/UI/Button';
import {DEBOUNCE_TIME} from 'constants/debouce-time';
import {ROUTES} from 'constants/routes';
import {getCheckBoxData, getSelectedCheckBox} from 'redux/checkBox/selector';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {selectPestCompanyData} from 'redux/pest-company/selector';
import {useAppSelector} from 'redux/redux';
import SearchBar from '../searchbar/SearchBar';

const {BAITCHECKER_SEARCH_DELAY} = DEBOUNCE_TIME;

const PAGE_PATHS = {
  BAITCHECKER_LISTING_PAGE: 'bait-checker-list',
  PEST_COMPANY_LISTING_PAGE: 'pest-company',
  BAITCHECKER_COMMENTS: 'comments',
};

const {BAITCHECKER_LISTING_PAGE, PEST_COMPANY_LISTING_PAGE, BAITCHECKER_COMMENTS} = PAGE_PATHS;

type listHeaderType = {
  title: string;
  url: string;
  label: string;
  textChange?: (text: string) => void;
};

const ListHeader: FC<listHeaderType> = ({title, url, label, textChange}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {parseUI} = useTranslate();

  const {inventoryDetails, searchText} = useAppSelector(getBaitCheckerState);

  const {
    selectedPestCompaniesForAction,
    pestCompanies,
    searchText: pestCompaniesSearchText,
  } = useAppSelector(selectPestCompanyData);

  const selectedBaitBox = useAppSelector(getSelectedCheckBox);
  const checkBoxData = useAppSelector(getCheckBoxData);

  const handleClick = useCallback(() => {
    navigate(`/${ROUTES.DASHBOARD}/${url}`);
  }, []);

  const textSearchHandler = (text: string) => {
    textChange(text);
  };

  const handleSearch = debounce(textSearchHandler, BAITCHECKER_SEARCH_DELAY);

  const showSearchBar = () => {
    if (location.pathname.includes(BAITCHECKER_COMMENTS)) {
      return false;
    } else if (searchText || (location.pathname.includes(BAITCHECKER_LISTING_PAGE) && checkBoxData.length > 0)) {
      return true;
    } else if (
      pestCompaniesSearchText ||
      (location.pathname.includes(PEST_COMPANY_LISTING_PAGE) && pestCompanies.length > 0)
    ) {
      return true;
    }
    return false;
  };

  const MemoizedAddTwoToneIcon = useMemo(() => <AddTwoToneIcon fontSize="large" />, []);

  return (
    <PageTitleWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {parseUI(title)}
          </Typography>
          {location.pathname.includes(PAGE_PATHS.BAITCHECKER_COMMENTS) && (
            <Grid item>
              {parseUI('UNIQUE_ID')} : {inventoryDetails.slug}
            </Grid>
          )}
        </Grid>

        <Grid item className="header-row-wrap">
          {location.pathname.endsWith(PAGE_PATHS.BAITCHECKER_LISTING_PAGE) && selectedBaitBox.length > 0 && (
            <BtckrSelectOptions textChange={textChange} />
          )}
          {location.pathname.includes(PAGE_PATHS.PEST_COMPANY_LISTING_PAGE) &&
            selectedPestCompaniesForAction.length > 0 && <PestCompanyMultiSelectOptions />}
          {showSearchBar() && <SearchBar onInputChange={handleSearch} textChange={textChange} />}

          <Button label={label} variant="contained" startIcon={MemoizedAddTwoToneIcon} onClick={handleClick} />
        </Grid>
      </Grid>
    </PageTitleWrapper>
  );
};

export default memo(ListHeader);
