import {useField} from 'formik';
import React from 'react';

import {INPUT_TYPES} from 'constants/ui-input';
import useTranslate from 'hooks/useTranslate';

interface InputFieldProps {
  name: string;
  type?: typeof INPUT_TYPES[keyof typeof INPUT_TYPES];
  readOnly: boolean;
  className?: string;
  value?: string;
}

const InputTableCell: React.FC<InputFieldProps> = ({value, ...props}) => {
  const [field, meta] = useField(props);
  const {parseError} = useTranslate();

  const getClass = () => {
    const classes = ['py-2'];
    if (['iccNumber', 'slug', 'uuid'].includes(field.name)) {
      classes.push('font-monospace');
    }
    if (props.readOnly) {
      classes.push('border0');
    }
    if (meta.error) classes.push('border1 error');
    return classes.join(' ');
  };
  return (
    <div className={getClass() + field.name}>
      {value || field.value}
      {meta.error && meta.touched ? <div className="table-cell-inputReq">{parseError(meta.error)}</div> : null}
    </div>
  );
};

export default InputTableCell;
