import axiosInstance from 'api/api-service';
import {SCHEDULE_MAINTENANCE} from 'constants/api-endpoints';
import {HttpResponse} from 'models/http-interface';
import {ScheduleMaintenanceForm} from 'models/maintenance-interface';

/**
 * @desc: schedule maintenance
 * @param body
 * @returns
 */
export const scheduleMaintenance = (body: ScheduleMaintenanceForm) => {
  return axiosInstance.post<HttpResponse<null>>(SCHEDULE_MAINTENANCE.SCHEDULE_MAINTENANCE, body);
};

/**
 * @desc: schedule maintenance
 * @returns
 */
export const getUpcomingScheduleMaintenance = () => {
  return axiosInstance.get<HttpResponse<null>>(SCHEDULE_MAINTENANCE.SCHEDULE_MAINTENANCE);
};
