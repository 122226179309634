export const getStatusIconText = (status: number, isActive: number, isRetire: boolean) => {
  if (isActive === 0) {
    return isRetire
      ? {
          text: 'RETIRE',
          icon: '/static/images/svg/retired-baitbox.svg',
        }
      : {
          text: 'INACTIVE',
          icon: '/static/images/svg/inactive.svg',
        };
  } else {
    switch (status) {
      case 1:
        return {
          text: 'NORMAL',
          icon: '/static/images/svg/check-circle.svg',
        };
      case 2:
        return {
          text: 'INCIDENT',
          icon: '/static/images/svg/warning-status.svg',
        };
      case 3:
        return {
          text: 'SERVICE',
          icon: '/static/images/svg/bell.svg',
        };
      case 4:
        return {
          text: 'RESET',
          icon: '/static/images/svg/gray-watch.svg',
        };
    }
  }
};
