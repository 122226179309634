import axiosInstance from 'api/api-service';
import {BAIT_BOX_TAG} from 'constants/api-endpoints';
import {HttpResponse} from 'models/http-interface';
import {
  BaitBoxTagData,
  DeactivateBaitBoxTagBody,
  GetBaitBoxTagsResponse,
  TagListParams,
  UpdateBaitBoxTagData,
} from 'models/tags-interface';

const {TAG, REMOVE_TAG} = BAIT_BOX_TAG;

export const createBaitBoxTag = (body: BaitBoxTagData) => {
  return axiosInstance.post<HttpResponse<null>>(TAG, body);
};

export const updateBaitBoxTag = (updatedBaitBoxData: UpdateBaitBoxTagData) => {
  const {id, body} = updatedBaitBoxData;
  return axiosInstance.patch<HttpResponse<null>>(`${TAG}/${id}`, body);
};

export const deactivateBaitBoxTag = (body: DeactivateBaitBoxTagBody) => {
  const {uuid} = body;
  return axiosInstance.patch<HttpResponse<null>>(`${TAG}${REMOVE_TAG}/${uuid}`);
};

export const getBaitBoxTagList = (params: TagListParams) => {
  return axiosInstance.get<HttpResponse<GetBaitBoxTagsResponse>>(TAG, {
    params,
  });
};
