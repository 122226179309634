/**
 * @desc: convert keys to snake case
 * @param obj
 * @returns
 */
export const convertKeysToSnakeCase = (obj: {[x: string]: any}) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const snakeCaseObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let value = obj[key];
      if (typeof value === 'object' && value !== null) {
        value = convertKeysToSnakeCase(value);
      }
      const snakeCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
      snakeCaseObj[snakeCaseKey] = value;
    }
  }
  return snakeCaseObj;
};
