import {BaitBox, UseTableCheckBoxListData} from 'models/pest-company-interface';
import {useEffect, useState} from 'react';

const useTableCheckBoxList = () => {
  const [data, setData] = useState<UseTableCheckBoxListData[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  const syncCode = (pestCompanyBaitBoxes: BaitBox[], pestCompanyId: string) => {
    const baitBoxData: UseTableCheckBoxListData[] = [];

    for (const data of pestCompanyBaitBoxes) {
      baitBoxData.push({
        ...data,
        pestCompanyId,
        isChecked: false,
      });
    }

    setData(baitBoxData);
  };

  const toggleCheckBox = (iccHash: string) => {
    setData(prevData => {
      return prevData.map(item => (item.iccHash === iccHash ? {...item, isChecked: !item.isChecked} : item));
    });
  };

  const markCheckUnCheck = (check: boolean) => {
    setData(prevData => {
      return prevData.map(item => ({
        ...item,
        isChecked: check,
      }));
    });

    setCheckAll(check);
  };

  const toggleCheckAll = () => {
    setCheckAll(prev => !prev);
  };

  const getSelectedBaitBoxesByStatus = (isActive: number) => {
    if (isActive === 1) {
      return data
        .filter(item => item.isActive === isActive && item.isChecked === true)
        .map(({pestCompanyId, clientCompanyId, clientBaitBoxId}) => {
          return {
            pestCompanyId,
            clientCompanyId,
            clientBaitBoxId,
          };
        });
    } else {
      return data.filter(item => item.isActive === isActive && item.isChecked === true).map(({iccHash}) => iccHash);
    }
  };

  useEffect(() => {
    const allCheck = data.every(item => item.isChecked === true);
    setCheckAll(allCheck);
  }, [data]);

  return {
    syncCode,
    data,
    toggleCheckBox,
    getSelectedBaitBoxesByStatus,
    toggleCheckAll,
    markCheckUnCheck,
    checkAll,
  };
};

export default useTableCheckBoxList;
