import React from 'react';

/**
 * The useDialog function is a custom hook in TypeScript that manages the state of a dialog component,
 * including its visibility and data.
 * @param {boolean} initialState - The `initialState` parameter is a boolean value that determines
 * whether the dialog is initially open or closed. If `initialState` is `true`, the dialog will be open
 * by default. If `initialState` is `false`, the dialog will be closed by default.
 * @param {null | T} [data] - The `data` parameter is an optional parameter that can be of type `null`
 * or `T`. It is used to store any additional data that you want to pass to the dialog component.
 * @returns The useDialog function returns an object with the following properties:
 */
const useDialog = (initialState: boolean) => {
  const [isOpen, setOpen] = React.useState(initialState);
  const [dialogData, setData] = React.useState(null);

  const showDialog = <T>(obj: T) => {
    setOpen(true);
    setData(obj);
  };

  const hideDialog = () => {
    setOpen(false);
  };

  return {isOpen, showDialog, hideDialog, dialogData};
};

export default useDialog;
