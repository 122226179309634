import ActivityLogsTableBody from './ActivityLogsTableBody';
import TableHeader from './Header';

const List = () => {
  return (
    <section className="common-table editable">
      <div className="editable-table filter-container">
        <TableHeader />
        <ActivityLogsTableBody />
      </div>
    </section>
  );
};

export default List;
