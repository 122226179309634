import axios, {AxiosError, AxiosInstance} from 'axios';
import {AxiosExtendsRequest} from 'models/http-interface';
import {updateAccessTokenAsync} from 'redux/auth/thunk';
import {store} from 'redux/store';
import {HTTP_CODE} from 'utils/http-code';
import {getKey} from 'utils/local-storage';
import {removeUser} from 'utils/remove-user';
import {API_TIMEOUTS} from './../constants/axios-timeout';

const {DEFAULT} = API_TIMEOUTS;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: DEFAULT,
});

/* The `axiosInstance.interceptors.request.use()` function is adding a request interceptor to the Axios
instance. This interceptor is used to modify the outgoing request before it is sent.. */
axiosInstance.interceptors.request.use(
  req => {
    const token = store.getState().auth.accessToken;
    if (token) {
      req.headers['Authorization'] = `Bearer ${token}`;
    }
    req.params = {
      ...req.params,
      lang: getKey('i18nextLng'),
    };

    return req;
  },
  (error: AxiosError) => {
    // Handle request error (optional)
    return Promise.reject(error.message);
  },
);

/* The `axiosInstance.interceptors.response.use()` function is adding a response interceptor to the
Axios instance. This interceptor is used to modify the response before it is returned to the calling
code. */
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest: AxiosExtendsRequest = error?.config;
    if (error.response) {
      /* The code `if (error.response.status === 401) { removeUser(); }` is checking if the response
     status code is 401 (Unauthorized). If it is, it calls the `removeUser()` function, which is
     responsible for removing the user's authentication token or any other user-related data from
     the application. This is typically done to log the user out or revoke their access if their
     authentication token is no longer valid or expired. */
      if (error.response.status === 401) {
        removeUser();
      }

      if (error.response.status === 412 && originalRequest && !originalRequest?._retry) {
        originalRequest._retry = true;

        const refreshToken = store.getState().auth.refreshToken;
        const response = await store.dispatch(updateAccessTokenAsync({refreshToken})).unwrap();

        if (response) {
          return axiosInstance(originalRequest);
        }
        return Promise.reject(error.response.data);
      }

      return Promise.reject(error.response.data);
    } else {
      /* The code `if (error.code === HTTP_CODE.ECONNABORTED) { return Promise.reject({ error:
      'Internal Server Error' }); }` is checking if the error code of the Axios error is equal to
      `HTTP_CODE.ECONNABORTED`. */
      if (error.code === HTTP_CODE.ECONNABORTED) {
        return Promise.reject({error: 'Internal Server Error'});
      }
      return Promise.reject({error: error.message});
    }
  },
);

export default axiosInstance;
