import {Card} from '@mui/material';
import {FC} from 'react';

import useTranslate from 'hooks/useTranslate';

const DataNotFound: FC<{message?: string}> = ({message}) => {
  const {parseUI} = useTranslate();

  return (
    <Card className="body-wrapper-not-found">
      <h4 className="not-found">{message ? parseUI(message) : parseUI('DATA_NOT_FOUND')}</h4>
    </Card>
  );
};

export default DataNotFound;
