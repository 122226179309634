import {toast} from 'react-toastify';

/**
 * The function `showError` displays an error message using a toast notification.
 * @param {string} message - The `message` parameter is a string that represents the error message that
 * you want to display.
 */
export const showError = (message: string) => {
  toast.error(message);
};

/**
 * The function `showSuccess` displays a success message using a toast notification.
 * @param {string} message - The `message` parameter is a string that represents the success message
 * that you want to display.
 */
export const showSuccess = (message: string) => {
  toast.success(message);
};

export const showWarning = (message: string) => {
  toast.warning(message);
};
