import {createContext, FC, useMemo, useState} from 'react';

type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

export const SidebarProvider: FC = ({children}) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const contextValue = useMemo(() => {
    return {sidebarToggle, toggleSidebar, closeSidebar};
  }, [sidebarToggle, toggleSidebar, closeSidebar]);

  return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
};
