import {NewlyCreatedBaitBox} from 'models/inventory.interface';
import {Dispatch, FC, SetStateAction} from 'react';

type createdBoxType = {
  data: {
    newSim: NewlyCreatedBaitBox[];
    oldSim: NewlyCreatedBaitBox[];
  };
  setData: Dispatch<
    SetStateAction<{
      newSim: NewlyCreatedBaitBox[];
      oldSim: NewlyCreatedBaitBox[];
    }>
  >;
};

export const CreatedBox: FC<createdBoxType> = ({data, setData}) => {
  const {newSim, oldSim} = data;
  return (
    <div className="wrapper sim-unique-wrapper">
      <div className="sim-unique-id-outer">
        <div className="sim-unique-row">
          <div className="add-bit-simId">
            <strong>SIM ID</strong>
          </div>

          <div className="add-bit-uniqueId">
            <strong>Unique ID</strong>
          </div>
        </div>
      </div>
      {newSim.map(box => (
        <div className="sim-unique-id-outer" key={box.slug}>
          <div className="sim-unique-row">
            <div className="add-bit-simId">
              <span className="sim-unique-iccNum">{box.iccNumber}</span> <span className="badge">new</span>
            </div>

            <div className="add-bit-uniqueId">{box.slug}</div>
          </div>
        </div>
      ))}

      {oldSim.map(box => (
        <div className="sim-unique-id-outer" key={box.slug}>
          <div className="sim-unique-row">
            <div className="add-bit-simId">
              <span className="sim-unique-iccNum">{box.iccNumber}</span>
            </div>
            <div className="add-bit-uniqueId">{box.slug}</div>
          </div>
        </div>
      ))}

      <div>
        <button className="add-more-ids" onClick={() => setData({oldSim: [], newSim: []})}>
          Add More
        </button>
      </div>
    </div>
  );
};
