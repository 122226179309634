import {useEffect} from 'react';
import {useParams} from 'react-router';
import DataNotFound from 'components/shared/DataNotFound';
import Loader from 'components/shared/loader/Loader';
import TablePagination from 'components/shared/table-pagination/TablePagination';
import {usePagination} from 'hooks/usePagination';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {clearBaitCheckerComments} from 'redux/inventory/slice';
import {getBaitBoxCommentsAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {countPages} from 'utils/page-count';
import List from './table';

const CommentsListing = () => {
  const {page, limit, onPageChange, onLimitChange} = usePagination(1, 50);

  const {baitCheckerCommentsLoading, commentsCount} = useAppSelector(getBaitCheckerState);
  const dispatch = useAppDispatch();

  const {id} = useParams();
  const getCommentsList = () => {
    dispatch(
      getBaitBoxCommentsAsync({
        pageNumber: page,
        limit: limit,
        baitBoxId: id,
      }),
    );
  };

  useEffect(() => {
    if (page && limit) {
      getCommentsList();
    }
  }, [page, limit]);

  useEffect(() => {
    //cleanup the state when component unmount
    return () => {
      dispatch(clearBaitCheckerComments());
    };
  }, []);
  return (
    <>
      <Loader show={baitCheckerCommentsLoading} />
      {commentsCount > 0 ? (
        <>
          <List />
          <TablePagination
            pageChange={onPageChange}
            page={page}
            count={countPages(commentsCount, limit)}
            limit={limit}
            limitChange={onLimitChange}
          />
        </>
      ) : (
        <DataNotFound />
      )}
    </>
  );
};

export default CommentsListing;
