import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

const debug = process.env.REACT_APP_I18N_DEBUG === 'true';
const fallbackLng = process.env.REACT_APP_FALLBACK_LNG;
const escapeValue = process.env.REACT_APP_I18N_FALLBACK_LNG === 'true';

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    debug,
    fallbackLng,
    ns: ['error', 'ui'],
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue,
    },
    supportedLngs: ['en', 'de'],
  });

export default i18n;
