import Button from 'components/UI/Button';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import useDialog from 'hooks/useDialog';
import useTranslate from 'hooks/useTranslate';
import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {selectPestCompanyData} from 'redux/pest-company/selector';
import {clearSelectedPestCompanies} from 'redux/pest-company/slice';
import {deactivateSelectedPestCompaniesAsync, getPestCompanyListAsync} from 'redux/pest-company/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {showError, showSuccess} from 'utils/toast-alerts';

const PestCompanyMultiSelectOptions = () => {
  const [parameter] = useSearchParams();
  const {parseUI} = useTranslate();
  const {selectedPestCompaniesForAction, isLoading} = useAppSelector(selectPestCompanyData);

  const dispatch = useAppDispatch();

  const {isOpen, showDialog, hideDialog} = useDialog(false);
  const [confirmDialogBody, setConfirmDialogBody] = useState('');

  const handleDeactivateMultiple = async () => {
    setConfirmDialogBody(`${parseUI('WANT_TO_DEACTIVATE_SELECTED_PEST_COMPANIES')} ?`);
    showDialog<string>('deactivate');
  };

  const queryPage = parameter.get('page') ?? '1';
  const queryLimit = parameter.get('limit') ?? '10';

  const onSuccess = async () => {
    // deactive api calling is here.
    try {
      const response = await dispatch(deactivateSelectedPestCompaniesAsync(selectedPestCompaniesForAction)).unwrap();
      dispatch(clearSelectedPestCompanies());
      showSuccess(response.message);
      dispatch(
        getPestCompanyListAsync({
          pageNumber: parseInt(queryPage),
          limit: parseInt(queryLimit),
        }),
      );
    } catch (error) {
      showError(error);
    }
    hideDialog();
  };

  const hideDeactiveDialog = () => {
    hideDialog();
  };

  const renderConfirmDialog = () => {
    return (
      <ConfirmDialog
        isLoading={isLoading}
        initialValue={isOpen}
        onClose={hideDeactiveDialog}
        successMessage={parseUI('YES')}
        cancelMessage={parseUI('CANCEL')}
        onSuccess={onSuccess}
        heading={parseUI('ARE_YOU_SURE')}
        body={confirmDialogBody}
      />
    );
  };

  return (
    <>
      <Button
        type="button"
        onClick={handleDeactivateMultiple}
        label="DEACTIVATE"
        isDisabled={selectedPestCompaniesForAction.length < 1}
      />
      {renderConfirmDialog()}
    </>
  );
};

export default PestCompanyMultiSelectOptions;
