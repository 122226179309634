import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {formatError} from 'utils/http-error-formatter';
import {showError} from 'utils/toast-alerts';
import {HttpError} from 'models/http-interface';
import initialState from './initial-state';

import {PestCompanyData} from 'models/pest-company-interface';
import {
  createPestCompanyAsync,
  deactivateSelectedClientBaitBoxAsync,
  deactivateSelectedPestCompaniesAsync,
  getPestCompanyBaitBoxesAsync,
  getPestCompanyListAsync,
  getPestDetailsWithBaitBoxesAsync,
  markBaitBoxDeactiveAsync,
  markBaitBoxRetireAsync,
  markBaitBoxReturnToZeptaAsync,
  retireMultipleBaitBoxesAsync,
  returnToZeptaMultipleBaitBoxesAsync,
  updatePestCompanyAsync,
  updatePestCompanyStatusAsync,
} from './thunk';

export const pestCompanySlice = createSlice({
  name: 'pestCompany',
  initialState,
  reducers: {
    setPestDetails: (state, action: PayloadAction<PestCompanyData>) => {
      state.pestCompanyDetail = action.payload;
    },
    cleanPestCompanyBaitBoxes: state => {
      state.pestCompanyBaitBoxes = [];
    },
    updatePestCompanies: (state, action: PayloadAction<PestCompanyData[]>) => {
      state.pestCompanies = action.payload;
    },
    updatePestCompanySearchText: (state, action: PayloadAction<{text: string | null}>) => {
      state.searchText = action.payload.text;
    },
    updatePestCompaniesSortingKeyAndOrder: (
      state,
      action: PayloadAction<{
        sortingKeyOrder: {
          sortingKey: string | null;
          sortingOrder: number | null;
        };
      }>,
    ) => {
      state.sortingKey = action.payload.sortingKeyOrder.sortingKey;
      state.sortingOrder = action.payload.sortingKeyOrder.sortingOrder;
    },
    updatePestBaitBoxSearchText: (state, action: PayloadAction<{text: string | null}>) => {
      state.baitboxSearchText = action.payload.text;
    },
    updatePestBaitBoxSortingKeyAndOrder: (
      state,
      action: PayloadAction<{
        sortingKeyOrder: {
          sortingKey: string | null;
          sortingOrder: number | null;
        };
      }>,
    ) => {
      state.baitboxSortingKey = action.payload.sortingKeyOrder.sortingKey;
      state.baitboxSortingOrder = action.payload.sortingKeyOrder.sortingOrder;
    },
    selectDisSelectRow(state, action: PayloadAction<string>) {
      const index = state.selectedPestCompaniesForAction.indexOf(action.payload);
      if (index === -1) {
        state.selectedPestCompaniesForAction.push(action.payload);
      } else {
        state.selectedPestCompaniesForAction.splice(index, 1);
      }

      // to change isAllSelected value
      if (
        state.pestCompanies.filter(({isActive}) => isActive === 1).length ===
        state.selectedPestCompaniesForAction.length
      ) {
        state.isAllActiveSelected = true;
      } else {
        state.isAllActiveSelected = false;
      }
    },
    selectDisSelectMultipleRows(state, action: PayloadAction<{selectAll: boolean}>) {
      if (action.payload.selectAll) {
        const activeUUIDs = () => {
          return state.pestCompanies.reduce((acc, item) => {
            if (item.isActive === 1) {
              acc.push(item.uuid);
            }
            return acc;
          }, []);
        };
        state.selectedPestCompaniesForAction = activeUUIDs();
        state.isAllActiveSelected = true;
      } else {
        state.selectedPestCompaniesForAction = [];
        state.isAllActiveSelected = false;
      }
    },
    clearSelectedPestCompanies(state) {
      state.selectedPestCompaniesForAction = [];
      state.isAllActiveSelected = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createPestCompanyAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(createPestCompanyAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(createPestCompanyAsync.rejected, state => {
        state.isLoading = false;
      })
      .addCase(getPestCompanyListAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(getPestCompanyListAsync.fulfilled, (state, {payload}) => {
        state.pestCompanies = payload.data.list;
        state.count = payload.data.count;
        state.isLoading = false;
      })
      .addCase(getPestCompanyListAsync.rejected, (state, action) => {
        state.isLoading = false;
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
      })
      .addCase(updatePestCompanyAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(updatePestCompanyAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(updatePestCompanyAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.isLoading = false;
      })
      .addCase(getPestCompanyBaitBoxesAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(getPestCompanyBaitBoxesAsync.fulfilled, (state, {payload}) => {
        state.pestCompanyBaitBoxes = payload.data.list;
        state.baitBoxCount = payload.data.count;
        state.isLoading = false;
      })
      .addCase(getPestCompanyBaitBoxesAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.isLoading = false;
      })
      .addCase(updatePestCompanyStatusAsync.pending, state => {
        state.isUpdate = true;
      })
      .addCase(updatePestCompanyStatusAsync.fulfilled, state => {
        state.isUpdate = false;
      })
      .addCase(updatePestCompanyStatusAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.isUpdate = false;
      })
      .addCase(markBaitBoxDeactiveAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(markBaitBoxDeactiveAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(markBaitBoxDeactiveAsync.rejected, state => {
        state.isLoading = false;
      })
      .addCase(markBaitBoxReturnToZeptaAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(markBaitBoxReturnToZeptaAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(markBaitBoxReturnToZeptaAsync.rejected, state => {
        state.isLoading = false;
      })
      .addCase(markBaitBoxRetireAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(markBaitBoxRetireAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(markBaitBoxRetireAsync.rejected, state => {
        state.isLoading = false;
      })
      .addCase(deactivateSelectedPestCompaniesAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(deactivateSelectedPestCompaniesAsync.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(deactivateSelectedPestCompaniesAsync.rejected, state => {
        state.isLoading = false;
      })

      .addCase(deactivateSelectedClientBaitBoxAsync.pending, state => {
        state.loadingActionOnSelected = true;
      })
      .addCase(deactivateSelectedClientBaitBoxAsync.fulfilled, state => {
        state.loadingActionOnSelected = false;
      })
      .addCase(deactivateSelectedClientBaitBoxAsync.rejected, state => {
        state.loadingActionOnSelected = false;
      })

      .addCase(retireMultipleBaitBoxesAsync.pending, state => {
        state.loadingActionOnSelected = true;
      })
      .addCase(retireMultipleBaitBoxesAsync.fulfilled, state => {
        state.loadingActionOnSelected = false;
      })
      .addCase(retireMultipleBaitBoxesAsync.rejected, state => {
        state.loadingActionOnSelected = false;
      })

      .addCase(returnToZeptaMultipleBaitBoxesAsync.pending, state => {
        state.loadingActionOnSelected = true;
      })
      .addCase(returnToZeptaMultipleBaitBoxesAsync.fulfilled, state => {
        state.loadingActionOnSelected = false;
      })
      .addCase(returnToZeptaMultipleBaitBoxesAsync.rejected, state => {
        state.loadingActionOnSelected = false;
      })

      .addCase(getPestDetailsWithBaitBoxesAsync.pending, state => {
        state.loadingClientDetailsWithCount = true;
      })
      .addCase(getPestDetailsWithBaitBoxesAsync.fulfilled, (state, {payload}) => {
        state.loadingClientDetailsWithCount = false;
        state.pestCompanyDetail = payload.data;
      })
      .addCase(getPestDetailsWithBaitBoxesAsync.rejected, state => {
        state.loadingClientDetailsWithCount = false;
      });
  },
});

export const {
  setPestDetails,
  cleanPestCompanyBaitBoxes,
  updatePestCompanies,
  updatePestCompanySearchText,
  updatePestCompaniesSortingKeyAndOrder,
  updatePestBaitBoxSearchText,
  updatePestBaitBoxSortingKeyAndOrder,
  selectDisSelectRow,
  selectDisSelectMultipleRows,
  clearSelectedPestCompanies,
} = pestCompanySlice.actions;

export default pestCompanySlice.reducer;
