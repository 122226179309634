import {FC} from 'react';
import {BaitBoxActivityLog} from 'models/inventory.interface';
import {germanDateFormat} from 'utils/format-date';
import {EMPTY_STRING} from 'constants/text-strings';
import InfoTooltip from 'components/UI/InfoTooltip';

type ActivityRowProps = {
  data: BaitBoxActivityLog;
};

const ActivityLogRow: FC<ActivityRowProps> = ({data}) => {
  const {createdAt, eventName, message, clientCompanyName, pestCompanyName} = data;

  const formattedDate = createdAt ? germanDateFormat(createdAt) : EMPTY_STRING;
  const renderRows = () => {
    return (
      <div className="editable-table-row">
        <div className="editable-table-cell">{formattedDate}</div>
        <div className="editable-table-cell">{eventName}</div>
        <div className="editable-table-cell">
          {message.length > 44 ? (
            <InfoTooltip name={message}>
              <div
                dangerouslySetInnerHTML={{
                  __html: message.substring(0, 44) + '...',
                }}
              />
            </InfoTooltip>
          ) : (
            <div dangerouslySetInnerHTML={{__html: message}} />
          )}
        </div>

        <div className="editable-table-cell">{clientCompanyName}</div>
        <div className="editable-table-cell">{pestCompanyName}</div>
      </div>
    );
  };

  return <div className="editable-table-content">{renderRows()}</div>;
};

export default ActivityLogRow;
