import {RootState} from '../store';

export const selectPestCompanyData = (state: RootState) => state.pestCompany;
export const selectLoading = (state: RootState) => state.pestCompany.isLoading;
export const updateLoading = (state: RootState) => state.pestCompany.isUpdate;

export const selectPestCompanies = (state: RootState) => state.pestCompany.pestCompanies;
export const totalPestCompany = (state: RootState) => state.pestCompany.count;
export const pestCompanyDetails = (state: RootState) => state.pestCompany.pestCompanyDetail;
export const pestCompanyBaitBoxes = (state: RootState) => state.pestCompany.pestCompanyBaitBoxes;
export const pestCompanyBaitBoxCount = (state: RootState) => state.pestCompany.baitBoxCount;
