import React from 'react';

const VersionBadge: React.FC = () => {
  const githubBaseURL = 'https://github.com/ZeptaIO/zcontrol-backoffice/commit/';
  const version = process.env.REACT_APP_VERSION;
  return (
    <a onClick={() => window.open(githubBaseURL + version)} target="_blank">
      <small className="badge text-bg-primary text-white">{version}</small>
    </a>
  );
};

export default VersionBadge;
