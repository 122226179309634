/**
 * The function `setKey` sets a value in the browser's local storage using a specified key.
 * @param {string} key - The key parameter is a string that represents the name of the item you want to
 * store in the localStorage. It is used to identify the item when you want to retrieve it later.
 * @param {string} value - The value parameter is a string that represents the value to be stored in
 * the local storage.
 */
export const setKey = (key: string, value: string) => localStorage.setItem(key, value);

/**
 * The function `getKey` retrieves the value associated with a given key from the browser's local
 * storage.
 * @param {string} key - The `key` parameter is a string that represents the key of the item you want
 * to retrieve from the localStorage.
 */
export const getKey = (key: string) => localStorage.getItem(key);

/**
 * The function `removeKey` removes a key-value pair from the browser's local storage based on the
 * provided key.
 * @param {string} key - The key parameter is a string that represents the key of the item you want to
 * remove from the localStorage.
 */
export const removeKey = (key: string) => localStorage.removeItem(key);

/**
 * The clearStorage function clears the localStorage.
 */
export const clearStorage = () => localStorage.clear();
