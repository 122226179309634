import {useEffect, useState} from 'react';
import useTranslate from 'hooks/useTranslate';
import {updateActivityLogSortingKeyOrder} from 'redux/inventory/slice';
import {useAppDispatch} from 'redux/redux';
import Icon from '@mdi/react';
import {mdiChevronDown, mdiChevronUp} from '@mdi/js';

const columnDefinitions = [
  {label: 'DATE', key: 'createdAt', sortable: true},
  {label: 'EVENT_NAME', key: 'eventName', sortable: true},
  {label: 'DETAIL', key: 'message', sortable: false},
  {label: 'CLIENT_COMPANY_NAME', key: 'clientCompanyName', sortable: true},
  {label: 'PEST_COMPANY_NAME', key: 'pestCompanyName', sortable: true},
];

const initialSortState = {
  column: null,
  sortingOrder: null,
};

const TableHeader = () => {
  const dispatch = useAppDispatch();
  const {parseUI} = useTranslate();

  const [sortState, setSortState] = useState(initialSortState);

  const changeOrder = () => {
    if (sortState.sortingOrder === 1) {
      setSortState({
        column: null,
        sortingOrder: null,
      });
    }

    if (sortState.sortingOrder === 0) {
      setSortState(prevState => ({
        column: prevState.column,
        sortingOrder: 1,
      }));
    }

    if (sortState.sortingOrder === -1) {
      setSortState(prevState => ({
        column: prevState.column,
        sortingOrder: 0,
      }));
    }
  };

  const sort = (columnName: string) => {
    if (columnName === sortState.column) {
      changeOrder();
    } else {
      setSortState({
        column: columnName,
        sortingOrder: 0,
      });
    }
  };

  useEffect(() => {
    dispatch(
      updateActivityLogSortingKeyOrder({
        sortingKeyOrder: {
          sortingKey: sortState.column,
          sortingOrder: sortState.sortingOrder,
        },
      }),
    );
  }, [sortState]);

  return (
    <div className="editable-table-header filter-table-head">
      {columnDefinitions.map(column => (
        <div
          className="editable-header-cell"
          key={column.key}
          onClick={() => (column.sortable ? sort(column.key) : null)}
          style={column.sortable ? {cursor: 'pointer'} : {}}>
          <div className={'d-flex'}>
            {typeof column.label === 'string' ? parseUI(column.label) : column.label}
            <div className="sort-btn align-self-end">
              {column.sortable && sortState.column === column.key && (
                <Icon path={sortState.sortingOrder ? mdiChevronDown : mdiChevronUp} size={0.9} />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableHeader;
