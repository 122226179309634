import useTranslate from 'hooks/useTranslate';
import {memo, useEffect, useState} from 'react';
import {checkUnCheckAll} from 'redux/checkBox/slice';
import {updateBaitCheckerSortingKeyOrder} from 'redux/inventory/slice';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {getCheckBoxStatus} from '../../../../redux/checkBox/selector';
import Icon from '@mdi/react';
import {
  mdiAccountOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiIdentifier,
  mdiInformationBoxOutline,
  mdiQrcode,
  mdiSimOutline,
  mdiTagOutline,
} from '@mdi/js';

const initialSortState = {
  column: null,
  sortingOrder: null,
};

interface ColumnDefinition {
  label: string | JSX.Element;
  key: string;
  sortable: boolean;
  classes?: string[];
  icon?: string | null;
}

const TableHeader = () => {
  const dispatch = useAppDispatch();
  const {parseUI} = useTranslate();
  const {isCheckAll, isAllDisabled} = useAppSelector(getCheckBoxStatus);
  const [sortState, setSortState] = useState(initialSortState);

  const changeOrder = () => {
    if (sortState.sortingOrder === 1) {
      setSortState({
        column: null,
        sortingOrder: null,
      });
    }

    if (sortState.sortingOrder === 0) {
      setSortState(prevState => ({
        column: prevState.column,
        sortingOrder: 1,
      }));
    }

    if (sortState.sortingOrder === -1) {
      setSortState(prevState => ({
        column: prevState.column,
        sortingOrder: 0,
      }));
    }
  };

  const sort = (columnName: string) => {
    if (columnName === sortState.column) {
      changeOrder();
    } else {
      setSortState({
        column: columnName,
        sortingOrder: 0,
      });
    }
  };

  const handleSelectAll = () => {
    dispatch(checkUnCheckAll());
  };

  const columnDefinitions: ColumnDefinition[] = [
    {
      label: <input type="checkbox" checked={isCheckAll} onChange={handleSelectAll} disabled={isAllDisabled} />,
      key: 'selectAll',
      classes: ['multi-action-checkbox'],
      sortable: false,
    },
    {
      label: 'UNIQUE_ID',
      key: 'slug',
      sortable: true,
      classes: ['slug'],
      icon: mdiQrcode,
    },
    {
      label: 'STATUS',
      key: 'isActive',
      sortable: true,
      classes: ['status'],
      icon: mdiInformationBoxOutline,
    },
    {label: 'SIM_ID', key: 'iccNumber', sortable: true, icon: mdiSimOutline, classes: ['iccNumber']},
    {
      label: 'PEST_COMPANY_NAME',
      key: 'pestCompanyName',
      sortable: true,
      icon: mdiAccountOutline,
    },
    {
      label: 'UUID',
      icon: mdiIdentifier,
      key: 'uuid',
      sortable: false,
      classes: ['uuid'],
    },
    {label: 'TAGS', key: 'tags', sortable: false, icon: mdiTagOutline},
    {label: 'ACTIONS', key: 'uuid', sortable: false},
  ];

  useEffect(() => {
    dispatch(
      updateBaitCheckerSortingKeyOrder({
        sortingKeyOrder: {
          sortingKey: sortState.column,
          sortingOrder: sortState.sortingOrder,
        },
      }),
    );
  }, [sortState]);

  return (
    <div className="editable-table-header">
      {columnDefinitions.map(column => {
        const {key, sortable, classes, label, icon} = column;
        const parsedClasses = (classes ?? []).join(' ');
        const parsedLabel = typeof label === 'string' ? parseUI(label) : label;
        return (
          <div
            className={`editable-header-cell d-flex ${parsedClasses}`}
            key={key}
            style={{lineHeight: '24px'}}
            onClick={() => (sortable ? sort(key) : null)}>
            {key !== 'selectAll' && (
              <div className={'d-flex align-items-center cur-pointer'}>
                {icon && (
                  <div className={'me-2 align-middle'}>
                    <Icon path={icon} size={0.9} />
                  </div>
                )}
                <div style={{lineHeight: '24px', marginTop: '6px'}} className={'d-flex'}>
                  {parsedLabel}
                </div>
                {typeof column.label === 'string' && (
                  <div className="sort-btn align-self-end">
                    {column.sortable && sortState.column === column.key && (
                      <Icon path={sortState.sortingOrder ? mdiChevronDown : mdiChevronUp} size={0.9} />
                    )}
                  </div>
                )}
              </div>
            )}
            {key === 'selectAll' && parsedLabel}
          </div>
        );
      })}
    </div>
  );
};

export default memo(TableHeader);
