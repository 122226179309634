import {AssignTags, UpdateBaitBoxTagBody} from 'models/inventory.interface';
import {useCallback, useState} from 'react';

interface Item {
  uuid: string;
  name: string;
  isAssigned: string;
  isCheck: boolean;
}

const useCheckboxList = () => {
  const [data, setItems] = useState<Map<string, Item>>(() => new Map());

  // Function to add data into the hash map
  const syncCode = useCallback((data: AssignTags[]) => {
    const hashMap = new Map();
    for (const {uuid, name, isAssigned} of data) {
      hashMap.set(uuid, {
        uuid,
        name,
        isAssigned,
        isCheck: isAssigned === '1',
      });
    }

    setItems(hashMap);
  }, []);

  const toggleCheckBox = (uuid: string) => {
    setItems(prevData => {
      const newData = new Map(prevData);
      const checkBox = {...newData.get(uuid)};
      checkBox.isCheck = !checkBox.isCheck;
      newData.set(uuid, checkBox);
      return newData;
    });
  };

  const getChangedCheckBoxList = (): UpdateBaitBoxTagBody[] => {
    return Array.from(data.values())
      .filter(item => {
        // Convert isAssigned to boolean
        const isAssignedBoolean = item.isAssigned === '1';

        // Filter items where isCheck is different from isAssigned
        return item.isCheck !== isAssignedBoolean;
      })
      .map(({isCheck, uuid}) => ({isCheck, tagId: uuid}));
  };

  return {
    data: Array.from(data.values()),
    syncCode,
    toggleCheckBox,
    getChangedCheckBoxList,
  };
};

export default useCheckboxList;
