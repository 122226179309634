import {Box, Grid} from '@mui/material';
import Button from 'components/UI/Button';
import LoadingButton from 'components/UI/LoadingButton';
import AutoComplete from 'components/UI/form/AutoComplete';
import AutoTextArea from 'components/UI/form/AutoTextArea';
import Loader from 'components/shared/loader/Loader';
import {ROUTES} from 'constants/routes';
import {Form, Formik} from 'formik';
import {BaitCheckerInventoryForm} from 'models/inventory.interface';
import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {getBaitBoxTagsState} from 'redux/tags/selector';
import {getBaitBoxTagsListAsync} from 'redux/tags/thunk';
import {AddBaitCheckerInventorySchema} from 'utils/validation-schema';

type baitCheckerFormType = {
  initialValues: BaitCheckerInventoryForm;
  onSubmit: (data: BaitCheckerInventoryForm) => void;
  loading: boolean;
};

const BaitCheckerForm: FC<baitCheckerFormType> = ({initialValues, onSubmit, loading}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {tagsList, loading: isLoading} = useAppSelector(getBaitBoxTagsState);

  const getAllTags = () => {
    dispatch(getBaitBoxTagsListAsync({pageNumber: 1, limit: 50}));
  };

  useEffect(() => {
    getAllTags();
  }, []);

  if (isLoading)
    return (
      <div>
        <Loader show={true} />;
      </div>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddBaitCheckerInventorySchema}
      onSubmit={onSubmit}
      validateOnBlur={true}
      validateOnChange={true}>
      {({isValid, dirty}) => (
        <Form style={{width: '50%'}}>
          <Grid container spacing={2}>
            <AutoTextArea name="iccNumbers" label="ICC_NUMBERS" customMessage={true} />
          </Grid>

          <AutoComplete
            name="tags"
            options={tagsList}
            label="Tags"
            placeholder="Select tags"
            className="auto-complete"
          />

          <Box mt={3} justifyContent={'space-between'} alignItems={'justify-end'}>
            <div className="btn-end">
              <LoadingButton
                type="submit"
                label="SUBMIT"
                loader={loading}
                className="loading-button"
                disabled={!isValid || !dirty}
              />
              <Button
                type="button"
                onClick={() => navigate(`/${ROUTES.DASHBOARD}/${ROUTES.LIST_BAIT_CHECKER}`)}
                label="CANCEL"
              />
            </div>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default BaitCheckerForm;
