import CommentsTableHeader from './Header';
import CommentsTableBody from './TableBody';

const List = () => {
  return (
    <section className="common-table editable">
      <div className="editable-table full-width-table">
        <CommentsTableHeader />
        <CommentsTableBody />
      </div>
    </section>
  );
};

export default List;
