import ListHeader from 'components/UI/list/ListHeader';
import CommentsListing from 'components/baitchecker-inventory/comments/comments-listing/CommentsListing';
import {ROUTES} from 'constants/routes';

const {ADD_COMMENT, LIST_BAIT_CHECKER} = ROUTES;

const BaitBoxComments = () => {
  return (
    <>
      <ListHeader
        label="ADD_COMMENT"
        url={`${LIST_BAIT_CHECKER}/${ROUTES.BAIT_BOX_COMMENTS}/${ADD_COMMENT}`}
        title="COMMENTS"
      />
      <div className="body-wrapper">
        <CommentsListing />
      </div>
    </>
  );
};

export default BaitBoxComments;
