import {Box} from '@mui/material';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {BaitCheckerInventoryForm, NewlyCreatedBaitBox} from 'models/inventory.interface';
import {useState} from 'react';
import {inventoryLoading} from 'redux/inventory/selector';
import {createBaitBoxInventoryAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess, showWarning} from 'utils/toast-alerts';
import BaitCheckerForm from '../form/BaitCheckerForm';
import {CreatedBox} from './CreatedBox';

const AddBaitChecker = () => {
  const {parseUI, parseError} = useTranslate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(inventoryLoading);
  const [newlyBaitBox, setNewlyBaitbox] = useState<{
    newSim: NewlyCreatedBaitBox[];
    oldSim: NewlyCreatedBaitBox[];
  }>({
    newSim: [],
    oldSim: [],
  });

  const initialValues: BaitCheckerInventoryForm = {
    iccNumbers: '',
    tags: [],
  };

  const handleSubmit = async (formRequest: BaitCheckerInventoryForm) => {
    try {
      const body = {
        ...formRequest,
        tags: formRequest.tags.map(tag => tag.uuid),
      };
      const {data} = await dispatch(createBaitBoxInventoryAsync(body)).unwrap();
      const {
        data: {alreadyExists, newSimIds},
      } = data;
      setNewlyBaitbox({
        newSim: newSimIds,
        oldSim: alreadyExists,
      });

      if (newSimIds.length > 0) {
        const successMessage = `${newSimIds.length} ${parseError('BAITCHECKER_ADDED')}`;
        showSuccess(successMessage);
      }

      if (alreadyExists.length > 0) {
        const alreadyExisting = `${alreadyExists.map(item => item.iccNumber).join(', ')}`;
        const errorMessage = `${parseError('BAITCHECKER_EXISTS')} ${alreadyExisting}`;
        showWarning(errorMessage);
      }
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="plr36">
      <h1 className="page-title"> {parseUI('ADD_BAIT_CHECKER')} </h1>
      {newlyBaitBox.newSim.length > 0 ? (
        <CreatedBox data={newlyBaitBox} setData={setNewlyBaitbox} />
      ) : (
        <BaitCheckerForm loading={isLoading} onSubmit={handleSubmit} initialValues={initialValues} />
      )}
    </Box>
  );
};

export default AddBaitChecker;
