import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import LoadingButton from 'components/UI/LoadingButton';
import {ONLY_NUMBER} from 'utils/regex';

interface InputPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
  title: string;
}

export const CustomInputPopup: React.FC<InputPopupProps> = ({open, onClose, onSubmit, title}) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === '' || ONLY_NUMBER.test(value)) {
      setInputValue(value.trim());
    }
  };

  const handleSubmit = () => {
    onSubmit(inputValue);
    setInputValue('');
  };

  const handleClose = () => {
    setInputValue('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{paddingBottom: '0'}}>
        <strong>{title}</strong>
      </DialogTitle>
      <DialogContent style={{paddingBottom: '0'}}>
        <TextField
          label="Enter Value"
          value={inputValue}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          autoFocus
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          type="button"
          label="SUBMIT"
          disabled={false}
          className="loading-button"
          onClick={handleSubmit}
          color="green"
        />
        <LoadingButton type="button" label="CLOSE" disabled={false} className="loading-button" onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};
