import {Box, Container, styled} from '@mui/material';
import PropTypes from 'prop-types';
import {FC} from 'react';

import {PageTitleWrapperProps} from 'models/page-title-wrapper-interface';

const PageTitle = styled(Box)(
  ({theme}) => `
        padding: ${theme.spacing(4)};
`,
);

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({children}) => {
  return (
    <PageTitle className="MuiPageTitle-wrapper pest-header">
      <Container className="page-header" maxWidth="lg">
        {children}
      </Container>
    </PageTitle>
  );
};

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitleWrapper;
