import {Lock, Visibility} from '@mui/icons-material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import {Box, Button, Hidden, Popover, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useRef, useState} from 'react';
import {useNavigate} from 'react-router';

import LanguageSelector from 'components/shared/language-selector/LanguageSelector';
import {ROUTES} from 'constants/routes';
import useTranslate from 'hooks/useTranslate';
import {useAppSelector} from 'redux/redux';
import {removeUser} from 'utils/remove-user';

import {AUTH} from 'models/auth-interface';
import {selectLoggedInUser} from 'redux/auth/selector';
import {showSuccess} from 'utils/toast-alerts';

const UserBoxButton = styled(Button)(
  ({theme}) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
);

const MenuUserBox = styled(Box)(
  ({theme}) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
);

const UserBoxText = styled(Box)(
  ({theme}) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = styled(Typography)(
  ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
);

function HeaderUserbox() {
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const navigate = useNavigate();

  const {parseUI} = useTranslate();

  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const logout = () => {
    showSuccess(parseUI('LOGOUT'));
    removeUser();
    navigate(`/${ROUTES.LOGIN}`);
  };

  const changePassword = () => {
    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CHANGE_PASSWORD}`);
  };

  const onClick = (value: string) => {
    setOpen(false);
    switch (value) {
      case AUTH.LOGOUT:
        return logout();

      case AUTH.CHANGE_PASSWORD:
        return changePassword();

      default:
        setOpen(false);
    }
  };

  return (
    <>
      <LanguageSelector />
      <UserBoxButton color="secondary" ref={ref} onClick={() => setOpen(true)}>
        <Hidden>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              <span className="password-reset">{loggedInUser?.firstName}</span>
            </UserBoxLabel>
          </UserBoxText>
        </Hidden>
        <Hidden>
          <ExpandMoreTwoToneIcon sx={{ml: 1}} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuUserBox sx={{minWidth: 210}} display="flex">
          <UserBoxText>
            <UserBoxLabel variant="body1">
              <span className="password-reset">{loggedInUser?.firstName}</span>
            </UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>

        <Box sx={{m: 1}}>
          <Button color="primary" fullWidth onClick={() => onClick(AUTH.CHANGE_PASSWORD)} className="flex-start-item">
            <Visibility sx={{mr: 1}} />
            {parseUI('CHANGE_PASSWORD')}
          </Button>
        </Box>

        <Box sx={{m: 1}}>
          <Button color="primary" fullWidth onClick={() => onClick(AUTH.LOGOUT)} className="flex-start-item">
            <Lock sx={{mr: 1}} />
            {parseUI('LOGOUT')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
