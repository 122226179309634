import {ThemeProvider} from '@mui/material';
import {StylesProvider} from '@mui/styles';
import React, {useCallback, useState} from 'react';
import {themeCreator} from './base';

export const ThemeContext = React.createContext((): void => {});

const ThemeProviderWrapper: React.FC = props => {
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);

  const setThemeName = useCallback((newThemeName: string) => {
    localStorage.setItem('appTheme', newThemeName);
    _setThemeName(newThemeName);
  }, []);

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName as () => void}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
