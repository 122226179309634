import {MenuItem, TextField} from '@mui/material';
import {useField} from 'formik';
import {FC} from 'react';

import useTranslate from 'hooks/useTranslate';

interface DropdownProps {
  name: string;
  list?: any[];
  label: string;
}

/**
 * @description: Dropdown component for formik
 * @param param0
 * @returns
 */
const Dropdown: FC<DropdownProps> = ({name, list, label}) => {
  const [field, meta] = useField(name);

  const {parseUI, parseError} = useTranslate();

  return (
    <TextField
      select
      label={parseUI(label)}
      {...field}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? parseError(meta.error) : ''}
      variant="outlined"
      margin="normal"
      fullWidth>
      {list.map(item => (
        <MenuItem key={item.uuid} value={item.uuid}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Dropdown;
