import AdapterDayjs from '@mui/lab/AdapterDayjs';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {FormHelperText} from '@mui/material';
import TextField from '@mui/material/TextField';
import {useField, useFormikContext} from 'formik';
import useTranslate from 'hooks/useTranslate';
import React from 'react';

interface DateTimePickerProps {
  label: string;
  initialValue?: string;
  onChange?: (value: Date) => void;
  name: string;
}

/**
 * @desc: common date picker component
 * @param param0
 * @returns
 */
const DateTimePickerCommonComponent: React.FC<DateTimePickerProps> = ({label, initialValue, onChange, name}) => {
  const {parseUI, parseError} = useTranslate();

  const {setFieldTouched} = useFormikContext();
  const [, meta] = useField(name);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={parseUI(label)}
        value={initialValue}
        onChange={(date: Date) => {
          if (date) onChange(date);
        }}
        className="date-time-picker"
        onClose={() => setFieldTouched(name, true)}
        shouldDisableDate={date => {
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          return date < currentDate;
        }}
        renderInput={props => (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            onBlur={() => setFieldTouched(name, true)}
            {...props}
            error={meta.touched && !!meta.error}
          />
        )}
      />
      {meta.touched && meta.error ? (
        <FormHelperText style={{color: 'red'}}>{parseError(meta.error)}</FormHelperText>
      ) : null}
    </LocalizationProvider>
  );
};

export default DateTimePickerCommonComponent;
