import {Autocomplete, Checkbox, TextField} from '@mui/material';
import {Field, useFormikContext} from 'formik';
import {FC} from 'react';

interface Options {
  name: string;

  [key: string]: any;
}

type autoCompletetypes = {
  name: string;
  label: string;
  options: Options[];
  placeholder?: string;
  className?: string;
};

const AutoComplete: FC<autoCompletetypes> = ({options, label, name, placeholder, className, ...props}) => {
  const {setFieldValue} = useFormikContext();

  const handleChange = (_, value) => {
    setFieldValue(name, value);
  };
  return (
    <Field name={name}>
      {({field}) => (
        <Autocomplete
          {...field}
          {...props}
          multiple
          id="checkboxes-tags-demo"
          options={options}
          disableCloseOnSelect
          onChange={handleChange}
          className={className}
          getOptionLabel={(option: any) => option.name}
          renderOption={(prop, option: Options, {selected}) => (
            <li {...prop}>
              <Checkbox style={{marginRight: 8}} checked={selected} />
              {option.name}
            </li>
          )}
          style={{width: 500}}
          renderInput={params => <TextField {...params} label={label} placeholder={placeholder ?? 'Select'} />}
        />
      )}
    </Field>
  );
};

export default AutoComplete;
