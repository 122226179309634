import AddBaitChecker from 'components/baitchecker-inventory/add/AddBaitChecker';
import AddBaitBoxComment from 'components/baitchecker-inventory/comments/add-comment/AddBaitBoxComment';
import AddPestCompany from 'components/pest-company/AddPestCompany';
import EditPestCompany from 'components/pest-company/EditPestCompany';
import PestCompanyDetails from 'components/pest-company/PestCompanyDetails';
import AddMaintenance from 'components/schedule-maintenance/add/AddMaintenance';
import AddBaitBoxTag from 'components/tags/add/AddBaitBoxTag';
import {ROUTES} from 'constants/routes';
import SidebarLayout from 'layouts/SidebarLayout';
import AssignTag from 'pages/AssignTag';
import BaitBoxComments from 'pages/BaitBoxComments';
import BaitBoxLogs from 'pages/BaitBoxLogs';
import BaitBoxTags from 'pages/BaitBoxTags';
import BaitCheckerInventory from 'pages/BaitCheckerInventory';
import BaitCheckerSimulator from 'pages/BaitCheckerSimulator';
import {lazy} from 'react';
import {Navigate} from 'react-router-dom';

const PestCompany = lazy(() => import('components/pest-company'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));

const dashboardRoutes = [
  {
    path: ROUTES.DASHBOARD,
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.PEST_COMPANY} replace />,
      },
      {
        path: `${ROUTES.PEST_COMPANY}`,
        element: <PestCompany />,
      },
      {
        path: ROUTES.ADD_PEST_COMPANY,
        element: <AddPestCompany />,
      },
      {
        path: `${ROUTES.EDIT_PEST_COMPANY}/:id`,
        element: <EditPestCompany />,
      },
      {
        path: `${ROUTES.VIEW_PEST_COMPANY}/:id`,
        element: <PestCompanyDetails />,
      },
      {
        path: ROUTES.CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
      {
        path: ROUTES.LIST_BAIT_CHECKER,
        element: <BaitCheckerInventory />,
      },
      {
        path: ROUTES.ADD_BAIT_CHECKER,
        element: <AddBaitChecker />,
      },
      {
        path: ROUTES.LIST_TAGS,
        element: <BaitBoxTags />,
      },
      {
        path: ROUTES.ADD_TAG,
        element: <AddBaitBoxTag />,
      },
      {
        path: ROUTES.BAIT_CHECKER_SIMULATOR,
        element: <BaitCheckerSimulator />,
      },
      {
        path: `${ROUTES.LIST_BAIT_CHECKER}/${ROUTES.ASSIGN_TAG}/:id`,
        element: <AssignTag />,
      },
      {
        path: `${ROUTES.LIST_BAIT_CHECKER}/${ROUTES.BAIT_BOX_COMMENTS}/:id`,
        element: <BaitBoxComments />,
      },
      {
        path: `${ROUTES.LIST_BAIT_CHECKER}/${ROUTES.BAIT_BOX_COMMENTS}/${ROUTES.ADD_COMMENT}`,
        element: <AddBaitBoxComment />,
      },
      {
        path: `${ROUTES.LIST_BAIT_CHECKER}/${ROUTES.BAIT_BOX_LOGS}/:id`,
        element: <BaitBoxLogs />,
      },
      {
        path: ROUTES.SCHEDULE_MAINTENANCE,
        element: <AddMaintenance />,
      },
    ],
  },
];

export default dashboardRoutes;
