import React, {useEffect} from 'react';
import {FormikErrors, useField} from 'formik';
import {TextField} from '@mui/material';
import {INPUT_TYPES} from 'constants/ui-input';

import useTranslate from 'hooks/useTranslate';
import useDebounce from 'hooks/useDebounce';
import {getBaitBoxStatusAsync} from 'redux/inventory/thunk';
import {useAppDispatch} from 'redux/redux';
import {CreateSimulateData} from 'models/inventory.interface';

const inputType = {
  TEXT: INPUT_TYPES.TEXT,
  NUMBER: INPUT_TYPES.NUMBER,
  EMAIL: INPUT_TYPES.EMAIL,
  PASSWORD: INPUT_TYPES.PASSWORD,
  TEXT_AREA: INPUT_TYPES.TEXT_AREA,
  CHECK_BOX: INPUT_TYPES.CHECK_BOX,
  RADIO: INPUT_TYPES.RADIO,
} as const;

interface DebounceInputFieldProps {
  label: string;
  name: string;
  type?: typeof INPUT_TYPES[keyof typeof inputType]; //
  isPasswordVisible?: boolean;
  changePassVisibility?: () => void;
  eyeIcon?: boolean;
  debounceTimeout?: number; // Prop for debounce timeout
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CreateSimulateData>>;
}

const DebounceInputField: React.FC<DebounceInputFieldProps> = ({
  label,
  debounceTimeout = 500, // Default debounce timeout
  setFieldValue,
  ...props
}) => {
  const [field, meta, {setValue, setError, setTouched}] = useField(props);
  const dispatch = useAppDispatch();

  const {parseUI, parseError} = useTranslate();
  const {onBlur, name, value} = field;

  const debouncedValue = useDebounce<string>(field.value, debounceTimeout);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const getBaitBoxStatus = async iccNumber => {
    try {
      await dispatch(getBaitBoxStatusAsync(iccNumber)).unwrap();
      setFieldValue('isValidHash', true);
      setTouched(true);
    } catch (error) {
      setError('INVALID_BAIT_BOX');
      setFieldValue('isValidHash', false);
    }
  };

  useEffect(() => {
    setValue(debouncedValue);
    //call the get baitchecker details api to check if the baitbox is active or inactive
    if (debouncedValue) getBaitBoxStatus(debouncedValue);
  }, [debouncedValue]);

  return (
    <TextField
      onBlur={onBlur}
      name={name}
      {...props}
      label={parseUI(label)}
      value={value} // Use local state for controlled input
      onChange={handleInputChange}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? parseError(meta.error) : ''}
      variant="outlined"
      margin="normal"
      fullWidth
    />
  );
};

export default DebounceInputField;
