import {Box, Grid} from '@mui/material';
import {Form, Formik} from 'formik';
import useTranslate from 'hooks/useTranslate';
import {useNavigate, useParams} from 'react-router';

import Button from 'components/UI/Button';
import LoadingButton from 'components/UI/LoadingButton';
import CustomPhoneInput from '../UI/CustomPhoneInput';
import InputField from 'components/UI/form/InputField';
import {ROUTES} from 'constants/routes';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {showError, showSuccess} from 'utils/toast-alerts';
import {UpdatePestCompanySchema} from 'utils/validation-schema';

import Dropdown from 'components/UI/form/Dropdown';
import Loader from 'components/shared/loader/Loader';
import {DEFAULT_VALUES} from 'constants/default-values';
import {HttpError} from 'models/http-interface';
import {LanguagesList, UpdatePestCompanyData} from 'models/pest-company-interface';
import {useEffect, useState} from 'react';
import {pestCompanyDetails, selectLoading} from 'redux/pest-company/selector';
import {getLanguagesListAsync, updatePestCompanyAsync} from 'redux/pest-company/thunk';
import {formatError} from 'utils/http-error-formatter';

const editPestCompanyFields = [
  {name: 'country', label: 'COUNTRY', type: 'text'},
  {name: 'name', label: 'PEST_COMPANY_NAME', type: 'string'},
  {name: 'personName', label: 'PERSON_NAME', type: 'string'},
  {name: 'email', label: 'EMAIL', type: 'email'},
  {name: 'phoneNumber', label: 'PHONE_NUMBER', type: 'text', maxLength: 11},
  {name: 'street', label: 'STREET', type: 'text'},
  {name: 'city', label: 'CITY', type: 'text'},
  {name: 'postCode', label: 'POSTAL_CODE', type: 'text'},
  {name: 'taxId', label: 'TAX_ID', type: 'text'},
  {name: 'languageId', label: 'LANGUAGE', type: 'text'},
];

const EditPestCompany = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [languages, setLanguages] = useState<LanguagesList[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {parseUI} = useTranslate();
  const {id} = useParams();

  const pestCompanyDetail = useAppSelector(pestCompanyDetails);
  const loading = useAppSelector(selectLoading);

  const [country, setCountry] = useState<string>(pestCompanyDetail.country);

  /** to fetch languages list */
  useEffect(() => {
    setIsLoading(true);
    fetchLanguages();
  }, []);

  /**
   * @description: to fetch languages list
   */
  const fetchLanguages = async () => {
    try {
      const response = await dispatch(getLanguagesListAsync()).unwrap();
      setIsLoading(false);

      /** to update state */
      setLanguages(response.data);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      setIsLoading(false);

      showError(errorMsg);
    }
  };

  const initialValues: UpdatePestCompanyData = {
    country: pestCompanyDetail.country,
    name: pestCompanyDetail.name,
    personName: pestCompanyDetail.personName,
    email: pestCompanyDetail.email,
    phoneNumber: pestCompanyDetail.phoneNumber,
    street: pestCompanyDetail.street,
    city: pestCompanyDetail.city,
    postCode: pestCompanyDetail.postCode,
    taxId: pestCompanyDetail.taxId,
    languageId: pestCompanyDetail.languageId ?? DEFAULT_VALUES.DEFAULT_LANGUAGE_ID,
  };

  const handleUpdate = async (values: UpdatePestCompanyData) => {
    const formData = UpdatePestCompanySchema.cast(values);
    const formValues = {
      ...values,
      phoneNumber: formData.phoneNumber,
      country,
    };

    try {
      const response = await dispatch(
        updatePestCompanyAsync({
          pestCompanyId: id,
          data: formValues,
        }),
      ).unwrap();
      showSuccess(response.message);
      navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PEST_COMPANY}`);
    } catch (error) {
      showError(error.message);
    }
  };

  const onCancelClick = () => {
    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PEST_COMPANY}`);
  };

  /**
   * @description: to render fields
   * @param field
   * @param languages
   * @returns
   */
  const renderField = (field: {name: string; label: string; type: string}, languages: any[]) => {
    if (field.name === 'phoneNumber') {
      return (
        <CustomPhoneInput
          name="phoneNumber"
          type="text"
          label={field.label}
          maxLength={20}
          country={country}
          onCountryChange={setCountry}
        />
      );
    } else if (field.name === 'languageId') {
      return <Dropdown name={field.name} list={languages} label={field.label} />;
    } else if (field.name !== 'country') {
      return <InputField name={field.name} type={field.type} label={field.label} />;
    }
  };

  return (
    <>
      <Loader show={isLoading} />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="plr36">
        <h1 className="page-title"> {parseUI('EDIT_PEST_COMPANY')}</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={UpdatePestCompanySchema}
          onSubmit={handleUpdate}
          validateOnBlur={true}
          validateOnChange={true}>
          {({isValid}) => (
            <Form>
              <Grid container spacing={2}>
                {editPestCompanyFields
                  ?.filter(f => f.name !== 'country')
                  .map(field => (
                    <Grid item xs={12} sm={6} className="pt-0" key={field.name}>
                      {renderField(field, languages)}
                    </Grid>
                  ))}
              </Grid>
              <Box mt={3} justifyContent={'space-between'} alignItems={'justify-end'}>
                <div className="btn-end">
                  <LoadingButton
                    type="submit"
                    label="SUBMIT"
                    loader={loading}
                    className="loading-button"
                    disabled={!isValid || loading}
                  />

                  <Button type="button" onClick={onCancelClick} label="CANCEL" />
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default EditPestCompany;
