import {createAsyncThunk} from '@reduxjs/toolkit';
import {ChangePasswordData, LoginData} from 'models/user-interface';

import {changePassword, loginUser, updateAccessToken} from './api';

// Async thunk returns us three action creators
export const loginUserAsync = createAsyncThunk('user/loginUser', async (loginInfo: LoginData, {rejectWithValue}) => {
  try {
    const {data} = await loginUser(loginInfo);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changePasswordAsync = createAsyncThunk(
  'user/changePassword',
  async (data: ChangePasswordData, {rejectWithValue}) => {
    try {
      const response = await changePassword(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateAccessTokenAsync = createAsyncThunk(
  'user/refreshToken',
  async (data: {refreshToken: string}, {rejectWithValue}) => {
    try {
      const response = await updateAccessToken(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
