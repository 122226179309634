import {FC} from 'react';
import {getSignalColor} from 'utils/signal-indicator';

const SignalIndicator: FC<{signalValue: number}> = ({signalValue}) => {
  const signalLevel = getSignalColor(signalValue);
  return (
    <>
      <svg
        className="signal-icon"
        width="24"
        height="24"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_22_2667)">
          <path
            // eslint-disable-next-line max-len
            d="M16 1C16 0.447716 16.4477 0 17 0C17.5523 0 18 0.447715 18 1V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V1Z"
            fill={signalLevel.five}
          />
          <path
            // eslint-disable-next-line max-len
            d="M12 5C12 4.57045 12.4477 4 13 4C13.5523 4 14 4.57045 14 5V17.2222C14 17.6518 13.5523 18 13 18C12.4477 18 12 17.6518 12 17.2222V5Z"
            fill={signalLevel.four}
          />
          <path
            // eslint-disable-next-line max-len
            d="M8 8.83152C8 8.5247 8.44772 8 9 8C9.55228 8 10 8.5247 10 8.83152V17.1863C10 17.4931 9.55228 18 9 18C8.44772 18 8 17.4931 8 17.1863V8.83152Z"
            fill={signalLevel.three}
          />
          <path
            // eslint-disable-next-line max-len
            d="M4 12.7225C4 12.5384 4.44772 12 5 12C5.55228 12 6 12.5384 6 12.7225V17.1992C6 17.3833 5.55228 18 5 18C4.44772 18 3.98047 17.3833 3.98047 17.1992L4 12.7225Z"
            fill={signalLevel.two}
          />
          <circle cx="1" cy="17" r="1" fill={signalLevel.one} />
        </g>
        <defs>
          <clipPath id="clip0_22_2667">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default SignalIndicator;
