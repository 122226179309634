import {FC} from 'react';

interface InputCheckBoxProps {
  disabled?: boolean;
  checked: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputCheckBox: FC<InputCheckBoxProps> = ({disabled, checked, onChange, value}) => {
  return <input disabled={disabled} type="checkbox" checked={checked} onChange={onChange} value={value} />;
};

export default InputCheckBox;
