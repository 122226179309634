import {Button, List, ListItem} from '@mui/material';
import {useContext} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';

import sidebarLinks from 'config/sidebar-routes';
import {SidebarContext} from 'contexts/SidebarContext';
import useTranslate from 'hooks/useTranslate';
import MenuWrapper from './MenuWrapper';
import SubMenuWrapper from './SubMenuWrapper';
import VersionBadge from '../../../../components/shared/version-badge/VersionBadge';

function SidebarMenu() {
  const location = useLocation();
  const {closeSidebar} = useContext(SidebarContext);
  const {parseUI} = useTranslate();

  const renderSidebarLinks = () => {
    return sidebarLinks.map(({path, title, id}) => (
      <List component="div" key={id}>
        <ListItem component="div">
          <Button
            disableRipple
            component={NavLink}
            onClick={closeSidebar}
            to={path}
            className={location.pathname === path ? 'active' : 'inactive'}>
            {parseUI(title)}
          </Button>
        </ListItem>
      </List>
    ));
  };

  return (
    <>
      <div className="sidebar-logo">
        <Link to={'/'} className={'text-decoration-none justify-content-start'}>
          <div>
            <img src="/static/images/svg/logo.svg" width={100} alt="[z]Control" />
            <img className={'ms-2'} src="/static/images/zbase.svg" width={25} alt="[z]Control" />
          </div>
          <div className={'text-white text-decoration-none'}>Admin Backoffice</div>
          <VersionBadge />
        </Link>
      </div>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper> {renderSidebarLinks()}</SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
