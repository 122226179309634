import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import {FC} from 'react';

type InfoTooltipComponent = {
  name: unknown;
  children: unknown;
  className?: string;
};

const InfoTooltip: FC<InfoTooltipComponent> = ({name, children, className = 'cur-pointer'}) => {
  const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: '#13A6B1',
      color: '#fff',
      padding: '5px 10px',
    },
  }));

  return (
    <HtmlTooltip title={name} placement="bottom">
      <span className={className}>{children}</span>
    </HtmlTooltip>
  );
};

export default InfoTooltip;
