import {Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {useField} from 'formik';
import React from 'react';

import {INPUT_TYPES} from 'constants/ui-input';
import useTranslate from 'hooks/useTranslate';

const inputType = {
  TEXT: INPUT_TYPES.TEXT,
  NUMBER: INPUT_TYPES.NUMBER,
  EMAIL: INPUT_TYPES.EMAIL,
  PASSWORD: INPUT_TYPES.PASSWORD,
  TEXT_AREA: INPUT_TYPES.TEXT_AREA,
  CHECK_BOX: INPUT_TYPES.CHECK_BOX,
  RADIO: INPUT_TYPES.RADIO,
} as const;

interface InputFieldProps {
  label: string;
  name: string;
  type?: typeof inputType[keyof typeof inputType];
  isPasswordVisible?: boolean;
  changePassVisibility?: () => void;
  eyeIcon?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({label, isPasswordVisible, changePassVisibility, eyeIcon, ...props}) => {
  const [field, meta] = useField(props);

  const {parseUI, parseError} = useTranslate();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...field}
      {...props}
      label={parseUI(label)}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? parseError(meta.error) : ''}
      variant="outlined"
      margin="normal"
      fullWidth
      className={eyeIcon && 'setPass'}
      InputProps={{
        endAdornment: eyeIcon && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={changePassVisibility}
              onMouseDown={handleMouseDownPassword}>
              {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputField;
