import {Box, Grid} from '@mui/material';
import LoadingButton from 'components/UI/LoadingButton';
import DateTimePickerCommonComponent from 'components/UI/date-time-picker/DateTimePicker';
import InputField from 'components/UI/form/InputField';
import Loader from 'components/shared/loader/Loader';
import {Field, Form, Formik} from 'formik';
import {ScheduleMaintenanceForm} from 'models/maintenance-interface';
import {FC} from 'react';
import {useAppSelector} from 'redux/redux';
import {getBaitBoxTagsState} from 'redux/tags/selector';
import {ScheduleMaintenanceSchema} from 'utils/validation-schema';

type ScheduleMaintenanceType = {
  initialValues: ScheduleMaintenanceForm;
  onSubmit: (data: ScheduleMaintenanceForm, {resetForm}) => void;
  loading: boolean;
};

const scheduleMaintenanceFields = [
  {name: 'description', label: 'DESCRIPTION', type: 'text'},
  {name: 'emailSubject', label: 'EMAIL_SUBJECT', type: 'text'},
];

const ScheduleMaintenance: FC<ScheduleMaintenanceType> = ({initialValues, onSubmit, loading}) => {
  const {loading: isLoading} = useAppSelector(getBaitBoxTagsState);

  if (isLoading)
    return (
      <div>
        <Loader show={true} />;
      </div>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ScheduleMaintenanceSchema}
      onSubmit={onSubmit}
      validateOnBlur={true}
      validateOnChange={true}>
      {({isValid, dirty, setFieldValue, setFieldTouched, touched}) => {
        const handleTimeChange = (field: string, value: Date) => {
          setFieldValue(field, value);
          if (touched.emailScheduledTime) {
            setFieldTouched('emailScheduledTime', true, false); // manually trigger validation for emailScheduledTime
          }
        };

        return (
          <Form style={{width: '50%'}}>
            <Grid container spacing={1}>
              <Field name="startTime">
                {({field}) => (
                  <DateTimePickerCommonComponent
                    name="startTime"
                    label="START_TIME"
                    initialValue={field.value}
                    onChange={date => handleTimeChange(field.name, new Date(date))}
                  />
                )}
              </Field>
            </Grid>

            <Grid container spacing={1}>
              <Field name="endTime">
                {({field}) => (
                  <DateTimePickerCommonComponent
                    label="END_TIME"
                    name="endTime"
                    initialValue={field.value}
                    onChange={date => handleTimeChange(field.name, new Date(date))}
                  />
                )}
              </Field>
            </Grid>

            {scheduleMaintenanceFields?.map(field => (
              <Grid container spacing={1} key={field.name}>
                <InputField name={field.name} type={field.type} label={field.label} />
              </Grid>
            ))}

            <Grid container spacing={1}>
              <Field name="emailScheduledTime">
                {({field}) => (
                  <DateTimePickerCommonComponent
                    name="emailScheduledTime"
                    label="EMAIL_SCHEDULED_TIMESTAMP"
                    initialValue={field.value}
                    onChange={date => {
                      if (date) setFieldValue(field.name, new Date(date));
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Box mt={3} justifyContent={'space-between'} alignItems={'justify-end'}>
              <div className="btn-end">
                <LoadingButton
                  type="submit"
                  label="SUBMIT"
                  loader={loading}
                  className="loading-button"
                  disabled={!isValid || !dirty}
                />
              </div>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ScheduleMaintenance;
