import TableHeader from './Header';
import ScheduleMaintenanceTableBody from './TableBody';

const List = () => {
  return (
    <section className="common-table editable">
      <div className="editable-table inventory-outer">
        <TableHeader />
        <ScheduleMaintenanceTableBody />
      </div>
    </section>
  );
};

export default List;
