import useTranslate from 'hooks/useTranslate';
import {memo} from 'react';

const TableHeader = () => {
  const {parseUI} = useTranslate();

  const columnDefinitions = [
    {label: 'DESCRIPTION', key: 'description', sortable: false},
    {label: 'EMAIL_SUBJECT', key: 'emailSubject', sortable: false},
    {label: 'START_TIME', key: 'startTime', sortable: false},
    {label: 'END_TIME', key: 'endTime', sortable: false},
    {
      label: 'EMAIL_SCHEDULED_TIMESTAMP',
      key: 'emailScheduledTime',
      sortable: false,
    },
  ];

  return (
    <div className="editable-table-header">
      {columnDefinitions.map(column => (
        <div
          className="editable-header-cell"
          key={column.key}
          onClick={() => null}
          style={column.sortable ? {cursor: 'pointer'} : {}}>
          {typeof column.label === 'string' ? parseUI(column.label) : column.label}
        </div>
      ))}
    </div>
  );
};

export default memo(TableHeader);
