import axiosInstance from 'api/api-service';
import {HttpResponse} from 'models/http-interface';

import {USER} from 'constants/api-endpoints';
import {ChangePasswordData, LoginData, LoginResponse} from 'models/user-interface'; //apis

//apis
export const loginUser = (body: LoginData) => axiosInstance.post<HttpResponse<LoginResponse>>(USER.LOGIN, body);

export const changePassword = (body: ChangePasswordData) =>
  axiosInstance.post<HttpResponse<null>>(USER.CHANGE_PASSWORD, body);

export const updateAccessToken = (body: {refreshToken: string}) =>
  axiosInstance.post<HttpResponse<{refreshToken: string; accessToken: string}>>(USER.UPDATE_ACCESS_TOKEN, body);
