import {TableCell} from '@mui/material';
import useTranslate from 'hooks/useTranslate';

const tagColumns = [
  {label: 'NAME', key: 'name'},
  {label: 'ASSIGNED', key: 'assigned'},
];

const TagTableHeader = () => {
  const {parseUI} = useTranslate();
  const renderHeadings = () => {
    return tagColumns.map(({key, label}) => (
      <TableCell className="editable-header-cell" key={key}>
        <span>{parseUI(label)}</span>
      </TableCell>
    ));
  };

  return <> {renderHeadings()}</>;
};

export default TagTableHeader;
