import {Grid, Typography} from '@mui/material';
import SearchBar from 'components/UI/searchbar/SearchBar';
import {DEBOUNCE_TIME} from 'constants/debouce-time';
import useTranslate from 'hooks/useTranslate';
import {debounce} from 'lodash';
import {FC} from 'react';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {useAppSelector} from 'redux/redux';

const {BAITCHECKER_SEARCH_DELAY} = DEBOUNCE_TIME;

type BaitBoxTagsPageHeaderProp = {
  textChange?: (text: string) => void;
};

const PageHeader: FC<BaitBoxTagsPageHeaderProp> = ({textChange}) => {
  const {baitBoxTagsSearchText, baitCheckerTags, inventoryDetails} = useAppSelector(getBaitCheckerState);

  const {parseUI} = useTranslate();

  const showSearchBar = () => {
    return baitBoxTagsSearchText || (baitCheckerTags && baitCheckerTags.length > 0);
  };

  const textSearchHandler = (text: string) => {
    textChange(text);
  };

  const handleSearch = debounce(textSearchHandler, BAITCHECKER_SEARCH_DELAY);

  return (
    <Grid container justifyContent="space-between" alignItems="center" className="detail-header">
      <div>
        <Typography variant="h3" component="h3" gutterBottom>
          {parseUI('TAGS')}
        </Typography>
      </div>

      <div className="header-row-wrap">
        {showSearchBar() && <SearchBar onInputChange={handleSearch} textChange={textChange} />}

        <Typography variant="h4" component="h4" gutterBottom>
          {parseUI('BAITCHECKER_UNIQUE_ID')}:{inventoryDetails?.slug ? inventoryDetails.slug : '--'}
        </Typography>
      </div>
    </Grid>
  );
};

export default PageHeader;
