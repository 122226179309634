import AssignTagsListing from 'components/baitchecker-inventory/assign-tags/AssignTagsListing';
import {usePagination} from 'hooks/usePagination';
import {useEffect} from 'react';
import {useParams} from 'react-router';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {updateBaitboxTagSearchText, updateInventoryDetails, updateTagsList} from 'redux/inventory/slice';
import {getAllAndAssignedTags} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';

const AssignTag = () => {
  const {id} = useParams();
  const {baitBoxTagsSearchText} = useAppSelector(getBaitCheckerState);

  const {page, limit, onPageChange, onLimitChange} = usePagination(1, 50);

  const dispatch = useAppDispatch();

  const onTextChange = (text: string) => {
    dispatch(updateBaitboxTagSearchText({text}));
    if (page === 1) {
      dispatch(
        getAllAndAssignedTags({
          baitBoxId: id,
          pageNumber: 1,
          limit,
          searchText: text,
        }),
      );
    } else {
      onLimitChange(10);
    }
  };

  const getTagsAssignments = () => {
    dispatch(
      getAllAndAssignedTags({
        baitBoxId: id,
        pageNumber: page,
        limit,
        searchText: baitBoxTagsSearchText,
      }),
    );
  };

  useEffect(() => {
    if (page && limit) {
      getTagsAssignments();
    }
  }, [page, limit]);

  useEffect(() => {
    // Cleanup the inventory details and tag list when assign tag component unmounts
    return () => {
      dispatch(updateTagsList());
      dispatch(updateInventoryDetails(null));
      dispatch(updateBaitboxTagSearchText({text: null}));
    };
  }, []);

  return <AssignTagsListing textChange={onTextChange} pagination={{page, limit, onPageChange, onLimitChange}} />;
};

export default AssignTag;
