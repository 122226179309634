import {Edit, Save, Visibility as ViewIcon} from '@mui/icons-material';
import AddCommentIcon from '@mui/icons-material/AddComment';
import TagIcon from '@mui/icons-material/Tag';
import {IconButton} from '@mui/material';
import InfoTooltip from 'components/UI/InfoTooltip';
import InputTableCell from 'components/UI/form/InputTableCell';
import {BAIT_BOX_ACTIONS} from 'constants/baitchecker-icon-actions';
import {Form, Formik} from 'formik';
import useDialog from 'hooks/useDialog';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {BaitCheckerInventory, CheckboxInventoryData} from 'models/inventory.interface';
import {Tag} from 'models/pest-company-interface';
import {FC, memo, useCallback, useState} from 'react';
import {useNavigate} from 'react-router';
import {setBaitBoxLogDetails} from 'redux/baitbox-log-details/slice';
import {updateInventoryDetails} from 'redux/inventory/slice';
import {updateBaitBoxMetaAsync} from 'redux/inventory/thunk';
import {useAppDispatch} from 'redux/redux';
import {getStatusIconText} from 'utils/baitbox-status';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import {EditBaitCheckerSchema} from 'utils/validation-schema';
import TagPopup from '../tag-popup/TagPopup';

const {TAGS, COMMENTS, LOGS} = BAIT_BOX_ACTIONS;

type rowProps = {
  data: CheckboxInventoryData;
  onSelect: () => void;
};

const Row: FC<rowProps> = ({data, onSelect}) => {
  const navigate = useNavigate();
  const {parseUI} = useTranslate();
  const dispatch = useAppDispatch();
  const [showForm, setShowForm] = useState<boolean>(false);
  const {isOpen, showDialog, hideDialog} = useDialog(false);

  const onSubmit = async (formData: BaitCheckerInventory) => {
    const {uuid, tareOffset, multiplier} = formData;
    const data = {
      baitBoxId: uuid,
      tareOffset,
      multiplier,
    };

    try {
      const response = await dispatch(updateBaitBoxMetaAsync(data)).unwrap();
      showSuccess(response.message);
      setShowForm(false);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
      setShowForm(true);
    }
  };

  const onClickIcon = (selectedBaitBox: BaitCheckerInventory, actionType: string) => {
    // updates redux reference of currently selected baitbox
    dispatch(updateInventoryDetails(selectedBaitBox));
    dispatch(setBaitBoxLogDetails(selectedBaitBox));
    if ([TAGS, COMMENTS, LOGS].includes(actionType)) {
      navigate(`${actionType}/${selectedBaitBox.uuid}`);
    }
  };

  const renderTags = (tags: Tag[] | null) => {
    const length = getTagsLength();
    if (tags === null) return <>--</>;
    if (length > 0) {
      return `${tags[0].name}... +${length}`;
    }
    return `${tags[0].name}`;
  };

  const showTags = () => {
    if (data.tags && data.tags.length > 0) {
      dispatch(updateInventoryDetails(data));
      showDialog(true);
    }
  };

  const getTagsLength = () => {
    if (data?.tags?.length > 1) {
      return data.tags.length - 1;
    }
    return 0;
  };

  const getTagsTooltipMessage = (tags: Tag[] | null) => {
    if (!tags) return '';
    const length = getTagsLength();
    if (length >= 0) {
      let tagsList = '';

      for (let i = 0; i < tags.length; i++) {
        tagsList += `${tags[i].name}${i != tags.length - 1 ? ', ' : ''}`;
      }

      return tagsList;
    }
  };

  const closePopup = useCallback(() => {
    hideDialog();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          ...data,
          pestCompanyName: data.pestCompanyName ? data.pestCompanyName : '--',
        }}
        enableReinitialize={true}
        validationSchema={EditBaitCheckerSchema}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={true}>
        {({isValid}) => (
          <Form className="editable-table-row">
            <div className="editable-table-cell multi-action-checkbox">
              <input disabled={!data.isActive} type="checkbox" checked={data.isCheck} onChange={onSelect} />
            </div>
            <div className="editable-table-cell cur-pointer slug hoverable" onClick={() => onClickIcon(data, LOGS)}>
              <InputTableCell name="slug" type="text" readOnly={true} />
            </div>
            <div className="editable-table-cell status" style={{display: 'flex', textAlign: 'center'}}>
              <InfoTooltip name={parseUI(getStatusIconText(data.status, data.isActive, data.isRetire).text)}>
                <img
                  src={getStatusIconText(data.status, data.isActive, data.isRetire).icon}
                  width={24}
                  height={24}
                  alt={'#'}
                />
              </InfoTooltip>
            </div>
            <div className="editable-table-cell iccNumber hoverable">
              <InputTableCell name="iccNumber" type="text" readOnly={true} />
            </div>
            <div className="editable-table-cell">
              <InputTableCell name="pestCompanyName" type="text" readOnly={true} />
            </div>
            <div className="editable-table-cell uuid">
              <InputTableCell name="uuid" type="text" readOnly={!showForm} />
            </div>
            <div
              className={`editable-table-cell ${data.tags && data.tags.length > 0 ? 'clickable-div' : ''}`}
              onClick={showTags}>
              <InfoTooltip name={`${getTagsTooltipMessage(data.tags)}`}>{renderTags(data.tags)}</InfoTooltip>
            </div>

            <div className="editable-table-cell">
              {showForm ? (
                <IconButton aria-label="Save" disabled={!isValid} type="submit">
                  <Save />
                </IconButton>
              ) : (
                <InfoTooltip name={showForm ? parseUI('SAVE') : parseUI('EDIT')}>
                  <IconButton aria-label="Edit" onClick={() => setShowForm(prev => !prev)}>
                    <Edit />
                  </IconButton>
                </InfoTooltip>
              )}
              {
                <InfoTooltip name={parseUI('LOGS')}>
                  <IconButton aria-label="baitbox-logs" onClick={() => onClickIcon(data, LOGS)}>
                    <ViewIcon />
                  </IconButton>
                </InfoTooltip>
              }
              {
                <InfoTooltip name={parseUI('ASSIGN_TAGS')}>
                  <IconButton aria-label="AddTag" onClick={() => onClickIcon(data, TAGS)}>
                    <TagIcon />
                  </IconButton>
                </InfoTooltip>
              }
              {
                <InfoTooltip name={parseUI('COMMENTS')}>
                  <IconButton aria-label="AddTag" onClick={() => onClickIcon(data, COMMENTS)}>
                    <AddCommentIcon />
                  </IconButton>
                </InfoTooltip>
              }
            </div>
          </Form>
        )}
      </Formik>
      <TagPopup open={isOpen} onClose={closePopup} tags={data.tags} />
    </>
  );
};

// trash
const TableRow = memo(Row, (prev, next) => {
  return prev && JSON.stringify(prev.data) === JSON.stringify(next.data);
});
export default TableRow;
