import useTranslate from 'hooks/useTranslate';

const CommentColumns = [
  {label: 'COMMENT', key: 'text', sortable: false},
  {label: 'COMMENTED_BY', key: 'commented_by', sortable: false},
  {label: 'COMMENTED_ON', key: 'created_at', sortable: false},
  {label: 'ACTIONS', key: 'actions', sortable: false},
];

const CommentsTableHeader = () => {
  const {parseUI} = useTranslate();

  const renderTableHeadingFields = () => {
    return CommentColumns.map(column => (
      <div key={column.key} className="editable-header-cell">
        {parseUI(column.label)}
      </div>
    ));
  };

  return <div className="editable-table-header">{renderTableHeadingFields()}</div>;
};

export default CommentsTableHeader;
