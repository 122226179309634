export const USER = {
  LOGIN: '/auth/login',
  CHANGE_PASSWORD: '/auth/change-password',
  UPDATE_ACCESS_TOKEN: '/auth/token',
};

export const PEST_COMPANY = {
  PEST_COMPANY: '/pest-company',
  PEST_COMPANY_STATUS: '/pest-company/status',
  PEST_COMPANY_WITH_BAITBOX_COUNT: '/pest-company/details',
};

export const LANGUAGES = {
  LANGUAGES: '/languages',
};

export const PEST_COMPANY_ACTION = {
  DEACTIVATE_MULTIPLE_PEST_COMPANIES: '/pest-company/deactivate-multiple',
  DEACTIVATE_MULTIPLE_CLIENT_BAITBOX: '/client-bait-box/deactivate-multiple',
  RETIRE_MULTIPLE: '/client-bait-box/mark-retire-multiple',
  RETURN_TO_ZEPTA_MULTIPLE: '/client-bait-box/return-to-zepta-multiple',
};

export const BAIT_BOX = {
  LIST: '/bait-box/list',
  CREATE_INVENTORY: '/bait-box',
  LIST_INVENTORY: '/bait-box',
  EDIT_INVENTORY: '/bait-box/edit',
  DEACTIVATE_MULTIPLE: '/bait-box/deactivate-multiple',
  COMMENTS: '/bait-box/comments-list',
  EDIT_COMMENT: '/bait-box/edit-comment',
  REMOVE_COMMENT: '/bait-box/remove-comment',
  ADD_COMMENT: '/bait-box/add-comment',
};

export const BAIT_BOX_ACTION = {
  MARK_DEACTIVATE: '/client-bait-box/remove',
  MARK_RETURN_TO_ZEPTA: '/bait-box/return-to-zepta',
  MARK_RETIRE: '/bait-box/mark-retire',
  UPDATE_TARE_OFFSET: '/bait-box/tare-offset',
  UPDATE_MULTIPLIER: '/bait-box/multiplier',
  ASSIGN_TAGS: '/bait-box-tags/assign',
  UPDATE_DEVICE_SETTINGS: '/bait-box/configure-device-settings',
};

export const BAIT_BOX_TAG = {
  TAG: '/bait-box-tags',
  REMOVE_TAG: '/remove-tag',
  GET_BAIT_BOX_TAGS: '/bait-box-tags',
};

export const BAIT_BOX_LOGS = {
  SIMULATE: '/bait-box/logs/simulator',
  SIMULATE_V2: '/bait-box/logs/simulator-v2',
};

export const ACTIVITY_LOGS = {
  GET_BAIT_BOX_LOGS: 'activity-logs',
  GET_BAIT_BOX_LOGS_FILTERS: 'activity-logs/filters',
  DOWNLOAD_CSV: 'activity-logs/export-csv',
};

export const CONFIGURATIONS = {
  SIMULATOR_API_LIMIT: '/configurations/simulator-limit-config',
};

export const SCHEDULE_MAINTENANCE = {
  SCHEDULE_MAINTENANCE: '/scheduled-maintenance',
};
