import {FC} from 'react';
import {getBaitCheckerState} from 'redux/inventory/selector';
import Loader from 'components/shared/loader/Loader';
import {useAppSelector} from 'redux/redux';
import TablePagination from 'components/shared/table-pagination/TablePagination';
import {countPages} from 'utils/page-count';
import DataNotFound from 'components/shared/DataNotFound';
import List from './table';

type BaitBoxLogsListProp = {
  pagination: {
    limit: number;
    page: number;
    onPageChange: (newPage: number) => void;
    onLimitChange: (newLimit: number) => void;
  };
};

const BaitBoxLogsList: FC<BaitBoxLogsListProp> = ({pagination}) => {
  const {isLoading, baitBoxActivityLogsCount} = useAppSelector(getBaitCheckerState);
  const {page, limit, onPageChange, onLimitChange} = pagination;

  return (
    <div>
      <Loader show={isLoading} />
      {baitBoxActivityLogsCount > 0 ? (
        <>
          <List />

          <TablePagination
            pageChange={onPageChange}
            page={page}
            count={countPages(baitBoxActivityLogsCount, limit)}
            limit={limit}
            limitChange={onLimitChange}
          />
        </>
      ) : (
        <DataNotFound />
      )}
    </div>
  );
};

export default BaitBoxLogsList;
