import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {persistorStore, store} from './redux/store';
import {SidebarProvider} from 'contexts/SidebarContext';
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import './i18n/config';
import {ToastContainer} from 'react-toastify';

import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {PersistGate} from 'redux-persist/integration/react';
import './styles/global.css';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary';

ReactDOM.render(
  <HelmetProvider>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistorStore}>
          <SidebarProvider>
            <BrowserRouter>
              <App />
              <ToastContainer />
            </BrowserRouter>
          </SidebarProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </HelmetProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
