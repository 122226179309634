import {Box, styled} from '@mui/material';

const MenuWrapper = styled(Box)(
  ({theme}) => `
    .MuiList-root {
  
      & > .MuiList-root {
      }
    }
  
      .MuiListSubheader-root {
        text-transform: uppercase;
        font-weight: bold;
        font-size: ${theme.typography.pxToRem(12)};
        color: ${theme.colors.alpha.trueWhite[50]};
        line-height: 1.4;
      }
  `,
);

export default MenuWrapper;
