import {INDICATOR} from 'models/indicator-color';

/**
 * The function `getBatteryColor` takes a battery level as input and returns an object with three color
 * values based on the battery level.
 * @param {number} batteryLevel - The `batteryLevel` parameter represents the current battery level as
 * a number.
 * @returns an object called `batteryColor` which contains three properties: `one`, `two`, and `third`.
 * The values of these properties depend on the `batteryLevel` parameter passed to the function.
 */
export const getBatteryColor = (batteryLevel: number) => {
  const batteryColor = {
    one: null,
    two: null,
    third: null,
    batteryOutline: INDICATOR.DARK,
  };

  if (batteryLevel === 100 || batteryLevel >= 67) {
    batteryColor.one = INDICATOR.DARK;
    batteryColor.two = INDICATOR.DARK;
    batteryColor.third = INDICATOR.DARK;
  } else if (batteryLevel === 66 || batteryLevel >= 33) {
    batteryColor.one = INDICATOR.DARK;
    batteryColor.two = INDICATOR.DARK;
  } else if (batteryLevel === 32 || batteryLevel >= 1) {
    batteryColor.batteryOutline = INDICATOR.LIGHT;
    batteryColor.one = INDICATOR.LIGHT;
  } else {
    batteryColor.batteryOutline = INDICATOR.RED;
  }

  return batteryColor;
};

export const getBatteryPercentage = (batteryLevel: number) => {
  return `${+batteryLevel} %`;
};
