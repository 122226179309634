export const formatDateToYYYYMMDD = (dateString: Date) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
  const day = String(date.getDate()).padStart(2, '0');

  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const germanDateFormat = (dateString: string) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = String(date.getFullYear()).slice(-2);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const daysFromDate = (dateStr: string) => {
  // Convert the input date string to a Date object
  const inputDate = new Date(dateStr);
  // Get today's date
  const today = new Date();
  // Calculate the number of milliseconds in a day
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  // Calculate the time difference in milliseconds
  return Math.floor((today.getTime() - inputDate.getTime()) / millisecondsInDay);
};

export const formatDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getToday = (): string => {
  return formatDate(new Date());
};

export const getYesterday = (): string => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return formatDate(date);
};
