import TableHeader from './Header';
import InventoryTableBody from './TableBody';

const List = () => {
  return (
    <>
      <section className="common-table editable">
        <div className="editable-table inventory-outer">
          <TableHeader />
          <InventoryTableBody />
        </div>
      </section>
    </>
  );
};

export default List;
