import {FC} from 'react';
import {getBatteryColor} from 'utils/battery-indicator';

const BatteryIndicator: FC<{value: number}> = ({value}) => {
  const batteryLevel = getBatteryColor(value);

  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6 6C4.89543 6 4 6.89543 4 8V16C4 17.1046 4.89543 18 6 18H16C17.1046 18 18 17.1046 18 16V15C18 14.6212 18.214 14.275 18.5528 14.1056L19.4472 13.6584C19.786 13.489 20 13.1427 20 12.7639V11.2361C20 10.8573 19.786 10.511 19.4472 10.3416L18.5528 9.89443C18.214 9.72504 18 9.37877 18 9V8C18 6.89543 17.1046 6 16 6H6ZM2 8C2 5.79086 3.79086 4 6 4H16C18.2091 4 20 5.79086 20 8V8.38197L20.3416 8.55279C21.358 9.06096 22 10.0998 22 11.2361V12.7639C22 13.9002 21.358 14.939 20.3416 15.4472L20 15.618V16C20 18.2091 18.2091 20 16 20H6C3.79086 20 2 18.2091 2 16V8Z"
          fill={batteryLevel.batteryOutline}
        />
        <path
          // eslint-disable-next-line max-len
          d="M6 9C6 8.44772 6.44772 8 7 8C7.55228 8 8 8.44772 8 9V15C8 15.5523 7.55228 16 7 16C6.44772 16 6 15.5523 6 15V9Z"
          fill={batteryLevel.one}
        />
        <path
          // eslint-disable-next-line max-len
          d="M10 9C10 8.44772 10.4477 8 11 8C11.5523 8 12 8.44772 12 9V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V9Z"
          fill={batteryLevel.two}
        />
        <path
          // eslint-disable-next-line max-len
          d="M15 8C14.4477 8 14 8.44772 14 9V15C14 15.5523 14.4477 16 15 16C15.5523 16 16 15.5523 16 15V9C16 8.44772 15.5523 8 15 8Z"
          fill={batteryLevel.third} // test
        />
      </svg>
    </>
  );
};

export default BatteryIndicator;
