import {Box, Grid} from '@mui/material';
import {Form, Formik} from 'formik';
import {useNavigate} from 'react-router';

import Button from 'components/UI/Button';
import LoadingButton from 'components/UI/LoadingButton';
import CustomPhoneInput from '../UI/CustomPhoneInput';
import Dropdown from 'components/UI/form/Dropdown';
import InputField from 'components/UI/form/InputField';
import Loader from 'components/shared/loader/Loader';
import {DEFAULT_VALUES} from 'constants/default-values';
import {ROUTES} from 'constants/routes';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {CreatePestCompanyData, LanguagesList} from 'models/pest-company-interface';
import {useEffect, useState} from 'react';
import {selectLoading} from 'redux/pest-company/selector';
import {createPestCompanyAsync, getLanguagesListAsync} from 'redux/pest-company/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import {AddPestCompanySchema} from 'utils/validation-schema';

const addPestCompanyFields = [
  {name: 'country', label: 'COUNTRY', type: 'text'},
  {name: 'firstName', label: 'FIRST_NAME', type: 'text'},
  {name: 'lastName', label: 'LAST_NAME', type: 'text'},
  {name: 'email', label: 'EMAIL', type: 'email'},
  {name: 'phoneNumber', label: 'PHONE_NUMBER', type: 'text'},
  {name: 'name', label: 'PEST_COMPANY_NAME', type: 'text'},
  {name: 'street', label: 'STREET', type: 'text'},
  {name: 'postCode', label: 'POSTAL_CODE', type: 'text'},
  {name: 'city', label: 'CITY', type: 'text'},
  {name: 'taxId', label: 'TAX_ID', type: 'text'},
  {name: 'languageId', label: 'LANGUAGE', type: 'text'},
];

const AddPestCompany = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [languages, setLanguages] = useState<LanguagesList[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [country, setCountry] = useState<string>('DE');

  const {parseUI} = useTranslate();

  const loading = useAppSelector(selectLoading);

  const initialValues: CreatePestCompanyData = {
    country: 'DE',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    name: '',
    street: '',
    city: '',
    postCode: '',
    taxId: '',
    languageId: DEFAULT_VALUES.DEFAULT_LANGUAGE_ID,
  };

  /** to fetch languages list */
  useEffect(() => {
    setIsLoading(true);
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const response = await dispatch(getLanguagesListAsync()).unwrap();
      setLanguages(response.data);
      setIsLoading(false);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);

      setIsLoading(false);
      showError(errorMsg);
    }
  };

  const handleSubmit = async (pestCompanyData: CreatePestCompanyData) => {
    // cast is used to apply the validation in the fields that we defined in schema
    const formData = AddPestCompanySchema.cast(pestCompanyData);

    const formValues = {
      ...pestCompanyData,
      phoneNumber: formData.phoneNumber,
      country,
    };
    try {
      const response = await dispatch(createPestCompanyAsync(formValues)).unwrap();
      showSuccess(response.data.message);
      navigate(`/${ROUTES.DASHBOARD}`);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
  };

  const onCancelClick = () => {
    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PEST_COMPANY}`);
  };

  const renderField = (field: {name: string; label: string; type: string}, languages: any[]) => {
    if (field.name === 'phoneNumber') {
      return (
        <CustomPhoneInput
          name="phoneNumber"
          type="text"
          label={field.label}
          maxLength={20}
          country={country}
          onCountryChange={setCountry}
        />
      );
    } else if (field.name === 'languageId') {
      return <Dropdown name={field.name} list={languages} label={field.label} />;
    } else if (field.name !== 'country') {
      return <InputField name={field.name} type={field.type} label={field.label} />;
    }
  };

  return (
    <>
      <Loader show={isLoading} />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="plr36">
        <h1 className="page-title"> {parseUI('ADD_PEST_COMPANY')} </h1>
        <Formik
          initialValues={initialValues}
          validationSchema={AddPestCompanySchema}
          onSubmit={handleSubmit}
          validateOnBlur={true}
          validateOnChange={true}>
          {({isValid, dirty}) => (
            <Form>
              <Grid container spacing={2}>
                {addPestCompanyFields
                  ?.filter(f => f.name !== 'country')
                  .map(field => (
                    <Grid item xs={12} sm={6} className="pt-0" key={field.name}>
                      {renderField(field, languages)}
                    </Grid>
                  ))}
              </Grid>
              <Box mt={3} justifyContent={'space-between'} alignItems={'justify-end'}>
                <div className="btn-end">
                  <LoadingButton
                    type="submit"
                    label="SUBMIT"
                    loader={loading}
                    className="loading-button"
                    disabled={!isValid || !dirty || loading}
                  />
                  <Button type="button" onClick={onCancelClick} label="CANCEL" />
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default AddPestCompany;
