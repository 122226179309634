import React from 'react';

import './Loader.css';

const Loader: React.FC<{show: boolean}> = ({show}) => {
  return (
    show && (
      <div className="loader-container">
        <div className="loader"></div>
        <div className="bg"></div>
      </div>
    )
  );
};

export default Loader;
