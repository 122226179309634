import {Dialog, DialogContent, DialogTitle, Grid} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import AutoTextArea from 'components/UI/form/AutoTextArea';
import LoadingButton from 'components/UI/LoadingButton';
import {Form, Formik} from 'formik';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {BaitboxDeviceSettingsForm} from 'models/inventory.interface';
import React from 'react';
import {getSelectedCheckBoxIds} from 'redux/checkBox/selector';
import {reset} from 'redux/checkBox/slice';
import {updateBaitboxDeviceSettingsAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import {AddDeviceSettingsSchema} from 'utils/validation-schema';

interface InputPopupProps {
  open: boolean;
  onClose: () => void;
}

export const InputSettingPopup: React.FC<InputPopupProps> = ({open, onClose}) => {
  const initialValues: Partial<BaitboxDeviceSettingsForm> = {
    deviceSettings: '',
  };

  const checkBoxIds = useAppSelector(getSelectedCheckBoxIds);
  const dispatch = useAppDispatch();

  const handleSubmit = async (data: any) => {
    if (checkBoxIds.length === 0) {
      return onClose();
    }

    try {
      const response = await dispatch(
        updateBaitboxDeviceSettingsAsync({
          deviceSettings: data.deviceSettings,
          baitBoxIds: checkBoxIds,
        }),
      ).unwrap();
      dispatch(reset());
      showSuccess(response.message);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
    onClose();
  };

  const handleClose = () => {
    onClose();
  };
  const {parseUI} = useTranslate();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{paddingBottom: '0'}}>
        <strong>{parseUI('CONFIGURE_DEVICE_SETTINGS_RESPONSE')}</strong>
      </DialogTitle>
      <DialogContent style={{paddingBottom: '0'}}>
        <Formik
          initialValues={initialValues}
          validationSchema={AddDeviceSettingsSchema}
          onSubmit={values => {
            handleSubmit(values);
            // resetForm();
          }}
          validateOnBlur={true}
          validationOnChange={true}>
          {({isValid, dirty}) => (
            <Form className="device-form">
              <Grid container spacing={2} className="device-setting-inner-form">
                <div style={{width: '100%'}}>
                  <AutoTextArea name="deviceSettings" label="DEVICE_SETTINGS_RESPONSE" customMessage={true} />
                </div>
              </Grid>
              <DialogActions>
                <LoadingButton
                  type="submit"
                  label="SUBMIT"
                  disabled={!isValid || !dirty}
                  className="loading-button"
                  color="green"
                />
                <LoadingButton
                  type="button"
                  label="CLOSE"
                  disabled={false}
                  className="loading-button"
                  onClick={handleClose}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
