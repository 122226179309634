import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BaitCheckerInventory} from 'models/inventory.interface';
import {initialState} from './initial-state';

export const baitboxLogsDetails = createSlice({
  name: 'baitbox-log-details',
  initialState: initialState,
  reducers: {
    setBaitBoxLogDetails: (state, action: PayloadAction<BaitCheckerInventory>) => {
      state.data = action.payload;
    },
  },
});

export const {setBaitBoxLogDetails} = baitboxLogsDetails.actions;

export default baitboxLogsDetails.reducer;
