import {Box} from '@mui/material';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {useNavigate} from 'react-router';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import ScheduleMaintenance from '../form/ScheduleMaintenanceForm';
import {ScheduleMaintenanceForm} from 'models/maintenance-interface';
import {createScheduledMaintenanceAsync, getScheduledMaintenanceAsync} from 'redux/scheduled-maintenance/thunk';
import {ROUTES} from 'constants/routes';
import ScheduleMaintenanceList from '../list/ScheduleMaintenaceList';
import {maintenanceLoading} from 'redux/scheduled-maintenance/selector';

/**
 * @desc: add maintenance component
 * @returns
 */
const AddMaintenance = () => {
  const {parseUI} = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoading = useAppSelector(maintenanceLoading);

  const initialValues: ScheduleMaintenanceForm = {
    startTime: null,
    endTime: null,
    description: '',
    emailSubject: '',
    emailScheduledTime: null,
  };

  /**
   * @desc: to handle submit
   * @param formRequest
   */
  const handleSubmit = async (formRequest: ScheduleMaintenanceForm, {resetForm}) => {
    try {
      const body = {
        ...formRequest,
      };
      const response = await dispatch(createScheduledMaintenanceAsync(body)).unwrap();

      if (response) {
        showSuccess(response.data.message);

        navigate(`/${ROUTES.DASHBOARD}/${ROUTES.SCHEDULE_MAINTENANCE}`);
        dispatch(getScheduledMaintenanceAsync()).unwrap();
        resetForm();
      }
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className="plr36 schedule-maintenance-outer">
      <h1 className="page-title"> {parseUI('SCHEDULE_MAINTENANCE')} </h1>

      <ScheduleMaintenance loading={isLoading} onSubmit={handleSubmit} initialValues={initialValues} />
      <div className="schedule-wrapper">
        <ScheduleMaintenanceList />
      </div>
    </Box>
  );
};

export default AddMaintenance;
