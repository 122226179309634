import {BaitBoxActivityLog} from 'models/inventory.interface';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {useAppSelector} from 'redux/redux';
import ActivityLogRow from './ActivityLogRow';

const ActivityLogsTableBody = () => {
  const {activityLogs} = useAppSelector(getBaitCheckerState);
  return (
    <div className="editable-table-content filter-table-body">
      {activityLogs.map((row: BaitBoxActivityLog) => (
        <ActivityLogRow key={row.uuid} data={row} />
      ))}
    </div>
  );
};

export default ActivityLogsTableBody;
