import React from 'react';
import {Pagination} from '@mui/material';
import {PAGE_LIMIT_OPTIONS} from 'constants/pagination';

const TablePagination = ({pageChange, page, count, limit, limitChange}) => {
  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLimit = parseInt(event.target.value, 10);
    limitChange(newLimit);
  };

  return page && limit ? (
    <div className="table-pagination">
      <div className="table-pagination-inner">
        <Pagination
          onChange={(_event: React.ChangeEvent<unknown>, value: number) => pageChange(value)}
          page={page}
          count={count}
        />
        <select id="pageLimit" name="pageLimit" value={limit} onChange={handleLimitChange}>
          {PAGE_LIMIT_OPTIONS.map(limit => (
            <option key={limit} value={limit}>
              {limit}
            </option>
          ))}
        </select>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TablePagination;
