import {Edit, Save} from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {IconButton} from '@mui/material';
import {FC, useState} from 'react';
import {useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';

import ConfirmDialog from 'components/UI/ConfirmDialog';
import InputTableCell from 'components/UI/form/InputTableCell';
import InfoTooltip from 'components/UI/InfoTooltip';
import {EMPTY_STRING} from 'constants/text-strings';
import {Form, Formik} from 'formik';
import useDialog from 'hooks/useDialog';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {BaitCheckerComment} from 'models/inventory.interface';
import {getAuthState} from 'redux/auth/selector';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {getBaitBoxCommentsAsync, removeBaitBoxCommentAsync, updateBaitBoxCommentAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {germanDateFormat} from 'utils/format-date';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import {EditBaitBoxCommentSchema} from 'utils/validation-schema';

type CommentRowProps = {
  comment: BaitCheckerComment;
};

const CommentRow: FC<CommentRowProps> = ({comment}) => {
  const dispatch = useAppDispatch();
  const {id} = useParams();
  const {parseUI} = useTranslate();
  const {isOpen, showDialog, dialogData, hideDialog} = useDialog(false);
  const [parameter] = useSearchParams();

  const {baitCheckerCommentsLoading} = useAppSelector(getBaitCheckerState);
  const {user} = useAppSelector(getAuthState);
  const [showForm, setShowForm] = useState<boolean>(false);

  const queryPage = parameter.get('page') ?? '1';
  const queryLimit = parameter.get('limit') ?? '10';

  const {createdAt} = comment;
  const {firstName, lastName} = comment.commentedBy;
  const fullName = `${firstName} ${lastName}`;
  const formattedDate = createdAt ? germanDateFormat(createdAt) : EMPTY_STRING;

  const onConfirmDialogSuccess = async () => {
    try {
      const response = await dispatch(removeBaitBoxCommentAsync(comment.uuid)).unwrap();
      dispatch(
        getBaitBoxCommentsAsync({
          baitBoxId: id,
          pageNumber: parseInt(queryPage),
          limit: parseInt(queryLimit),
        }),
      );
      showSuccess(response.message);
    } catch (error) {
      showError(error.message);
    }

    hideDialog();
  };

  const onSubmit = async (formData: BaitCheckerComment) => {
    const data = {
      commentId: formData.uuid,
      text: formData.text,
    };
    try {
      const response = await dispatch(updateBaitBoxCommentAsync(data)).unwrap();
      showSuccess(response.message);
      dispatch(
        getBaitBoxCommentsAsync({
          baitBoxId: id,
          pageNumber: parseInt(queryPage),
          limit: parseInt(queryLimit),
        }),
      );
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
    setShowForm(false);
  };

  const commentByLoggedInAdmin = () => {
    return comment.userId !== user.uuid ? true : false;
  };

  const renderRow = () => {
    return (
      <Formik
        key={comment.uuid}
        initialValues={comment}
        enableReinitialize={true}
        validationSchema={EditBaitBoxCommentSchema}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        className="editable-table-row">
        {({isValid}) => {
          return (
            <Form className="editable-table-row">
              <div className="editable-table-cell">
                <InputTableCell name="text" type="text" readOnly={!showForm} />
              </div>

              <div className="editable-table-cell">
                <InputTableCell name="fullName" type="text" readOnly={true} value={fullName} />
              </div>

              <div className="editable-table-cell">
                <InputTableCell name="createdAt" type="text" readOnly={true} value={formattedDate} />
              </div>

              <div className="editable-table-cell">
                {showForm ? (
                  <IconButton aria-label="Save" disabled={!isValid} type="submit">
                    <Save />
                  </IconButton>
                ) : (
                  <InfoTooltip name={showForm ? parseUI('SAVE') : parseUI('EDIT')}>
                    <IconButton
                      aria-label="Edit"
                      disabled={commentByLoggedInAdmin()}
                      onClick={() => setShowForm(prev => !prev)}>
                      <Edit />
                    </IconButton>
                  </InfoTooltip>
                )}

                {
                  <InfoTooltip name={parseUI('DELETE')}>
                    <IconButton
                      disabled={commentByLoggedInAdmin()}
                      onClick={() =>
                        showDialog<{
                          comment: BaitCheckerComment;
                        }>({
                          comment,
                        })
                      }
                      aria-label="Edit">
                      <DeleteOutlineIcon />
                    </IconButton>
                  </InfoTooltip>
                }
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderDeActiveCommentDialog = () => {
    const deleteText = parseUI('WANT_TO_DELETE');
    const tagName = dialogData?.comment.text;
    const baitBoxText = parseUI('BAIT_BOX_COMMENT');
    return (
      <ConfirmDialog
        isLoading={baitCheckerCommentsLoading}
        initialValue={isOpen}
        onClose={hideDialog}
        successMessage={parseUI('YES')}
        cancelMessage={parseUI('CANCEL')}
        onSuccess={onConfirmDialogSuccess}
        heading={parseUI('ARE_YOU_SURE')}
        body={
          <>
            {deleteText} <strong>{tagName}</strong> {baitBoxText} ?
          </>
        }
      />
    );
  };
  return (
    <>
      {renderRow()}
      {renderDeActiveCommentDialog()}
    </>
  );
};

export default CommentRow;
