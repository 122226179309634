import {ListParams, Tag} from './pest-company-interface';
import {TagListParams} from './tags-interface';

export interface BaitCheckerInventoryForm {
  iccNumbers: string;
  tags: any[];
}

export interface NewlyCreatedBaitBox {
  slug: string;
  iccNumber: string;
}

export interface BaitCheckerInventory {
  isActive: number;
  uuid: string;
  tareOffset: number;
  multiplier: number;
  pestCompanyName: string;
  iccNumber: string;
  isRetire: boolean;
  pestCompanyId: string;
  slug: string;
  clientCompanyId: string;
  clientCompanyName: string;
  tags: Tag[];
  status: number;
}

export interface CheckboxInventoryData extends BaitCheckerInventory {
  isCheck: boolean;
}

export interface GetBaitBoxResponse {
  list: BaitCheckerInventory[];
  count: number;
}

export interface UpdateBaitBoxMeta {
  tareOffset: number;
  multiplier: number;
  baitBoxId: string;
}

export interface SelectedBaitCheckers {
  baitBoxId: string;
  pestCompanyId: string;
}

export interface UpdateBaitCheckersTareOffset {
  baitBoxIds: string[];
  tareOffset: number;
}

export interface UpdateBaitCheckersMultiplier {
  baitBoxIds: string[];
  multiplier: number;
}

export interface BaitCheckerTags {
  uuid: string;
  name: string;
  isAssigned: boolean;
}

export interface GetAllAndAssignedTags {
  baitBoxId: string;
  pageNumber: number;
  limit: number;
  searchText?: string;
}

export interface AssignTags {
  uuid: string;
  name: string;
  isAssigned: string;
}

export interface GetAllAndAssignedTagResponse {
  data: AssignTags[];
  count: number;
}

export interface CreateSimulateData {
  iccNumber: string;
  batteryLevel: string;
  weight: string;
  networkStrength: string;
  isValidHash: boolean;
}

export interface SimulateDataWithoutHash extends Omit<CreateSimulateData, 'isValidHash'> {}

export interface BaitCheckerComment {
  uuid: string;
  userId: string;
  text: string;
  createdAt: string;
  commentedBy: {
    firstName: string;
    lastName: string;
  };
}

export interface BaitBoxCommentListParams extends TagListParams {
  baitBoxId: string;
}

export interface BaitBoxCommentsResponse {
  list: BaitCheckerComment[];
  count: number;
}

export interface UpdateCommentBody {
  commentId: string;
  text: string;
}

export interface BaitBoxCommentForm {
  text: string;
}

export interface CreateCommentBody {
  baitBoxId: string;
  text: string;
}

export interface GetBaitBoxStatusResponse {
  uuid: string;
  isActive: boolean;
}

export interface BaitBoxActivityLog {
  uuid: string;
  createdAt: string;
  eventName: string;
  message: string;
  clientCompanyName: string;
  pestCompanyName: string;
  pestCompanyId: string;
  clientCompanyId: string;
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface GetBaitBoxActivityLogsBody extends ListParams, DateRange {
  baitBoxId: string;
  pestCompanyIds: string[];
  clientCompanyIds: string[];
  eventIds: string[];
}

export interface DownloadActivityLogsCSV {
  baitBoxId: string;
  pestCompanyIds: string[];
  clientCompanyIds: string[];
  eventIds: string[];
  searchField?: string;
}

export interface BaitBoxActivityLogsResponse {
  list: BaitBoxActivityLog[];
  count: number;
}

interface ClientCompany {
  clientCompanyId: string;
  clientCompanyDetails: {
    name: string;
  };
}

interface PestCompany {
  pestCompanyId: string;
  pestCompanyDetails: {
    name: string;
  };
}

export interface SectionDetails {
  isIndependent: boolean;
  name: string;
  parent: {name: string} | null;
  uuid: string;
}

interface Event {
  eventId: string;
  eventDetails: {
    name: string;
  };
}

export interface BaitBoxLogsData {
  batteryLevel: number;
  createdAt: string;
  networkStrength: number;
  prevWeight: string;
  uuid: string;
  weight: string;
}

export interface BaiBoxActivityFilter {
  clientCompanies: ClientCompany[];
  pestCompanies: PestCompany[];
  events: Event[];
  sectionDetails: SectionDetails | null;
  metaData: {
    batteryLevel: number;
    currentWeight: string;
    name: string;
    networkStrength: number;
    status: number;
  } | null;
  logsData: BaitBoxLogsData[];
  details: {
    slug: string;
    iccNumber: string;
    deviceSettingsResponse?: object;
  } | null;
}

export interface BaitBoxActivityFiltersResponse {
  list: BaiBoxActivityFilter[];
}

export interface CSVDownloadResponse {
  path: string;
}

export interface ActivityLogsFilterAction {
  status: 'apply' | 'remove' | 'close';
  pestCompaniesFilters?: string[];
  clientCompaniesFilters?: string[];
  eventFilters?: string[];
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface ActivityLogsDateFilterAction extends DateRange {
  status: 'apply' | 'remove';
}

export enum DateFilterStatus {
  APPLY = 'apply',
  REMOVE = 'remove',
}

export interface UpdateBaitBoxTagBody {
  tagId: string;
  isCheck: boolean;
}

export interface AssignUnassignTagBody {
  baitBoxId: string;
  data: UpdateBaitBoxTagBody[];
}

export interface SimulatorBaitBoxState extends GetBaitBoxStatusResponse {}

export interface CreateSimulateDataV2 {
  blob: Blob;
}

export interface ProtobufResponse {
  deviceSettings: {
    scale: {
      calm_weight: {
        repeat_measure_per_sample?: number;
        max_difference?: number;
        continuous_values?: number;
        time_between_measures_ms?: number;
        weigh_timeout_ms?: number;
        tare_timeout_ms?: number;
        calibration_timeout_ms?: number;
      };
      calibration_weight?: number;
      save_scale_calibration_to_flash?: boolean;
      transmit_raw_scale_data?: boolean;
    };
    sleep: {
      after_success_s?: number;
      after_no_network_available_s?: number;
      after_transmitter_error_s?: number;
      after_backend_error_s?: number;
      after_not_calibrated_s?: number;
      after_calm_weight_timeout_s?: number;
      keep_powered_on?: boolean;
    };
    wifi: {
      allow_sleep_while_client_is_connected?: boolean;
      enable_wifi_after_deep_sleep?: boolean;
      login_timeout_on_power_on_s?: number;
      login_timeout_after_sleep_s?: number;
      ssid_prefix?: string;
    };
  };
}

export interface BaitboxDeviceSettingsForm {
  deviceSettings: ProtobufResponse | string;
}

export interface BaitboxDeviceSettings {
  baitBoxIds: string[];
  deviceSettings: string;
}
