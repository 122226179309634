import {useField} from 'formik';
import useAutosizeTextArea from 'hooks/useAutoSizeTextArea';
import useTranslate from 'hooks/useTranslate';
import {FC, useRef, useState} from 'react';

interface TextAreaProps {
  label: string;
  name: string;
  customMessage: boolean;
}

const AutoTextArea: FC<TextAreaProps> = ({label, name, customMessage}) => {
  const [field, meta, {setTouched}] = useField(name);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const {parseUI, parseError} = useTranslate();
  const [showError, setShowError] = useState(false);

  const formatErrorMessage = () => {
    const err = meta.error;
    if (!err) return;
    if (customMessage) {
      const error = err?.split('|');

      return error?.length > 1
        ? `${parseError(error[0])} <div>${parseError('INVALID_VALUES')} ${error[1]}</div>`
        : parseError(meta.error);
    }
  };
  const changeTouch = (value: boolean) => {
    setTouched(true);
    setShowError(value);
  };

  useAutosizeTextArea(textAreaRef.current, field.value);
  return (
    <>
      <textarea
        minLength={3}
        ref={textAreaRef}
        placeholder={parseUI(label)}
        {...field}
        onBlur={() => changeTouch(true)}
        onFocus={() => changeTouch(false)}
        className={meta.touched && meta.error ? 'textarea-mui textareaInput-error' : 'textarea-mui'}
      />
      {meta.touched && showError && (
        <div className="textarea-error" dangerouslySetInnerHTML={{__html: formatErrorMessage()}}></div>
      )}
    </>
  );
};

export default AutoTextArea;
