import {Box, Grid} from '@mui/material';
import {Form, Formik} from 'formik';
import {useNavigate} from 'react-router';

import Button from 'components/UI/Button';
import LoadingButton from 'components/UI/LoadingButton';
import InputField from 'components/UI/form/InputField';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {BaitBoxCommentForm} from 'models/inventory.interface';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {addBaitBoxCommentAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import {AddBaitBoxCommentSchema} from 'utils/validation-schema';

const addBaitBoxCommentFields = [{name: 'text', label: 'COMMENT', type: 'text'}];
const AddBaitBoxComment = () => {
  const {parseUI} = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {baitCheckerCommentsLoading, inventoryDetails} = useAppSelector(getBaitCheckerState);

  const initialValues: BaitBoxCommentForm = {
    text: '',
  };

  const handleSubmit = async (formData: BaitBoxCommentForm) => {
    const body = {
      baitBoxId: inventoryDetails.uuid,
      text: formData.text,
    };
    try {
      const response = await dispatch(addBaitBoxCommentAsync(body)).unwrap();
      showSuccess(response.message);
      navigate(-1);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
  };

  const onCancelClick = () => {
    navigate(-1);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="plr36">
      <h1 className="page-title"> {parseUI('ADD_COMMENT')} </h1>
      <Formik
        initialValues={initialValues}
        validationSchema={AddBaitBoxCommentSchema}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validationOnChange={true}>
        {({isValid, dirty}) => (
          <Form style={{width: '50%'}}>
            <Grid container spacing={2}>
              {addBaitBoxCommentFields?.map(field => (
                <div key={field.name} style={{width: '100%'}}>
                  <InputField name={field.name} type={field.type} label={field.label} />
                </div>
              ))}
            </Grid>

            <Box justifyContent={'space-between'} alignItems={'justify-end'}>
              <div className="btn-end">
                <LoadingButton
                  type="submit"
                  label="SUBMIT"
                  loader={baitCheckerCommentsLoading}
                  className="loading-button"
                  disabled={!isValid || !dirty || baitCheckerCommentsLoading}
                />

                <Button type="button" onClick={onCancelClick} label="CANCEL" />
              </div>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddBaitBoxComment;
