import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {usePagination} from 'hooks/usePagination';
import Loader from 'components/shared/loader/Loader';
import DataNotFound from 'components/shared/DataNotFound';
import {getBaitBoxTagsState} from 'redux/tags/selector';
import TablePagination from 'components/shared/table-pagination/TablePagination';
import {getBaitBoxTagsListAsync} from 'redux/tags/thunk';
import {countPages} from 'utils/page-count';
import BaitBoxTagList from './table';

const BaitBoxTagsList = () => {
  const {page, limit, onPageChange, onLimitChange} = usePagination(1, 50);
  const dispatch = useAppDispatch();
  const {loading, count} = useAppSelector(getBaitBoxTagsState);

  const getBaitBoxTags = () => {
    dispatch(getBaitBoxTagsListAsync({pageNumber: page, limit}));
  };

  useEffect(() => {
    if (page && limit) {
      getBaitBoxTags();
    }
  }, [page, limit]);

  return (
    <>
      <Loader show={loading} />
      {count > 0 ? (
        <>
          <BaitBoxTagList />

          <TablePagination
            pageChange={onPageChange}
            page={page}
            count={countPages(count, limit)}
            limit={limit}
            limitChange={onLimitChange}
          />
        </>
      ) : (
        <DataNotFound />
      )}
    </>
  );
};

export default BaitBoxTagsList;
