import {Box, Drawer, styled, useTheme} from '@mui/material';
import {useContext} from 'react';

import Scrollbar from 'components/shared/Scrollbar';
import {SidebarContext} from 'contexts/SidebarContext';

import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({theme}) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
`,
);

function Sidebar() {
  const {sidebarToggle, toggleSidebar} = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block',
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background: '#091d58',
        }}>
        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                width: 52,
              }}></Box>
          </Box>

          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}>
        <SidebarWrapper
          sx={{
            background: '#091d58',
          }}>
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52,
                }}></Box>
            </Box>

            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
