import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  BaitBoxListParams,
  ClientBaitBoxForDeactivation,
  CreatePestCompanyData,
  DeactiveBaitBoxBody,
  ListParams,
  UpdatePestCompanyStatusThunk,
  UpdatePestCompanyThunk,
} from 'models/pest-company-interface';

import {
  createPestCompany,
  deactivateBaitBox,
  deactivateSelectedClientBaitBoxes,
  deactivateSelectedPestCompanies,
  getLanguagesList,
  getPestCompanyBaitBoxes,
  getPestCompanyList,
  getPestDetailsWithBaitboxes,
  markBaitBoxRetire,
  retireSelectedClientBaitBoxes,
  retunrToZeptaSelectedClientBaitBoxes,
  returnBaitBoxToZepta,
  updatePestCompany,
  updatePestCompanyStatus,
} from './api';

export const createPestCompanyAsync = createAsyncThunk(
  'pestCompany/create',
  async (pestCompanyInfo: CreatePestCompanyData, {rejectWithValue}) => {
    try {
      return await createPestCompany(pestCompanyInfo);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPestCompanyListAsync = createAsyncThunk(
  'pestCompany/list',
  async (params: ListParams, {rejectWithValue}) => {
    try {
      const {data} = await getPestCompanyList(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePestCompanyAsync = createAsyncThunk(
  'pestCompany/update',
  async ({pestCompanyId, data}: UpdatePestCompanyThunk, {rejectWithValue}) => {
    try {
      const response = await updatePestCompany(pestCompanyId, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePestCompanyStatusAsync = createAsyncThunk(
  'pestCompany/statusUpdate',
  async ({pestCompanyId, isDeactivate}: UpdatePestCompanyStatusThunk, {rejectWithValue}) => {
    try {
      const params = {
        isDeactivate,
      };
      const response = await updatePestCompanyStatus(pestCompanyId, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPestCompanyBaitBoxesAsync = createAsyncThunk(
  'pestCompany/metaData',
  async (baitBoxParams: BaitBoxListParams, {rejectWithValue}) => {
    try {
      const params = {
        pageNumber: baitBoxParams.pageNumber,
        limit: baitBoxParams.limit,
        searchField: baitBoxParams.searchField,
        sortingField: baitBoxParams.sortingField,
        sortingOrder: baitBoxParams.sortingOrder,
      };
      const {data} = await getPestCompanyBaitBoxes(baitBoxParams.pestCompanyId, params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const markBaitBoxDeactiveAsync = createAsyncThunk(
  'pestCompany/markBaitBoxDeactive',
  async (deactiveBaitBoxBody: DeactiveBaitBoxBody, {rejectWithValue}) => {
    try {
      const {data} = await deactivateBaitBox(deactiveBaitBoxBody);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const markBaitBoxReturnToZeptaAsync = createAsyncThunk(
  'pestCompany/markBaitBoxReturnToZepta',
  async (iccHash: string, {rejectWithValue}) => {
    try {
      const {data} = await returnBaitBoxToZepta(iccHash);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const markBaitBoxRetireAsync = createAsyncThunk(
  'pestCompany/markBaitBoxRetire',
  async (iccHash: string, {rejectWithValue}) => {
    try {
      const {data} = await markBaitBoxRetire(iccHash);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deactivateSelectedPestCompaniesAsync = createAsyncThunk(
  'baitbox/deactivate-selected',
  async (selectedPestCompanies: string[], {rejectWithValue}) => {
    try {
      const {data} = await deactivateSelectedPestCompanies(selectedPestCompanies);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deactivateSelectedClientBaitBoxAsync = createAsyncThunk(
  'pestCompany/deactivate-selected-baitbox',
  async (selectedBaitBoxes: ClientBaitBoxForDeactivation[], {rejectWithValue}) => {
    try {
      const {data} = await deactivateSelectedClientBaitBoxes(selectedBaitBoxes);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const retireMultipleBaitBoxesAsync = createAsyncThunk(
  'pestCompany/retire-selected',
  async (selectedBaitBoxes: string[], {rejectWithValue}) => {
    try {
      const {data} = await retireSelectedClientBaitBoxes(selectedBaitBoxes);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const returnToZeptaMultipleBaitBoxesAsync = createAsyncThunk(
  'pestCompany/return-to-zepta-selected',
  async (selectedBaitBoxes: string[], {rejectWithValue}) => {
    try {
      const {data} = await retunrToZeptaSelectedClientBaitBoxes(selectedBaitBoxes);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPestDetailsWithBaitBoxesAsync = createAsyncThunk(
  'pestCompany/details-with-baiboxes-count',
  async (pestCompanyId: string, {rejectWithValue}) => {
    try {
      const {data} = await getPestDetailsWithBaitboxes(pestCompanyId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * @description: to get languages list
 */
export const getLanguagesListAsync = createAsyncThunk('pestCompany/languages-list', async (_, {rejectWithValue}) => {
  try {
    const {data} = await getLanguagesList();

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
